import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';

/**
 * Used to render an element / page in an asynchronous manner.
 * Useful for splitting the render task into multiple smaller ones.
 * Only intended to be used for parts of the UI, that are not immediately visible on the page load.
 */
@Directive({
  selector: '[aukAsyncRender]',
})
export class AsyncRenderDirective extends NgUnsubscribe implements OnInit {

  constructor(
    private readonly platformCommonService: PlatformCommonService,
    private readonly templateRef: TemplateRef<unknown>,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly ngZoneUtilService: NgZoneUtilService,
  ) {
    super();
  }

  public ngOnInit(): void {
    if (this.platformCommonService.isBot) {
      return;
    }

    this.viewContainerRef.clear();

    if (this.platformCommonService.isServer) {
      return;
    }

    this.ngZoneUtilService.simpleTimerOut$(
      () => this.viewContainerRef.createEmbeddedView(this.templateRef),
      this.ngUnsubscribe,
    );
  }

}
