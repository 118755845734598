import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonDirectivesModule } from '@shared/legacy/directive/common-directives.module';
import { CommonPipesModule } from '@shared/legacy/pipe/common-pipes.module';
import { SvgIconModule } from '@shared/legacy/component/svg-icon/svg-icon.module';
import { MobileBoxLayoutComponent, MobileBoxLayoutListComponent, MobileFiltersShowMoreComponent, MobileFiltersWrapperComponent } from './components';
import { Translate2Module } from '@common/translations/translate2.module';
import { TileComponent } from '@common/ui-kit/component/tile/component/tile/tile.component';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CommonPipesModule,
    SvgIconModule,
    CommonDirectivesModule,
    Translate2Module,
    TileComponent,
    MatCheckboxModule,
  ],
  declarations: [
    MobileBoxLayoutListComponent,
    MobileBoxLayoutComponent,
    MobileFiltersWrapperComponent,
    MobileFiltersShowMoreComponent,
  ],
  exports: [
    MobileBoxLayoutListComponent,
    MobileBoxLayoutComponent,
    MobileFiltersWrapperComponent,
    MobileFiltersShowMoreComponent,
  ],
})
export class MobileFiltersModule {

}
