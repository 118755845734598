<a [class.button-to-header]="showButton"
   [class.hidden]="!showButton"
   [class.hidden-tablet]="isHiddenOnTablet"
   (click)="scrollToTopWindow()"
   class="flex-center"
>
  <i class="material-icons text-white">
    keyboard_arrow_up
  </i>
</a>
