import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnChanges, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { BannerDeploymentDto } from '@api/generated/defs/BannerDeploymentDto';
import { BannerPlacementDto } from '@api/generated/defs/BannerPlacementDto';
import { BannerPositionDto } from '@api/generated/defs/BannerPositionDto';
import { PageTypeEnumRequestBannerDto, PlacementsRequestBannerDtoEnum } from '@api/generated/defs/RequestBannerDto';
import { BannersService } from '@shared/banner/service/banners.service';
import { BannerGoogleAnalyticsService } from '@shared/banner/service/banner-google-analytics.service';
import { BANNERS_TO_REGISTER_AS_LCP } from './banner.component.constants';
import { PreloadingLcpCandidatePriority } from '@shared/preloading/model/preloading-lcp-candidate-priority';
import isNil from 'lodash-es/isNil';
import { PreloadingRegisterService } from '@shared/preloading/service/preloading-register.service';
import { ArrayUtils } from '@util/util/array.utils';
import { DummyImagePlaceholderModel } from '@common/image-loading/model/dummy-image-placeholder.model';
import { AdElementIdType } from '@shared/google-analytics/model/ad-element-id.type';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { Nil } from '@util/helper-types/nil';
import { NgClass } from '@angular/common';
import { ReplaceByPlaceholderOnErrorDirective } from '@common/image-loading/directive/replace-by-placeholder-on-error.directive';
import { ThirdPartyBannerComponent } from '@shared/banner/component/third-party-banner/third-party-banner.component';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { SvgIconComponent } from '@common/ui-kit/component/svg-icon/component/svg-icon.component';
import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';
import { NavigationIconButtonComponent } from '@common/ui-kit/component/navigation-icon-button/navigation-icon-button.component';
import { RouterLink } from '@angular/router';
import { LinkFromHrefDirective } from '@common/routing/directive/link-from-href.directive';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';
import { DateUtils } from '@util/util/date.utils';
import { InnerHtmlDirective } from '@common/html/directive/inner-html.directive';
import { BannerGroupType } from '@shared/banner/model/banner-group.type';

const SLIDER_INTERVAL: number = DateUtils.convertSecondsToMilliseconds(4);

@Component({
  selector: 'auk-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['banner.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReplaceByPlaceholderOnErrorDirective,
    ThirdPartyBannerComponent,
    NgClass,
    SvgIconComponent,
    IconComponent,
    NavigationIconButtonComponent,
    RouterLink,
    LinkFromHrefDirective,
    InnerHtmlDirective,
  ],
})
export class BannerComponent extends NgUnsubscribe implements AfterViewInit, OnChanges, OnDestroy {

  /**
   * Note: This is propagated into {@link ThirdPartyBannerComponent}
   */
  @Input() public adElementId: AdElementIdType | Nil;
  @Input() public page: PageTypeEnumRequestBannerDto | Nil;
  @Input() public placement: PlacementsRequestBannerDtoEnum | Nil;
  /**
   * Whether banner images should be lazily loaded
   */
  @Input() public lazyLoadImages: boolean = false;
  @Input() public title: string | Nil;
  @Input() public titleSize: 'SMALL' | 'DEFAULT' = 'DEFAULT';
  /**
   * Whether, we should set width of banner to explicit value
   * Banner, is defaultly expanded to full width, so if if we want the banner to have explicit width, set this to `true`
   */
  @Input() public explicitBannerWidth: boolean = false;
  @Input() public presetBanner: BannerPlacementDto | Nil = null;

  @HostBinding('style.width.px') protected bannerWidth: number | Nil = null;

  protected group: BannerGroupType | Nil = null;
  protected aukroBanner: BannerPlacementDto | Nil;
  protected isDataDownloadRequired: boolean = true;
  protected selectedIndex: number | Nil = null;

  private subscriptionAukroBanner: Subscription | Nil;
  private subscriptionRefreshTrigger: Subscription | Nil;
  private swipeCoord: [number, number] | Nil;
  private swipeTime: number | Nil;
  private isAutoMoveAllowed: boolean = true;
  private interval: Subscription | Nil = null;

  constructor(
    private readonly bannersService: BannersService,
    private readonly bannerGoogleAnalyticsService: BannerGoogleAnalyticsService,
    private readonly platformCommonService: PlatformCommonService,
    private readonly preloadingRegisterService: PreloadingRegisterService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly ngZoneUtilService: NgZoneUtilService,
  ) {
    super();
  }

  public ngAfterViewInit(): void {
    if (!this.isDataDownloadRequired) {
      return;
    }

    this.loadBannerAndSubscription();
  }

  public ngOnChanges(changes: AukSimpleChanges<typeof this>): void {
    if (changes.presetBanner) {
      if (!this.presetBanner) {
        return;
      }

      this.group = 'AUKRO';
      this.aukroBanner = this.presetBanner;
      this.isDataDownloadRequired = false;
      void this.bannerGoogleAnalyticsService.trackBannerView([this.aukroBanner]);

      this.select(this.selectedIndex);
      if (this.platformCommonService.isBrowser) {
        this.initShowCaseTimer();
      }

      if (this.platformCommonService.isServer) {
        this.registerBannersAsLcp(this.aukroBanner.bannerPositions);
      }
    }

    if (changes.explicitBannerWidth || changes.presetBanner) {
      this.bannerWidth = this.explicitBannerWidth
        ? isNil(this.aukroBanner?.width)
          ? null
          : this.aukroBanner.width
        : null;
    }
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.destroy();
  }

  protected loadBannerAndSubscription(): void {
    this.loadBanner();

    this.subscriptionRefreshTrigger = this.bannersService.refreshTrigger
      .subscribe(() => {
        if (this.bannersService.isPlacementExisting(this.page, this.placement)) {
          this.loadBanner();
        } else {
          this.destroy();
        }
      });
  }

  protected get onlyFirstImage(): boolean {
    return this.platformCommonService.isServer;
  }

  protected onBannerClick(bannerPosition: BannerPositionDto, bannerDeployment: BannerDeploymentDto): void {
    void this.bannerGoogleAnalyticsService.trackBannerClick(
      this.aukroBanner,
      bannerPosition,
      bannerDeployment,
    );
  }

  public move(type: 'prev' | 'next', resetTimer: boolean = false): void {
    if (type === 'prev' && this.isPrevAllowed) {
      this.select(this.selectedIndex - 1);
      if (resetTimer) {
        this.initShowCaseTimer();
      }
    } else if (type === 'next' && this.isNextAllowed) {
      this.select(this.selectedIndex + 1);
      if (resetTimer) {
        this.initShowCaseTimer();
      }
    }
  }

  protected swipe(e: TouchEvent, when: string): void {
    const coord: [number, number] = [e.changedTouches[0].pageX, e.changedTouches[0].pageY];
    const time = new Date().getTime();

    if (when === 'start') {
      this.isAutoMoveAllowed = false;
      this.swipeCoord = coord;
      this.swipeTime = time;
    } else if (when === 'end') {
      this.isAutoMoveAllowed = true;
      const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
      const duration = time - this.swipeTime;

      if (
        duration < 1000 // Rapid
        && Math.abs(direction[0]) > 30 // Long enough
        && Math.abs(direction[0]) > Math.abs(direction[1] * 3)
      ) { // Horizontal enough
        if (direction[0] < 0) {
          this.move('next');
        } else {
          this.move('prev');
        }
      }
    }
  }

  protected autoMoveAllowed(value: boolean): void {
    this.isAutoMoveAllowed = value;
  }

  protected getDummyImagePlaceholder(bannerPosition: BannerPositionDto, width: number, height: number): DummyImagePlaceholderModel {
    return {
      text: bannerPosition.bannerDeployments[0].bannerName,
      width: width ?? 480,
      height: height ?? 300,
    };
  }

  protected get isArrowAllowed(): boolean {
    return this.aukroBanner?.bannerPositions?.length > 1;
  }

  protected get isPrevAllowed(): boolean {
    return this.isArrowAllowed && this.selectedIndex > 0;
  }

  protected get isNextAllowed(): boolean {
    return this.isArrowAllowed && this.selectedIndex < this.aukroBanner.bannerPositions.length - 1;
  }

  protected get transformStyle(): string {
    return `translateX(-${ this.selectedIndex * 100 }%)`;
  }

  private loadBanner(): void {
    if (!this.isDataDownloadRequired) {
      return;
    }

    if (this.bannersService.isAukroBanner(this.page, this.placement)) {
      if (!this.page || !this.placement) {
        return;
      }
      this.group = 'AUKRO';
      this.subscriptionAukroBanner = this.bannersService.waitForBanner(this.page, this.placement)
        .pipe(take(1))
        .subscribe((data: BannerPlacementDto) => {
          if (data) {
            this.aukroBanner = data;
            void this.bannerGoogleAnalyticsService.trackBannerView([this.aukroBanner]);
            this.registerBannersAsLcp(data.bannerPositions);

            if (this.platformCommonService.isBrowser) {
              this.initShowCaseTimer();
            }
            this.changeDetectorRef.markForCheck();
          } else {
            // If Aukro banner is not present, show third party banner.
            this.insertThirdPartyBanner();
          }
        });
    } else {
      this.insertThirdPartyBanner();
    }
  }

  /**
   * Sets group to THIRD_PARTY
   * Resets Aukro banner on this placement if there is any
   */
  private insertThirdPartyBanner(): void {

    this.aukroBanner = null;
    this.changeDetectorRef.detectChanges();

    if (!this.adElementId) {
      return;
    }

    this.group = 'THIRD_PARTY';
    this.changeDetectorRef.detectChanges();
  }

  private select(index: number): void {
    this.selectedIndex = index;
    this.changeDetectorRef.detectChanges();
  }

  private initShowCaseTimer(): void {
    this.interval?.unsubscribe();

    this.interval = this.ngZoneUtilService.intervalOut$(SLIDER_INTERVAL, false)
      .pipe(
        filter(() => this.isAutoMoveAllowed),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        if (this.isNextAllowed) {
          this.move('next');
        } else {
          this.select(0);
        }
      });
  }

  private destroy(): void {
    this.subscriptionAukroBanner?.unsubscribe();
    this.subscriptionRefreshTrigger?.unsubscribe();
  }

  private registerBannersAsLcp(bannerPositions: BannerPositionDto[]): void {
    // don't register banners as LCP candidates if they are lazily loaded
    if (ArrayUtils.isEmpty(bannerPositions) || this.lazyLoadImages) {
      return;
    }

    const priority: PreloadingLcpCandidatePriority = BANNERS_TO_REGISTER_AS_LCP
      .find((lb) => lb.page === this.page && lb.placement === this.placement)
      ?.priority;

    if (isNil(priority)) {
      return;
    }

    bannerPositions.forEach(bp => this.preloadingRegisterService
      .registerLcpImagePreloadingCandidate(bp?.bannerDeployments?.[0]?.imgUrl, priority));
  }

}
