import isNil from 'lodash-es/isNil';

export class HttpUtil {

  public static is5xxServerError(code: number): boolean {
    if (isNil(code)) {
      return false;
    }
    return Math.floor(code / 100) === 5;
  }

  public static is4xxClientError(code: number): boolean {
    if (isNil(code)) {
      return false;
    }
    return Math.floor(code / 100) === 4;
  }

  public static is2xxSuccessful(code: number): boolean {
    if (isNil(code)) {
      return false;
    }
    return Math.floor(code / 100) === 2;
  }

}
