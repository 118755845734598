import { PickupPointDto } from '@api/generated/defs/PickupPointDto';
import isNil from 'lodash-es/isNil';

export interface BalikovnaSelectedBranch {
  id: string;
  message: string;
  phone?: string;
  point: BalikovnaSelectedBranchData;
}

export interface BalikovnaSelectedBranchData {
  // id: number;
  // type: 'BALIKOVNY';
  zip: string;
  address: string;
  country: string;
  // atm: boolean;
  // 'coor_x_wgs84': number;
  // 'coor_y_wgs84': number;
  // coords: {x: number, y: number};
  // distanceMeters: number;
  // district: string;
  // 'municipality_district_name': string;
  // 'municipality_name': string;
  name: string;
  // 'opening_hours': [{'od_do': []}];
  // parking: boolean;
  // fulltext: string;
}

export interface BalikovnaConfig {
  type: string;
  phone: string;
  skipLocation: string;
}

// TODO(PDEV-20297) - remove this const and refactor to shipping_method.pickupPointSelection
export const BALIKOVNA_SHIPPING_CODES = ['AUKRO_BALIKOVNA'];
// TODO(PDEV-11487) - refactor shipping ID to CODE
export const BALIKOVNA_PICKUP_POINT_SHIPPING_IDS = [27];

// TODO(PDEV-11487) - refactor shipping ID to CODE - get enum from api BE
export enum BalikovnaShippingCodeEnum {
  AUKRO_BALIKOVNA = 'AUKRO_BALIKOVNA',
  AUKRO_BALIKOVNA_ADDRESS = 'AUKRO_BALIKOVNA_ADDRESS',
}

export class BalikovnaWidgetHelper {

  public static isBalikovnaAndHasEmptyPickupPoint(shippingOptionId: number, pickupPoint: PickupPointDto): boolean {
    return (
      BALIKOVNA_PICKUP_POINT_SHIPPING_IDS.includes(shippingOptionId) && (
        isNil(pickupPoint)
        || isNil(pickupPoint.id)
        || isNil(pickupPoint.name)
        || isNil(pickupPoint.url)
      )
    );
  }

}
