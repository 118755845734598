import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';
import { UserInterestStatisticsDto } from '@api/generated/defs/UserInterestStatisticsDto';
import { AppHeaderService } from '../../../../service/app-header.service';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { SubbrandService } from '@shared/subbrand/service/subbrand.service';
import { AuthenticationService } from '@shared/authentication/service/authentication.service';
import { Router } from '@angular/router';
import isNil from 'lodash-es/isNil';
import { ResponsivenessService } from '@common/responsiveness/service/responsiveness.service';
import { HeaderUserControlActionClickType } from './model/header-user-controls-action-click.model';
import { AppHeaderHamburgerService } from '@shared/app-header/service/app-header-hamburger.service';
import { AccountControlComponent } from '@shared/app-header/module/app-header-user-controls/component/account-control/account-control.component';
import { WatchingService } from '@shared/services/watching/watching.service';
import { CartOverviewDto } from '@api/generated/model';
import { CartService } from '@shared/cart/service/cart.service';
import { Nil } from '@util/helper-types/nil';

@Component({
  selector: 'auk-app-header-user-controls',
  templateUrl: './app-header-user-controls.component.html',
  styleUrls: ['./app-header-user-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppHeaderUserControlsComponent extends NgUnsubscribe implements OnInit, OnDestroy, AfterViewInit {

  @Input() public user: UserInterestStatisticsDto;
  @Input() public showSearchButton: boolean = false;

  /**
   * Emits menu toggle button y-axis bottom position (in px), everytime it changes (e.g. after resize)
   */
  @Output() public menuToggleBtnYPositionChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() public clickAction: EventEmitter<HeaderUserControlActionClickType> = new EventEmitter<HeaderUserControlActionClickType>();

  @ViewChild(AccountControlComponent) private readonly accountControlComponent: AccountControlComponent;
  @ViewChild('menuToggleBtnElm') private readonly menuToggleBtnElm: ElementRef<HTMLDivElement>;

  protected isMenuOpened: boolean = false;
  protected isSubbrandsEnabled: boolean = false;

  private isMdAndLower: boolean = false;
  private cartItemsRemainingCount: number | Nil;

  constructor(
    private readonly appHeaderService: AppHeaderService,
    private readonly appHeaderHamburgerService: AppHeaderHamburgerService,
    private readonly router: Router,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly subbrandService: SubbrandService,
    private readonly responsivenessService: ResponsivenessService,
    private readonly authenticationService: AuthenticationService,
    private readonly watchingService: WatchingService,
    private readonly cartService: CartService,
  ) {
    super();

    this.subbrandService.subbrandsEnabled$
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((isSubbrandsEnabled) => {
        this.isSubbrandsEnabled = isSubbrandsEnabled;
      });

    this.responsivenessService.isMdAndLower$
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((isMdAndLower) => {
        this.isMdAndLower = isMdAndLower;
        this.changeDetectorRef.markForCheck();
      });
  }

  public ngOnInit(): void {
    this.appHeaderService.getModalPanelStateChange()
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((state) => {
        this.isMenuOpened = state === 'navigation';
        this.changeDetectorRef.markForCheck();
      });

    this.cartService.getCartOverview()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((cart: CartOverviewDto) => {
        this.cartItemsRemainingCount = cart?.cart?.quantity;
        this.changeDetectorRef.detectChanges();
      });
  }

  public ngAfterViewInit(): void {
    this.recalculateMenuToggleBtnYPosition();
  }

  public recalculateMenuToggleBtnYPosition(): void {
    const btnElement = this.menuToggleBtnElm?.nativeElement;

    if (isNil(btnElement)) {
      return;
    }

    const menuToggleBtnYPosition = btnElement.getBoundingClientRect?.()?.bottom ?? 0;

    this.menuToggleBtnYPositionChange.emit(menuToggleBtnYPosition);
  }

  protected toggleNavigationMenu(): void {
    if (this.isSubbrandsEnabled) {
      this.appHeaderHamburgerService.openCategoryMenu();
    } else {
      this.appHeaderService.setModalPanel(this.isMenuOpened ? null : 'navigation');
    }
  }

  protected buttonClicked(action: HeaderUserControlActionClickType): void {
    this.clickAction.emit(action);
  }

  protected get showSearch(): boolean {
    return this.showSearchButton && this.isMdAndLower;
  }

  public get showCart(): boolean {
    if (!this.isMdAndLower) {
      return true;
    }
    if (isNil(this.cartItemsRemainingCount) || this.cartItemsRemainingCount === 0) {
      return false;
    }
    return true;
  }

  public onUserInfoClick(): void {
    if (isNil(this.user)) {
      this.startLoginTask();
      return;
    }

    const url: string = this.responsivenessService.isLgAndLower ? '/moje-aukro/rozcestnik' : '/moje-aukro/moje-nakupy/zakoupeno';

    this.accountControlComponent.measureAvatarOrLinkClick(url);
    void this.router.navigate([url]);
  }

  private startLoginTask(): void {
    this.authenticationService.loginTroughPopupFlow('LOGIN')
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

}
