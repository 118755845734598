import { Injectable } from '@angular/core';
import { ExchangedPriceService } from '@shared/currency/services/exchanged-price.service';
import { ExchangeRatesService } from '@shared/currency/services/exchange-rates.service';
import { YuspRecommendationResultInternal } from './model/yusp-recommendation-result-internal.model';
import { combineLatestWith, Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ExchangeRatesDto } from '@api/aukro-api/model/exchange-rates-dto';
import { YuspItemResultInternal } from './model/yusp-item-result-internal.model';
import { YuspPersonalizationConstants } from './yusp-personalization.constants';
import { UserCurrencyPreferenceService } from '@shared/currency/service/user-currency-preference.service';
import { CurrencyCode } from '@shared/currency/model/currency-code.model';
import isNil from 'lodash-es/isNil';
import isEmpty from 'lodash-es/isEmpty';
import { BoughtItemExtendedDto } from '../../../../typings/original/internal';
import { YuspBidEventParams, YuspBuyEventParams } from './yusp-personalization.helper';
import { OfferDetailDto } from '@api/aukro-api/model/offer-detail-dto';
import { MoneyDto } from '@api/aukro-api/model/money-dto';

/**
 * Performs currency exchange for data which is from YUSP
 */
@Injectable({
  providedIn: 'root',
})
export class YuspPersonalizationPriceExchangeService {

  constructor(
    private readonly exchangedPriceService: ExchangedPriceService,
    private readonly exchangeRatesService: ExchangeRatesService,
    private readonly userCurrencyPreferenceService: UserCurrencyPreferenceService,
  ) {
  }

  public exchangeYuspRecommendationResult(yuspDto: YuspRecommendationResultInternal): Observable<YuspRecommendationResultInternal> {
    if (isEmpty(yuspDto?.items)) {
      // if there're no items and products, we return the original dto without any change
      return of(yuspDto);
    }

    // Data from YUSP always in CZK
    return this.exchangeRatesService.exchangeRates(YuspPersonalizationConstants.YUSP_CURRENCY)
      .pipe(
        // Exchange to user preferred currency (to show in recommendation boxes, etc.)
        combineLatestWith(this.userCurrencyPreferenceService.preferredCurrencyCode$),
        take(1),
        map(([rates, userPreferredCurrencyCode]: [ExchangeRatesDto, CurrencyCode]) => {

          yuspDto.items?.forEach((yuspItem: YuspItemResultInternal) => {

            yuspItem.biddingPrice =
                            this.exchangedPriceService.exchange(yuspItem.biddingPrice, rates, userPreferredCurrencyCode);

            yuspItem.buyNowPrice =
                            this.exchangedPriceService.exchange(yuspItem.buyNowPrice, rates, userPreferredCurrencyCode);

            yuspItem.priceWithShipping =
                            this.exchangedPriceService.exchange(yuspItem.priceWithShipping, rates, userPreferredCurrencyCode);

          });
          return yuspDto;
        }),
      );
  }

  public exchangeTrackBidEvent(item: OfferDetailDto, currentBid: MoneyDto): Observable<YuspBidEventParams> {
    // TODO needs to be refactored when adding another currency (e.g. USD)
    return this.exchangeRatesService.exchangeRates('EUR')
      .pipe(
        map(rates => {
          const itemPrice = this.exchangedPriceService.exchange(item.price, rates, YuspPersonalizationConstants.YUSP_CURRENCY);
          const bidPrice = this.exchangedPriceService.exchange(currentBid, rates, YuspPersonalizationConstants.YUSP_CURRENCY);
          const yuspBidParams: YuspBidEventParams = {
            type: 'event',
            eventType: 'BID',
            itemId: item.itemId.toString(),
            biddingPrice: itemPrice?.amount.toString(),
            userMaximumBid: bidPrice?.amount.toString(),
            sellerId: item.seller.userId.toString(),
          };
          return yuspBidParams;
        },
        ),
      );
  }

  public exchangeTrackBuyEvent(boughtItems: BoughtItemExtendedDto[]): Observable<YuspBuyEventParams[]> {
    // TODO needs to be refactored when adding another currency (e.g. USD)
    return this.exchangeRatesService.exchangeRates('EUR')
      .pipe(
        map(rates => boughtItems
          .filter((item: BoughtItemExtendedDto) => !isNil(item))
          .map((item: BoughtItemExtendedDto) => {

            const unitPrice = this.exchangedPriceService.exchange(item.unitPrice, rates, YuspPersonalizationConstants.YUSP_CURRENCY);

            const event: YuspBuyEventParams = {
              type: 'event',
              eventType: 'BUY',
              buyType: 'buynow',
              unitPrice: unitPrice?.amount?.toString(),
              quantity: item.itemQuantity?.toString(),
              sellerId: item.sellerId?.toString(),
              itemId: item.itemId?.toString(),
            };

            return event;
          })),
      );
  }

}
