import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { RestHttpClientService } from '@api/rest/rest-http-client.service';
import * as model from '../model';
import { SubbrandType } from '@shared/subbrand/model/subbrand.type';

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface SaveUserAbsenceUsingPOSTParams {
  /** userAbsenceDto */
  userAbsenceDto: model.UserAbsenceDto;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface SetNotificationSettingsUsingPOST_1Params {
  /** List of user notification settings */
  list: model.UserNotificationDto[];
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface FindUserProfileUsingPOSTParams {
  /** Username of user to show profile of */
  username: string;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface GetUserProfileByUsernameAllUsingGETParams {
  /** Username of user to show profile of */
  username: string;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface FindUserProfileByUsernameMinimalUsingGETParams {
  /** Username of user to show profile of */
  username: string;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface UpdateSellerInfoUsingPOSTParams {
  /** Seller info dto */
  sellerInfoDto: model.SellerInfoDto;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface SetOnlyVerifiedBuyersParams {
  /** enableOnlyVerifiedBuyers */
  enableOnlyVerifiedBuyers: boolean;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface UpdateUserPreferenceSubbrandDto {
  subbrand: SubbrandType;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface EnableUserSubbrandParams {
  subbrandsEnabled: boolean;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface ShowNameParams {
  /** Suggest text */
  text: string;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface EncloseDocumentsParams {
  /** Attachment ids (enclosed documents) */
  attachmentIds: number[];
  /** Verification request token */
  token: string;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface PrivateParams {
  /**
   * id
   * format: int64
   */
  id: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface DetailParams {
  /**
   * Id of user to show account details
   * format: int64
   */
  id: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface AddressParams {
  /**
   * id
   * format: int64
   */
  id: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface GetEmailInfoParams {
  /**
   * id
   * format: int64
   */
  id: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface GetLoginInfoParams {
  /**
   * id
   * format: int64
   */
  id: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface InitBasicDataParams {
  /**
   * id
   * format: int64
   */
  id: number;
  /** User's data */
  initBasicDataFormDto: model.InitBasicDataFormDto;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface UpdateCompanyDetailParams {
  /**
   * id
   * format: int64
   */
  id: number;
  /** updateCompanyAccountDetailDto */
  updateCompanyAccountDetailDto: model.UpdateCompanyAccountDetailDto;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface UpdateLoginInfoParams {
  /** User login data */
  changeLoginDto: model.ChangeLoginDto;
  /**
   * id
   * format: int64
   */
  id: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface UpdateNameParams {
  /** Change name data */
  changeNameRequestDataDto: model.ChangeNameRequestDataDto;
  /**
   * id
   * format: int64
   */
  id: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface UpdateUserDetailParams {
  /**
   * id
   * format: int64
   */
  id: number;
  /** User's data */
  userAccountDetailDto: model.UserAccountDetailDto;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface ResendActivationEmailParams {
  /**
   * User ID
   * format: int64
   */
  id: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface GetUserProfileUsingGETParams {
  /**
   * Id of user to show profile of
   * format: int64
   */
  id: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface GetUserProfileByIdAllUsingGETParams {
  /**
   * Id of user to show profile of
   * format: int64
   */
  id: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface AllReceivedParams {
  /** Feedback filter */
  feedbackFilter?: model.FeedbackFilter;
  /**
   * Id of user to show profile of
   * format: int64
   */
  id: number;
  /** format: int32 */
  page?: number;
  /** format: int32 */
  size?: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface AllReceivedFromBuyersParams {
  /** Feedback filter */
  feedbackFilter?: model.FeedbackFilter;
  /**
   * Id of user to show profile of
   * format: int64
   */
  id: number;
  /** format: int32 */
  page?: number;
  /** format: int32 */
  size?: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface AllReceivedFromSellersParams {
  /** Feedback filter */
  feedbackFilter?: model.FeedbackFilter;
  /**
   * Id of user to show profile of
   * format: int64
   */
  id: number;
  /** format: int32 */
  page?: number;
  /** format: int32 */
  size?: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface AllSendParams {
  /** Feedback filter */
  feedbackFilter?: model.FeedbackFilter;
  /**
   * Id of user to show profile of
   * format: int64
   */
  id: number;
  /** format: int32 */
  page?: number;
  /** format: int32 */
  size?: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface GetUserProfileByIdMinimalUsingGETParams {
  /**
   * Id of user to show profile of
   * format: int64
   */
  id: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface GetUserAbsenceStatusUsingGETParams {
  /**
   * userId
   * format: int64
   */
  userId: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface CreateRequestForChangeToCompanyParams {
  /**
   * userId
   * format: int64
   */
  userId: number;
  /** verifyCompanyRequestDataDto */
  verifyCompanyRequestDataDto: model.VerifyCompanyRequestDataDto;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface GetSellerInfoUsingGETParams {
  /**
   * userId
   * format: int64
   */
  userId: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
@Injectable({
  providedIn: 'root',
})
export class UserApiService extends RestHttpClientService {
  constructor(injector: Injector) { super(injector); }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public saveUserAbsenceUsingPOST(params: SaveUserAbsenceUsingPOSTParams, headers: { [key: string]: string } = {}): Observable<model.UserAbsenceDto> {
    let bodyParams = params.userAbsenceDto;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/users/absence`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public cancel(headers: { [key: string]: string } = {}): Observable<model.UserAbsenceDto> {
    return this.postGenerated(`/users/absence/cancel`, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public history(): Observable<model.UserAbsenceDto[]> {
    return this.getGenerated(`/users/absence/history`);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public actualStatistics(): Observable<model.UserInterestStatisticsDto> {
    return this.getGenerated(`/users/actualStatistics`);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public unsubscribe(headers: { [key: string]: string } = {}): Observable<void> {
    return this.postGenerated(`/users/email/similarItems/unsubscribe`, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public getNotificationSettingsUsingGET(): Observable<model.UserNotificationSettingsDto> {
    return this.getGenerated(`/users/notification/settings`);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public setNotificationSettingsUsingPOST_1(params: SetNotificationSettingsUsingPOST_1Params, headers: { [key: string]: string } = {}): Observable<void> {
    let bodyParams = params.list;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/users/notification/settings`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public findUserProfileUsingPOST(params: FindUserProfileUsingPOSTParams, headers: { [key: string]: string } = {}): Observable<model.UserProfileDto> {
    let queryParams = {
      username: params.username,
    };
    let bodyParams;
    let formDataParams;
    return this.postGenerated(`/users/profile`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public getUserProfileByUsernameAllUsingGET(params: GetUserProfileByUsernameAllUsingGETParams): Observable<model.NewUserProfileAllDto> {
    let queryParams = {
      username: params.username,
    };
    return this.getGenerated(`/users/profile/all`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public findUserProfileByUsernameMinimalUsingGET(params: FindUserProfileByUsernameMinimalUsingGETParams): Observable<model.NewUserProfileBaseDto> {
    let queryParams = {
      username: params.username,
    };
    return this.getGenerated(`/users/profile/minimal`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public updateSellerInfoUsingPOST(params: UpdateSellerInfoUsingPOSTParams, headers: { [key: string]: string } = {}): Observable<model.SellerInfoDto> {
    let bodyParams = params.sellerInfoDto;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/users/sellerInfo`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public setOnlyVerifiedBuyers(params: SetOnlyVerifiedBuyersParams, headers: { [key: string]: string } = {}): Observable<boolean> {
    let pathParams = {
      enableOnlyVerifiedBuyers: params.enableOnlyVerifiedBuyers,
    };
    let bodyParams;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/users/setOnlyVerifiedBuyers/${pathParams.enableOnlyVerifiedBuyers}`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public showName(params: ShowNameParams): Observable<model.UserShowNameDto[]> {
    let queryParams = {
      text: params.text,
    };
    return this.getGenerated(`/users/suggest/showName`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public userProfileStatistics(): Observable<model.UserProfileStatisticsDto> {
    return this.getGenerated(`/users/userProfileStatistics`);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public encloseDocuments(params: EncloseDocumentsParams, headers: { [key: string]: string } = {}): Observable<void> {
    let queryParams = {
      attachmentIds: params.attachmentIds,
    };
    let pathParams = {
      token: params.token,
    };
    let bodyParams;
    let formDataParams;
    return this.postGenerated(`/users/verificationRequest/${pathParams.token}/encloseDocuments`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public private(params: PrivateParams, headers: { [key: string]: string } = {}): Observable<model.CompanyAccountDetailDto> {
    let pathParams = {
      id: params.id,
    };
    let bodyParams;
    let queryParams;
    return this.putGenerated(`/users/${pathParams.id}/account/changeType/private`, bodyParams, queryParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public detail(params: DetailParams): Observable<model.AccountDetailDto> {
    let pathParams = {
      id: params.id,
    };
    let queryParams;
    return this.getGenerated(`/users/${pathParams.id}/account/detail`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public address(params: AddressParams): Observable<model.AddressDtoRes> {
    let pathParams = {
      id: params.id,
    };
    let queryParams;
    return this.getGenerated(`/users/${pathParams.id}/account/detail/address`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public getEmailInfo(params: GetEmailInfoParams): Observable<model.ChangeEmailDto> {
    let pathParams = {
      id: params.id,
    };
    let queryParams;
    return this.getGenerated(`/users/${pathParams.id}/account/detail/getEmailInfo`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public getLoginInfo(params: GetLoginInfoParams): Observable<model.ChangeLoginDto> {
    let pathParams = {
      id: params.id,
    };
    let queryParams;
    return this.getGenerated(`/users/${pathParams.id}/account/detail/getLoginInfo`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public initBasicData(params: InitBasicDataParams, headers: { [key: string]: string } = {}): Observable<model.JWTInfo> {
    let pathParams = {
      id: params.id,
    };
    let bodyParams = params.initBasicDataFormDto;
    let queryParams;
    return this.putGenerated(`/users/${pathParams.id}/account/detail/initBasicData`, bodyParams, queryParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public updateCompanyDetail(params: UpdateCompanyDetailParams, headers: { [key: string]: string } = {}): Observable<void> {
    let pathParams = {
      id: params.id,
    };
    let bodyParams = params.updateCompanyAccountDetailDto;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/users/${pathParams.id}/account/detail/updateCompanyDetail`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public updateLoginInfo(params: UpdateLoginInfoParams, headers: { [key: string]: string } = {}): Observable<model.ChangeLoginDto> {
    let bodyParams = params.changeLoginDto;
    let pathParams = {
      id: params.id,
    };
    let queryParams;
    return this.putGenerated(`/users/${pathParams.id}/account/detail/updateLoginInfo`, bodyParams, queryParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public updateName(params: UpdateNameParams, headers: { [key: string]: string } = {}): Observable<boolean> {
    let bodyParams = params.changeNameRequestDataDto;
    let pathParams = {
      id: params.id,
    };
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/users/${pathParams.id}/account/detail/updateName`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public updateUserDetail(params: UpdateUserDetailParams, headers: { [key: string]: string } = {}): Observable<void> {
    let pathParams = {
      id: params.id,
    };
    let bodyParams = params.userAccountDetailDto;
    let queryParams;
    return this.putGenerated(`/users/${pathParams.id}/account/detail/updateUserDetail`, bodyParams, queryParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public resendActivationEmail(params: ResendActivationEmailParams, headers: { [key: string]: string } = {}): Observable<number> {
    let pathParams = {
      id: params.id,
    };
    let bodyParams;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/users/${pathParams.id}/account/resendActivationEmail`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public getUserProfileUsingGET(params: GetUserProfileUsingGETParams): Observable<model.UserProfileDto> {
    let pathParams = {
      id: params.id,
    };
    let queryParams;
    return this.getGenerated(`/users/${pathParams.id}/profile`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public getUserProfileByIdAllUsingGET(params: GetUserProfileByIdAllUsingGETParams): Observable<model.NewUserProfileAllDto> {
    let pathParams = {
      id: params.id,
    };
    let queryParams;
    return this.getGenerated(`/users/${pathParams.id}/profile/all`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public allReceived(params: AllReceivedParams, headers: { [key: string]: string } = {}): Observable<model.FeedbackDtoPageResources> {
    let bodyParams = params.feedbackFilter;
    let pathParams = {
      id: params.id,
    };
    let queryParams = {
      page: params.page,
      size: params.size,
    };
    let formDataParams;
    return this.postGenerated(`/users/${pathParams.id}/profile/feedback/allReceived`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public allReceivedFromBuyers(params: AllReceivedFromBuyersParams, headers: { [key: string]: string } = {}): Observable<model.FeedbackDtoPageResources> {
    let bodyParams = params.feedbackFilter;
    let pathParams = {
      id: params.id,
    };
    let queryParams = {
      page: params.page,
      size: params.size,
    };
    let formDataParams;
    return this.postGenerated(`/users/${pathParams.id}/profile/feedback/allReceivedFromBuyers`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public allReceivedFromSellers(params: AllReceivedFromSellersParams, headers: { [key: string]: string } = {}): Observable<model.FeedbackDtoPageResources> {
    let bodyParams = params.feedbackFilter;
    let pathParams = {
      id: params.id,
    };
    let queryParams = {
      page: params.page,
      size: params.size,
    };
    let formDataParams;
    return this.postGenerated(`/users/${pathParams.id}/profile/feedback/allReceivedFromSellers`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public allSend(params: AllSendParams, headers: { [key: string]: string } = {}): Observable<model.FeedbackDtoPageResources> {
    let bodyParams = params.feedbackFilter;
    let pathParams = {
      id: params.id,
    };
    let queryParams = {
      page: params.page,
      size: params.size,
    };
    let formDataParams;
    return this.postGenerated(`/users/${pathParams.id}/profile/feedback/allSend`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public getUserProfileByIdMinimalUsingGET(params: GetUserProfileByIdMinimalUsingGETParams): Observable<model.NewUserProfileBaseDto> {
    let pathParams = {
      id: params.id,
    };
    let queryParams;
    return this.getGenerated(`/users/${pathParams.id}/profile/minimal`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public getUserAbsenceStatusUsingGET(params: GetUserAbsenceStatusUsingGETParams): Observable<model.UserAbsenceDto> {
    let pathParams = {
      userId: params.userId,
    };
    let queryParams;
    return this.getGenerated(`/users/${pathParams.userId}/absence`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public createRequestForChangeToCompany(params: CreateRequestForChangeToCompanyParams, headers: { [key: string]: string } = {}): Observable<void> {
    let pathParams = {
      userId: params.userId,
    };
    let bodyParams = params.verifyCompanyRequestDataDto;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/users/${pathParams.userId}/createRequestForChangeToCompany`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public getSellerInfoUsingGET(params: GetSellerInfoUsingGETParams): Observable<model.SellerInfoDto> {
    let pathParams = {
      userId: params.userId,
    };
    let queryParams;
    return this.getGenerated(`/users/${pathParams.userId}/sellerInfo`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public updateUserLastSubbrand(params: UpdateUserPreferenceSubbrandDto, headers: { [key: string]: string } = {}): Observable<void> {
    let bodyParams = params;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/users/subbrand`, bodyParams, queryParams, formDataParams, headers)
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public enableUserSubbrandFeatureFlag(params: EnableUserSubbrandParams, headers: { [key: string]: string } = {}): Observable<void> {
    let bodyParams = params;
    let queryParams = {
      subbrandsEnabled: params.subbrandsEnabled,
    };
    let formDataParams;
    return this.postGenerated(`/users/subbrand-enable`, bodyParams, queryParams, formDataParams, headers)
  }

}
