<!-- ITS DUPLICATED BECAUSE OF BUG IN SVG LIB - SSR FIX -->
<a class="main-logo tw-z-50 tw-cursor-pointer" routerLink="/">
  @if ((getLogoId$() | async); as logoId) {
    @if (logoId === 'logo') {
      <!-- Full logo -->
      <span [ngClass]="{ '!tw-hidden': forceSmallLogo, 'tw-block': !forceSmallLogo }">
        <auk-svg-icon
          [iconId]="'logo'"
          class="!tw-w-[75px] lg:!tw-w-[125px] !tw-h-[26px] lg:!tw-h-[40px] !tw-fill-primary"
          (click)="onLogoClick()"
        ></auk-svg-icon>
      </span>
      <span [ngClass]="{ '!tw-block': forceSmallLogo, 'tw-hidden': !forceSmallLogo }">
        <auk-svg-icon
          [iconId]="'antique-bazaar-link'"
          class="!tw-w-[34px] !tw-fill-antique"
          (click)="onLogoClick()"
        ></auk-svg-icon>
      </span>
    }
    @if (logoId === 'logo-antique') {
      <!-- Full logo -->
      <span [ngClass]="{ '!tw-hidden': forceSmallLogo, 'tw-block': !forceSmallLogo }">
        <auk-svg-icon
          [iconId]="'logo-antique'"
          class="lg:!tw-w-[155px] !tw-w-[138px] lg:!tw-h-[50px] !tw-fill-antique"
          (click)="onLogoClick()"
        ></auk-svg-icon>
      </span>
      <span [ngClass]="{ '!tw-block': forceSmallLogo, 'tw-hidden': !forceSmallLogo }">
        <auk-svg-icon
          [iconId]="'antique-logo'"
          class="!tw-w-[34px] !tw-fill-antique"
          (click)="onLogoClick()"
        ></auk-svg-icon>
      </span>
    }
    @if (logoId === 'logo-antique-sk') {
      <!-- Full logo -->
      <span [ngClass]="{ '!tw-hidden': forceSmallLogo, 'tw-block': !forceSmallLogo }">
        <auk-svg-icon
          [iconId]="'logo-antique-sk'"
          class="lg:!tw-w-[155px] !tw-w-[138px] lg:!tw-h-[50px] !tw-fill-antique"
          (click)="onLogoClick()"
        ></auk-svg-icon>
      </span>
      <span [ngClass]="{ '!tw-block': forceSmallLogo, 'tw-hidden': !forceSmallLogo }">
        <auk-svg-icon
          [iconId]="'antique-logo'"
          class="!tw-w-[34px] !tw-fill-antique"
          (click)="onLogoClick()"
        ></auk-svg-icon>
      </span>
    }
  }
</a>
