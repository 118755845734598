import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Optional } from '@angular/core';
import { environment } from '@environment';
import { environmentConstants } from '@env-constants';
import { BehaviorSubject, merge, Observable, of } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { ENVIRONMENT_PRODUCTION } from '../../../app.constants'; // TODO: [PDEV-18307] - fix dependency
import { SessionStorageService } from '@common/services/storage/session-storage.service';
import { StringUtils } from '@util/util/string.utils';
import { ExpressRequest } from '@common/platform/model/request';
import { Response as ExpressResponseOriginal } from 'express';
import { WINDOW_OBJECT } from '@util/const/window-object';
import { DomainCode } from '@shared/platform/domain.model';
import { CookieService } from '@common/cookie/service/cookie.service';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import isNil from 'lodash-es/isNil';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { RESPONSE } from '../../../server/const/response';
import { REQUEST } from '../../../server/const/request';
import { DomainService } from '@shared/platform/domain.service';
import { PlatformType } from '@shared/platform/model/platform.type';

export const SW_UPDATE_KEY: string = 'swupdate';
export const DESKTOP_MIN_WIDTH: number = 768;
export const TABLET_MAX_WIDTH: number = 1024;

const domainEnvironmentMap: Record<DomainCode, string> = {
  CZ: environment.APP_HOST,
  SK: environment.HOST_FRONTEND_SK,
};

@Injectable({
  providedIn: 'root',
})
export class PlatformService {

  constructor(
    @Optional() @Inject(REQUEST) private readonly request: ExpressRequest,
    @Optional() @Inject(RESPONSE) private readonly response: ExpressResponseOriginal,
    @Inject(WINDOW_OBJECT) private readonly window: Window,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly sessionStorageService: SessionStorageService,
    private readonly cookieService: CookieService,
    private readonly platformCommonService: PlatformCommonService,
    private readonly ngZoneUtilService: NgZoneUtilService,
    private readonly domainService: DomainService,
  ) {
    this.initializeOldNativeAppsDetection();

    this.initializeDeviceType();
  }

  private _isOldAndroidNativeApp: boolean = false;

  private _isMobile$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _isTablet$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * Returns whether app is running in old Android native app
   * @deprecated do not use for new iOS native app, use {@link PlatformCommonService.isNativeAndroidApp} instead
   */
  public get isOldAndroidNativeApp(): boolean {
    return this._isOldAndroidNativeApp;
  }

  /**
   * @deprecated
   * Use {@link ResponsivenessService} isActiveBreakpointInRange$ fn instead
   * Returns whether app is running on mobile
   */
  public get isMobile$(): Observable<boolean> {
    return this._isMobile$.asObservable()
      .pipe(distinctUntilChanged());
  }

  /**
   * @deprecated
   * Use {@link ResponsivenessService} isActiveBreakpointInRange fn instead
   * Returns whether app is running on mobile
   * Use only if you need current actual value, otherwise you should always use {@link isMobile$}
   */
  public get isMobile(): boolean {
    return this._isMobile$.getValue();
  }

  /**
   * @deprecated
   * Use {@link ResponsivenessService} isActiveBreakpointInRange$ fn instead
   * Returns whether app is running on mobile
   */
  public get isTablet$(): Observable<boolean> {
    return this._isTablet$.asObservable()
      .pipe(distinctUntilChanged());
  }

  /**
   * @deprecated
   * Use {@link ResponsivenessService} isActiveBreakpointInRange fn instead
   * Returns whether app is running on tablet
   * Use only if you need current actual value, otherwise you should always use {@link isTablet$}
   */
  public get isTablet(): boolean {
    return this._isTablet$.value;
  }

  private _isOldIosNativeApp: boolean = false;

  /**
   * Returns whether app is running in old iOS native app
   * @deprecated do not use for new iOS native app, use {@link PlatformCommonService.isNativeIosApp} instead
   */
  public get isOldIosNativeApp(): boolean {
    return this._isOldIosNativeApp;
  }

  /**
   * Returns if app is running on touch device
   */
  public get isTouchDevice(): boolean {
    return window.matchMedia('(hover: none)').matches;
  }

  /**
   * Returns whether app is running in iOS
   */
  public get isIos(): boolean {
    return this.platformCommonService.isBrowser ?
      /iPad|iPhone|iPod/.test(this.window.navigator.userAgent) && !this.window['MSStream'] :
      this.isUserAgentIos();
  }

  public get isSafari(): boolean {
    if (!this.platformCommonService.isBrowser) {
      return this.request?.useragent?.isSafari;
    }
    return this.window.navigator.userAgent.includes('Safari') && !this.window.navigator.userAgent.includes('Chrome');
  }

  /**
   * @deprecated
   *
   * instead use {@link PlatformCommonService} isBrowser getter
   */
  public get isBrowser(): boolean {
    return this.platformCommonService.isBrowser;
  }

  /**
   * @deprecated
   * instead use {@link PlatformCommonService} isServer getter
   */
  public get isServer(): boolean {
    return this.platformCommonService.isServer;
  }

  /**
   * Returns whether app is running in server and user agent is not bot
   */
  public get isServerAndNotBot(): boolean {
    return this.platformCommonService.isServer && !this.isBot;
  }

  /**
   * Returns whether app is running in server on mobile and user agent is not bot.
   */
  public get isServerAndMobileAndNotBot(): boolean {
    return this.platformCommonService.isServer && !this.isBot && this.isMobile;
  }

  /**
   * Returns whether app is running in server on mobile and user agent is not bot.
   */
  public get isServerAndTabletAndNotBot(): boolean {
    return this.platformCommonService.isServer && !this.isBot && this.isTablet;
  }

  /**
   * Returns whether user agent is bot
   */
  public get isBot(): boolean {
    if (!this.platformCommonService.isServer) {
      return false;
    }

    return StringUtils.parseBoolean(this.request.headers['x-is-bot'] as string);
  }

  /**
   * Returns current url in SSR
   */
  public get serverHref(): string | null {
    try {
      return this.serverHost + this.request.url;
    } catch (error) {
      console.log('Error on fetching request header referer : ', error);
    }
  }

  /**
   * Returns current host in SSR
   */
  public get serverHost(): string | null {
    try {
      return this.getScheme() + environment.APP_HOST;
    } catch (error) {
      console.log('Error on fetching request header referer : ', error);
    }
  }

  /**
   * Returns whether SSR url has query params
   */
  public get hasSsrQueryParamsInUrl(): boolean {
    return this.serverHref.indexOf('?') > -1;
  }

  /**
   * Returns domain url by parameter (full url with https://)
   * @param domainCode
   */
  public getDomainUrl(domainCode: DomainCode): string | null {
    return this.getScheme() + this.getDomainHost(domainCode);
  }

  /**
   * Returns domain host by parameter
   * @param domainCode
   */
  public getDomainHost(domainCode: DomainCode): string | null {
    return domainEnvironmentMap[domainCode];
  }

  /**
   * Returns domain host by parameter
   */
  public getCurrentDomainHost(): string {
    return domainEnvironmentMap[this.domainService.domain];
  }

  /**
   * Returns domain host by parameter, upper case capital letter
   * @param domainCode
   */
  public getDomainHostUpperCaseCapitalLetter(domainCode: DomainCode): string | null {
    if (!isNil(domainCode)) {
      return domainEnvironmentMap[domainCode][0].toUpperCase() + domainEnvironmentMap[domainCode].slice(1);
    }

  }

  /**
   * Redirects on SSR
   * @param url
   * @param status
   */
  public redirect(url: string, status: number): void {
    if (!this.platformCommonService.isServer) {
      return;
    }

    if (url === this.request.url) {
      return;
    }

    if (this.response.finished) {
      this.request._r_count = (this.request._r_count || 0) + 1;
      if (this.request._r_count > 10) {
        console.error('Detected a redirection loop. killing the nodejs process');
        process.exit(1);
      }
    } else {
      this.response.redirect(status, url);
      this.response.end();
    }
  }

  /**
   * Sets response status code for SSR
   * @param statusCode
   */
  public setStatusCode(statusCode: number): void {
    try {
      this.response.status(statusCode);
    } catch (error) {
      console.log('Error on fetching response: ', error);
    }
  }

  /**
   * Checks if user is online
   * On SSR return always TRUE because of principe of SSR
   */
  public isUserOnline(): Observable<boolean> {
    if (this.platformCommonService.isBrowser) {
      return merge(
        this.ngZoneUtilService.fromEventOut$(this.window, 'online'),
        this.ngZoneUtilService.fromEventOut$(this.window, 'offline'),
      ).pipe(
        map(() => this.window.navigator.onLine),
      );
    }
    return of(true);
  }

  public static get platformType(): PlatformType {
    if (PlatformCommonService.isNativeAndroidApp) {
      return 'NATIVE_APP_ANDROID';
    }

    if (PlatformCommonService.isNativeIosApp) {
      return 'NATIVE_APP_IOS';
    }

    return 'WEB';
  }

  /**
   * Sets {@link _isOldAndroidNativeApp } {@link _isOldIosNativeApp} values. The value has to be saved for future page reloads.
   * The storage type has to be session storage, because local storage is shared between TWA and browser.
   * There is duplicated method in index.tpl
   * - [android mobile PWA] detection has to be saved in session, because there is no referer after reload
   * - [ios native] detection is evaluated on every reload
   * @deprecated do not use for new iOS native app, use logic in {@link PlatformCommonService} instead
   */
  private initializeOldNativeAppsDetection(): void {
    if (this.platformCommonService.isBrowser && !PlatformCommonService.isNativeApp) {
      // [android mobile PWA] detection
      const isAndroidSavedValue = this.sessionStorageService.getItem<boolean>(environmentConstants.IS_ANDROID_MOBILE_APP_PWA_STORAGE_KEY);
      if (isAndroidSavedValue !== null) {
        this._isOldAndroidNativeApp = isAndroidSavedValue && !PlatformCommonService.isNativeApp;
      } else {
        // TWA on Android has specific document referrer
        this._isOldAndroidNativeApp = (!!(
          this.document.referrer
          && this.document.referrer.includes('android-app://' + environmentConstants.ANDROID_APP_PACKAGE_NAME))
          && !PlatformCommonService.isNativeApp
        );
        // save for use case when user refreshes page in TWA container on Android
        this.setAndroidAppDetector(this._isOldAndroidNativeApp);
      }

      // [ios native] detection
      this._isOldIosNativeApp = this.cookieService.get(environmentConstants.IS_IOS_NATIVE_COOKIE_NAME) === 'true'
        && !PlatformCommonService.isNativeApp;
    }
  }

  /**
   * This is here for specific use when TWA Android app is redirected to different domain, the document.referrer android detector
   * does not work correctly, so it needs to be overwriten by domain-redirect service to correctly detect android app.
   * @param value
   */
  public setAndroidAppDetector(value: boolean): void {
    this.sessionStorageService.setItem(environmentConstants.IS_ANDROID_MOBILE_APP_PWA_STORAGE_KEY, value);
  }

  private isUserAgentIos(): boolean {
    if (this.isBrowser) {
      return false;
    }

    return this.request?.useragent?.platform === 'Apple iOS';
  }

  public getScheme(): string {
    return environment.ENVIRONMENT === ENVIRONMENT_PRODUCTION ? 'https://' : 'http://';
  }

  public get schemes(): ['https://', 'http://'] {
    return ['https://', 'http://'];
  }

  private initializeDeviceType(): void {
    // handle server side
    if (this.platformCommonService.isServer) {
      const isTablet = !!this.request?.useragent?.isTablet;
      const isMobile = !!this.request?.useragent?.isMobile;
      this._isTablet$.next(isTablet);
      this._isMobile$.next(isMobile);
      return;
    }

    // handle client side
    this.setClientDeviceType();

    // init listener, for window resize, and dynamically update device type
    this.ngZoneUtilService.fromEventOut$(this.window, 'resize')
      .pipe(
        this.ngZoneUtilService.debounceTimeOut(100),
        // unsubscribe is not needed, as this observable is created only once and needs to run always until app is destroyed
      )
      .subscribe(() => {
        this.setClientDeviceType();
      });
  }

  private setClientDeviceType(): void {
    // check if browser is tablet size
    const isTablet = this.window.innerWidth < TABLET_MAX_WIDTH;
    this._isTablet$.next(isTablet);

    // check if browser is mobile size
    const isMobile = window.innerWidth < DESKTOP_MIN_WIDTH;
    this._isMobile$.next(isMobile);
  }

}
