import { DOCUMENT } from '@angular/common';
import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { captureException, captureMessage, Scope, withScope } from '@sentry/browser';
import moment from 'moment-mini-ts';
import { debounceTime } from 'rxjs/operators';
import { SentryService } from '@shared/services/logging/sentry.service';
import { PlatformService } from '@shared/platform/service/platform.service';
import { RestHttpClientService } from '@api/rest/rest-http-client.service';
import { LocalStorageService } from '@common/services/storage/local-storage.service';
import { TokenMonitoringService } from '@shared/services/token-monitoring/token-monitoring.service';
import { WINDOW_OBJECT } from '@util/const/window-object';
import isNil from 'lodash-es/isNil';
import { HttpUtil } from '@shared/util/http-status.util';

interface OfflineErrorStack {
  url?: string;
  aukroToken?: string;
}

@Injectable()
export class AukroErrorHandlerService implements ErrorHandler {

  private offlineErrorsStack: OfflineErrorStack[] = [];

  constructor(
    @Inject(WINDOW_OBJECT) private readonly window: Window,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly platformService: PlatformService,
    private readonly localStorageService: LocalStorageService,
    private readonly router: Router,
    private readonly sentryService: SentryService,
  ) {
    if (this.sentryService.sentryEnabled) {
      this.initOfflineLog();
    }
  }

  public handleError(error: any): void {
    console.error(error);

    if (!this.sentryService.sentryEnabled) {
      return;
    }

    if (typeof error === 'object') {
      const message: string = error.message;
      if (message) {
        if (this.isMessageIgnored(message)) {
          // ignore these errors, we don't have any chance to resolve them
          return;
        } else if (message.includes('Error: Loading chunk')) {
          // invalid chunk
          const reloadedAt = this.window.sessionStorage.getItem('reloadedAt');
          if (!reloadedAt || moment(reloadedAt).add('3', 'minutes').toDate() < new Date()) {
            this.window.sessionStorage.setItem('reloadedAt', moment().toDate().toString());
            this.window.location.reload();
            return;
          }
        }
      }

      if (HttpUtil.is4xxClientError(error.code) && !isNil(error.prerequisites)) {
        return;
      }

      if (this.isXhrRequest(error)) {
        if (!this.window.navigator?.onLine || error.code === 0) { // network problem
          return;
        }

        // 503 errors are grouped to standalone error because of cleaner Sentry
        const title: string = error.code === 503 ? 'Service unavailable' : 'Failed XHR - ' + this.cleanUrl(error.url || '');

        withScope((scope: Scope) => {
          scope.setFingerprint([title]);
          scope.setExtra('extra', error);
          captureException(new Error(title));
        });

        return;
      }

      withScope((scope: Scope) => {
        if (HttpUtil.is4xxClientError(error.code)) {
          scope.setLevel('warning');
        }
        captureException(error.originalError || error);
      });
    } else {
      captureException(error);
    }
  }

  private isMessageIgnored(message: string): boolean {
    return message?.includes('QuotaExceededError') // full storage
      || message?.includes('Permission denied to access property') // external scripts
      || message?.includes('ResizeObserver loop limit exceeded') // unimportant error, no impact
      || message?.startsWith('SecurityError') // external scripts or strict browser settings
    ;
  }

  private isXhrRequest(error: any): boolean {
    return Object.keys(error)?.length === 3
      && error?.body
      && error?.url
      && !isNil(error?.code);
  }

  private cleanUrl(url: string): string {
    url = url.replace(RestHttpClientService.API_URL, ''); // remove absolute path part
    url = url.split('?')[0]; // remove query part
    url = url.replace(/([0-9]+)/g, '*'); // replace numbers

    return url;
  }

  private initOfflineLog(): void {
    // check online status
    this.platformService.isUserOnline()
      .pipe(
        debounceTime(5000),
      )
      .subscribe((isOnline: boolean) => {
        // if user comes into offline store data
        if (!isOnline) {
          this.offlineErrorsStack.push({
            url: this.router.url,
            aukroToken: this.localStorageService.getItem(TokenMonitoringService.AUKRO_TOKEN_COOKIE_AND_LS_KEY),
          });
        } else if(isOnline && this.offlineErrorsStack.length > 0) {
          // if user is online and stack is not empty - send data into Sentry
          withScope((scope: Scope) => {
            scope.setExtra('extra', this.offlineErrorsStack);
            captureMessage('User is offline', 'info');
          });
          this.offlineErrorsStack = [];
        }
      });
  }

}
