import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { HttpClient , HttpContext  } from '@angular/common/http';
import { BidResponseDto } from '../model/bid-response-dto';
import { BillingFeePreviewDto } from '../model/billing-fee-preview-dto';
import { BillingRowDto } from '../model/billing-row-dto';
import { BillingSummaryDto } from '../model/billing-summary-dto';
import { CancelBidCollectionDto } from '../model/cancel-bid-collection-dto';
import { CreateExpertReviewRequest } from '../model/create-expert-review-request';
import { DeletedItemInfoDto } from '../model/deleted-item-info-dto';
import { FeePreviewRequestOld } from '../model/fee-preview-request-old';
import { HomepageItemDto } from '../model/homepage-item-dto';
import { ItemBulkReexposeDto } from '../model/item-bulk-reexpose-dto';
import { ItemBulkReexposeFormDto } from '../model/item-bulk-reexpose-form-dto';
import { ItemDetailUsersDataDto } from '../model/item-detail-users-data-dto';
import { ItemEditDto } from '../model/item-edit-dto';
import { ItemFormTypeDto } from '../model/item-form-type-dto';
import { ItemFormWithMoneyDto } from '../model/item-form-with-money-dto';
import { ItemFreePriorityListForItemsDto } from '../model/item-free-priority-list-for-items-dto';
import { ItemIdsCollectionDto } from '../model/item-ids-collection-dto';
import { ItemListHideDto } from '../model/item-list-hide-dto';
import { ItemPropagationDto } from '../model/item-propagation-dto';
import { ItemQuestionFormDto } from '../model/item-question-form-dto';
import { ItemReexposeOffersResultDto } from '../model/item-reexpose-offers-result-dto';
import { ItemReportDto } from '../model/item-report-dto';
import { ItemReportFormDto } from '../model/item-report-form-dto';
import { ItemSearchFilter } from '../model/item-search-filter';
import { ItemSearchFilterBase } from '../model/item-search-filter-base';
import { ItemSearchIteratorResponse } from '../model/item-search-iterator-response';
import { ItemSearchPagedResources } from '../model/item-search-paged-resources';
import { ItemSearchUserRelatedResources } from '../model/item-search-user-related-resources';
import { ItemTerminateDto } from '../model/item-terminate-dto';
import { MoneyDto } from '../model/money-dto';
import { NewBidAmountDto } from '../model/new-bid-amount-dto';
import { OfferDetailDto } from '../model/offer-detail-dto';
import { OfferDetailGaDto } from '../model/offer-detail-ga-dto';
import { OfferDetailShort } from '../model/offer-detail-short';
import { PagedModelHomepageItemDto } from '../model/paged-model-homepage-item-dto';
import { PagedModelItemDealDto } from '../model/paged-model-item-deal-dto';
import { PagedModelItemViewDto } from '../model/paged-model-item-view-dto';
import { RevenueDifferenceRequest } from '../model/revenue-difference-request';
import { ShowPropagationFeesDto } from '../model/show-propagation-fees-dto';
import { BASE_PATH } from '../variables';

export interface ActivateAndExposeOfferNowRequestParams {
    itemId: number;
}

export interface AddExpertReviewRequestParams {
    createExpertReviewRequest: CreateExpertReviewRequest;
    itemId: number;
}

export interface BulkRemoveScheduledItemsRequestParams {
    itemIdsCollectionDto: ItemIdsCollectionDto;
}

export interface BulkShowShortOfferDetailShortRequestParams {
    itemIds: number[];
}

export interface CancelBidRequestParams {
    cancelBidCollectionDto: CancelBidCollectionDto;
    /** Offer ID */
    offerId: number;
}

export interface CheckPrerequisitesForCreateNewOfferRequestParams {
    edit: boolean;
}

export interface CreateNewOfferRequestParams {
    itemFormWithMoneyDto: ItemFormWithMoneyDto;
}

export interface CreateOfferFromExistingOneRequestParams {
    id: number;
}

export interface FeePreviewRequestParams {
    feePreviewRequestOld: FeePreviewRequestOld;
}

export interface FormTypeRequestParams {
    itemId: number;
}

export interface GetBuyerItemQuestionFormRequestParams {
    /** Deal ID */
    dealId: number;
}

export interface GetCarouselOffersRequestParams {
    currency: 'CZK' | 'EUR';
    ids: number[];
    /** Zero-based page index (0..N) */
    page?: number;
    /** The size of the page to be returned */
    size?: number;
}

export interface GetCarouselOffersSellerCategoryRequestParams {
    currency: 'CZK' | 'EUR';
    itemId: number;
}

export interface GetExposeFeesSummaryRequestParams {
    itemPropagationDto: ItemPropagationDto;
}

export interface GetItemReportFormRequestParams {
    /** Offer ID */
    id: number;
}

export interface GetOfferDealsAndTransactionsRequestParams {
    /** List of deal ids we want to retrieve */
    dealId?: number;
    /** Offer ID */
    id: number;
    /** Zero-based page index (0..N) */
    page?: number;
    /** The size of the page to be returned */
    size?: number;
}

export interface GetOfferDetailRequestParams {
    id: number;
    pageType?: 'HOMEPAGE' | 'CATEGORY_PAGE' | 'CATEGORY_LISTING' | 'SEARCH' | 'DETAIL' | 'OUTER' | 'CART' | 'WATCHED_ITEMS' | 'MY_AUKRO' | 'PUBLIC_USER_PROFILE' | 'APP_AUKRO' | 'APP_STRELTO';
    requestedFor?: 'HOMEPAGE' | 'CATEGORY_PAGE' | 'CATEGORY_LISTING' | 'SEARCH' | 'DETAIL' | 'OUTER' | 'CART' | 'WATCHED_ITEMS' | 'MY_AUKRO' | 'PUBLIC_USER_PROFILE' | 'APP_AUKRO' | 'APP_STRELTO';
}

export interface GetOfferDetailGaRequestParams {
    id: number;
}

export interface GetOfferDetailUsersDataWithPageTypeRequestParams {
    id: number;
}

export interface GetReexposeOffersForUpdateRequestParams {
    itemIdsCollectionDto: ItemIdsCollectionDto;
}

export interface GetSellerItemQuestionFormRequestParams {
    /** Offer ID */
    id: number;
}

export interface GetSimilarOffersRequestParams {
    /** Offer ID */
    offerId: number;
    /** Zero-based page index (0..N) */
    page?: number;
    /** The size of the page to be returned */
    size?: number;
}

export interface HideOffersInListRequestParams {
    itemListHideDto: ItemListHideDto;
}

export interface MakeBidByItemIdRequestParams {
    /** Whether trying to bid without a confirm dialog */
    fastBid: boolean;
    /** Item ID to bid on */
    itemId: number;
    newBidAmountDto: NewBidAmountDto;
}

export interface ReexposeOffersRequestParams {
    itemBulkReexposeFormDto: ItemBulkReexposeFormDto;
}

export interface ReportItemRequestParams {
    itemReportDto: ItemReportDto;
}

export interface RevenueDifferenceRequestParams {
    itemId: number;
    revenueDifferenceRequest: RevenueDifferenceRequest;
}

export interface SearchItemsCollectionRequestParams {
    itemSearchFilter: ItemSearchFilter;
    /** Zero-based page index (0..N) */
    page?: number;
    /** The size of the page to be returned */
    size?: number;
}

export interface SearchItemsCommonRequestParams {
    grReco?: string;
    itemSearchFilter: ItemSearchFilter;
    /** Zero-based page index (0..N) */
    page?: number;
    /** The size of the page to be returned */
    size?: number;
    /** Sorting criteria in the format: property:(ASC|DESC) */
    sort?: 'endTime:ASC' | 'endingTime:ASC' | 'startingTime:ASC' | 'scheduledTime:ASC' | 'lastPurchaseTime:ASC' | 'price:ASC' | 'priceWithShipping:ASC' | 'bidCount:ASC' | 'bidderCount:ASC' | 'name:ASC' | 'seller:ASC' | 'highBidder:ASC' | 'relevance:ASC' | 'random:ASC' | 'endTime:DESC' | 'endingTime:DESC' | 'startingTime:DESC' | 'scheduledTime:DESC' | 'lastPurchaseTime:DESC' | 'price:DESC' | 'priceWithShipping:DESC' | 'bidCount:DESC' | 'bidderCount:DESC' | 'name:DESC' | 'seller:DESC' | 'highBidder:DESC' | 'relevance:DESC';
}

export interface SearchItemsIteratorRequestParams {
    grReco?: string;
    itemSearchFilter: ItemSearchFilter;
    /** Zero-based page index (0..N) */
    page?: number;
    /** The size of the page to be returned */
    size?: number;
    /** Sorting criteria in the format: property:(ASC|DESC) */
    sort?: 'endTime:ASC' | 'endingTime:ASC' | 'startingTime:ASC' | 'scheduledTime:ASC' | 'lastPurchaseTime:ASC' | 'price:ASC' | 'priceWithShipping:ASC' | 'bidCount:ASC' | 'bidderCount:ASC' | 'name:ASC' | 'seller:ASC' | 'highBidder:ASC' | 'relevance:ASC' | 'random:ASC' | 'endTime:DESC' | 'endingTime:DESC' | 'startingTime:DESC' | 'scheduledTime:DESC' | 'lastPurchaseTime:DESC' | 'price:DESC' | 'priceWithShipping:DESC' | 'bidCount:DESC' | 'bidderCount:DESC' | 'name:DESC' | 'seller:DESC' | 'highBidder:DESC' | 'relevance:DESC';
}

export interface SearchItemsUsersDataRequestParams {
    /** Watching item ids from unsigned user (from cookie) */
    itemIds?: number[];
    itemSearchFilterBase: ItemSearchFilterBase;
}

export interface SendItemQuestionToSellerRequestParams {
    itemQuestionFormDto: ItemQuestionFormDto;
}

export interface SetEarlyEndRequestParams {
    /** Item ID */
    itemId: number;
}

export interface SetItemFreePriorityListRequestParams {
    itemFreePriorityListForItemsDto: ItemFreePriorityListForItemsDto;
}

export interface ShowPropagationFeeRequestParams {
    showPropagationFeesDto: ShowPropagationFeesDto;
}

export interface ShowShortOfferDetailShortRequestParams {
    id: number;
}

export interface StartWatchingItemRequestParams {
    /** Offer ID */
    id: number;
}

export interface TerminateOfferRequestParams {
    itemTerminateDto: ItemTerminateDto;
    /** Offer ID */
    offerId: number;
}

export interface UpdateOfferRequestParams {
    itemFormWithMoneyDto: ItemFormWithMoneyDto;
}

@Injectable({
  providedIn: 'root',
})
export class OffersApiService {

  private readonly configuration: Configuration = new Configuration();

  constructor(
    private readonly httpClient: HttpClient,
  ) {
  }

  /**
   * Set status active for item but only by his owner
   */
  public activateAndExposeOfferNow$(
    requestParameters: ActivateAndExposeOfferNowRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<string> {
    const itemId: number = requestParameters.itemId;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'string'),
      headers,
    };
    return this.httpClient.request<string>(
      'post',
      `${ BASE_PATH }/offers/${ this.configuration.encodeParam({ name: 'itemId', value: itemId, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/activateAndExpose`,
      options,
    );
  }

  /**
   * Add expert review to item
   */
  public addExpertReview$(
    requestParameters: AddExpertReviewRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const itemId: number = requestParameters.itemId;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.createExpertReviewRequest,
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ BASE_PATH }/offers/${ this.configuration.encodeParam({ name: 'itemId', value: itemId, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/expert-review`,
      options,
    );
  }

  /**
   * [UCU105.7] Remove scheduled offers, return a list with informatoin about deleted items
   */
  public bulkRemoveScheduledItems$(
    requestParameters: BulkRemoveScheduledItemsRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<DeletedItemInfoDto[]> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.itemIdsCollectionDto,
      responseType: this.configuration.getResponseType(false, 'Array<DeletedItemInfoDto>'),
      headers,
    };
    return this.httpClient.request<DeletedItemInfoDto[]>(
      'post',
      `${ BASE_PATH }/offers/removeScheduled`,
      options,
    );
  }

  /**
   * Show reduced item details. For continuous pricing state check.
   */
  public bulkShowShortOfferDetailShort$(
    requestParameters: BulkShowShortOfferDetailShortRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<OfferDetailShort[]> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      itemIds: requestParameters.itemIds,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'Array<OfferDetailShort>'),
      headers,
    };
    return this.httpClient.request<OfferDetailShort[]>(
      'get',
      `${ BASE_PATH }/offers/bulk/offerDetail/short`,
      options,
    );
  }

  /**
   * [UCU101.4] Cancel bid (and add user to blacklist)
   */
  public cancelBid$(
    requestParameters: CancelBidRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<boolean> {
    const offerId: number = requestParameters.offerId;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.cancelBidCollectionDto,
      responseType: this.configuration.getResponseType(false, 'boolean'),
      headers,
    };
    return this.httpClient.request<boolean>(
      'post',
      `${ BASE_PATH }/offers/${ this.configuration.encodeParam({ name: 'offerId', value: offerId, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/cancelBid/`,
      options,
    );
  }

  /**
   * Check prerequisites for create offer
   */
  public checkPrerequisitesForCreateNewOffer$(
    requestParameters: CheckPrerequisitesForCreateNewOfferRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      edit: requestParameters.edit,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ BASE_PATH }/offers/check-prerequisites`,
      options,
    );
  }

  /**
   * Create offer
   */
  public createNewOffer$(
    requestParameters: CreateNewOfferRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<ItemFormWithMoneyDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.itemFormWithMoneyDto,
      responseType: this.configuration.getResponseType(false, 'ItemFormWithMoneyDto'),
      headers,
    };
    return this.httpClient.request<ItemFormWithMoneyDto>(
      'post',
      `${ BASE_PATH }/offers`,
      options,
    );
  }

  /**
   * [UCU100.15] Prepare new offer form from existing offer ID.
   */
  public createOfferFromExistingOne$(
    requestParameters: CreateOfferFromExistingOneRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<ItemEditDto> {
    const id: number = requestParameters.id;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'ItemEditDto'),
      headers,
    };
    return this.httpClient.request<ItemEditDto>(
      'post',
      `${ BASE_PATH }/offers/${ this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/createOfferFromExisting`,
      options,
    );
  }

  /**
   * Enables the postponing end of all the active auctions of logged user
   */
  public enablePostponingEndForAllOffers$(
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'put',
      `${ BASE_PATH }/offers/enable-postponing-end`,
      options,
    );
  }

  /**
   * Get fee preview
   * @deprecated
   */
  public feePreview$(
    requestParameters: FeePreviewRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<BillingFeePreviewDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.feePreviewRequestOld,
      responseType: this.configuration.getResponseType(false, 'BillingFeePreviewDto'),
      headers,
    };
    return this.httpClient.request<BillingFeePreviewDto>(
      'post',
      `${ BASE_PATH }/offers/fee-preview`,
      options,
    );
  }

  /**
   * Get item form type info
   */
  public formType$(
    requestParameters: FormTypeRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<ItemFormTypeDto> {
    const itemId: number = requestParameters.itemId;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'ItemFormTypeDto'),
      headers,
    };
    return this.httpClient.request<ItemFormTypeDto>(
      'get',
      `${ BASE_PATH }/offers/${ this.configuration.encodeParam({ name: 'itemId', value: itemId, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/formType`,
      options,
    );
  }

  /**
   * Return buyer item question form
   */
  public getBuyerItemQuestionForm$(
    requestParameters: GetBuyerItemQuestionFormRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<ItemQuestionFormDto> {
    const dealId: number = requestParameters.dealId;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'ItemQuestionFormDto'),
      headers,
    };
    return this.httpClient.request<ItemQuestionFormDto>(
      'get',
      `${ BASE_PATH }/offers/${ this.configuration.encodeParam({ name: 'dealId', value: dealId, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/buyerItemQuestionForm`,
      options,
    );
  }

  /**
   * Get carousel offers for list of offer IDs
   */
  public getCarouselOffers$(
    requestParameters: GetCarouselOffersRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<PagedModelHomepageItemDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      ids: requestParameters.ids,
      page: requestParameters.page,
      size: requestParameters.size,
      currency: requestParameters.currency,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'PagedModelHomepageItemDto'),
      headers,
    };
    return this.httpClient.request<PagedModelHomepageItemDto>(
      'get',
      `${ BASE_PATH }/offers/carouselOffers`,
      options,
    );
  }

  /**
   * Get items for seller-category carousel
   */
  public getCarouselOffersSellerCategory$(
    requestParameters: GetCarouselOffersSellerCategoryRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<HomepageItemDto[]> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      itemId: requestParameters.itemId,
      currency: requestParameters.currency,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'Array<HomepageItemDto>'),
      headers,
    };
    return this.httpClient.request<HomepageItemDto[]>(
      'get',
      `${ BASE_PATH }/offers/carousel-offers-seller-category`,
      options,
    );
  }

  /**
   * [UCU0100.13] Get exposure fees summary
   */
  public getExposeFeesSummary$(
    requestParameters: GetExposeFeesSummaryRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<BillingSummaryDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.itemPropagationDto,
      responseType: this.configuration.getResponseType(false, 'BillingSummaryDto'),
      headers,
    };
    return this.httpClient.request<BillingSummaryDto>(
      'post',
      `${ BASE_PATH }/offers/exposeFeeSummary`,
      options,
    );
  }

  /**
   * User data for report form
   */
  public getItemReportForm$(
    requestParameters: GetItemReportFormRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<ItemReportFormDto> {
    const id: number = requestParameters.id;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'ItemReportFormDto'),
      headers,
    };
    return this.httpClient.request<ItemReportFormDto>(
      'get',
      `${ BASE_PATH }/offers/${ this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/reportForm`,
      options,
    );
  }

  /**
   * [UCU125.1] Get offer transactions
   */
  public getOfferDealsAndTransactions$(
    requestParameters: GetOfferDealsAndTransactionsRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<PagedModelItemDealDto> {
    const id: number = requestParameters.id;
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      page: requestParameters.page,
      size: requestParameters.size,
      dealId: requestParameters.dealId,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'PagedModelItemDealDto'),
      headers,
    };
    return this.httpClient.request<PagedModelItemDealDto>(
      'get',
      `${ BASE_PATH }/offers/${ this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/transaction`,
      options,
    );
  }

  /**
   * Gets item detail without user data. Item show count will increase if requested for DETAIL page.
   */
  public getOfferDetail$(
    requestParameters: GetOfferDetailRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<OfferDetailDto> {
    const id: number = requestParameters.id;
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      pageType: requestParameters.pageType,
      requestedFor: requestParameters.requestedFor,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'OfferDetailDto'),
      headers,
    };
    return this.httpClient.request<OfferDetailDto>(
      'get',
      `${ BASE_PATH }/offers/${ this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/offerDetail`,
      options,
    );
  }

  /**
   * Gets item detail without user data for Google Analytics purposes. Item show count will not increase.
   */
  public getOfferDetailGa$(
    requestParameters: GetOfferDetailGaRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<OfferDetailGaDto> {
    const id: number = requestParameters.id;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'OfferDetailGaDto'),
      headers,
    };
    return this.httpClient.request<OfferDetailGaDto>(
      'get',
      `${ BASE_PATH }/offers/${ this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/offerDetailGa`,
      options,
    );
  }

  /**
   * Get user data in item detail. Show count won\&#39;t increase
   */
  public getOfferDetailUsersDataWithPageType$(
    requestParameters: GetOfferDetailUsersDataWithPageTypeRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<ItemDetailUsersDataDto> {
    const id: number = requestParameters.id;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'ItemDetailUsersDataDto'),
      headers,
    };
    return this.httpClient.request<ItemDetailUsersDataDto>(
      'get',
      `${ BASE_PATH }/offers/${ this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/detailUserData`,
      options,
    );
  }

  /**
   * [UCU100.16] Reexpose offers - prepare form method
   */
  public getReexposeOffersForUpdate$(
    requestParameters: GetReexposeOffersForUpdateRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<ItemBulkReexposeDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.itemIdsCollectionDto,
      responseType: this.configuration.getResponseType(false, 'ItemBulkReexposeDto'),
      headers,
    };
    return this.httpClient.request<ItemBulkReexposeDto>(
      'post',
      `${ BASE_PATH }/offers/reexposure/prepare`,
      options,
    );
  }

  /**
   * [UCU330.1] Show form to fill question to seller
   */
  public getSellerItemQuestionForm$(
    requestParameters: GetSellerItemQuestionFormRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<ItemQuestionFormDto> {
    const id: number = requestParameters.id;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'ItemQuestionFormDto'),
      headers,
    };
    return this.httpClient.request<ItemQuestionFormDto>(
      'get',
      `${ BASE_PATH }/offers/${ this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/sellerItemQuestionForm`,
      options,
    );
  }

  /**
   * [UCU021.3] Get similar offers
   */
  public getSimilarOffers$(
    requestParameters: GetSimilarOffersRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<PagedModelItemViewDto> {
    const offerId: number = requestParameters.offerId;
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      page: requestParameters.page,
      size: requestParameters.size,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'PagedModelItemViewDto'),
      headers,
    };
    return this.httpClient.request<PagedModelItemViewDto>(
      'get',
      `${ BASE_PATH }/offers/${ this.configuration.encodeParam({ name: 'offerId', value: offerId, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/similar`,
      options,
    );
  }

  /**
   * [UCU105.6] Hide offers in list
   */
  public hideOffersInList$(
    requestParameters: HideOffersInListRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.itemListHideDto,
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ BASE_PATH }/offers/hide`,
      options,
    );
  }

  /**
   * Make bid
   */
  public makeBidByItemId$(
    requestParameters: MakeBidByItemIdRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<BidResponseDto> {
    const itemId: number = requestParameters.itemId;
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      fastBid: requestParameters.fastBid,
    };
    const options: object = {
      context: httpContext,
      body: requestParameters.newBidAmountDto,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'BidResponseDto'),
      headers,
    };
    return this.httpClient.request<BidResponseDto>(
      'post',
      `${ BASE_PATH }/offers/${ this.configuration.encodeParam({ name: 'itemId', value: itemId, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/bid`,
      options,
    );
  }

  /**
   * [UCU100.16] Reexpose offers
   */
  public reexposeOffers$(
    requestParameters: ReexposeOffersRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<ItemReexposeOffersResultDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.itemBulkReexposeFormDto,
      responseType: this.configuration.getResponseType(false, 'ItemReexposeOffersResultDto'),
      headers,
    };
    return this.httpClient.request<ItemReexposeOffersResultDto>(
      'post',
      `${ BASE_PATH }/offers/reexposure`,
      options,
    );
  }

  /**
   * User report item
   */
  public reportItem$(
    requestParameters: ReportItemRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.itemReportDto,
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ BASE_PATH }/offers/report`,
      options,
    );
  }

  /**
   * Get item revenue if sold for given price
   */
  public revenueDifference$(
    requestParameters: RevenueDifferenceRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<MoneyDto> {
    const itemId: number = requestParameters.itemId;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.revenueDifferenceRequest,
      responseType: this.configuration.getResponseType(false, 'MoneyDto'),
      headers,
    };
    return this.httpClient.request<MoneyDto>(
      'post',
      `${ BASE_PATH }/offers/${ this.configuration.encodeParam({ name: 'itemId', value: itemId, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/revenue-difference`,
      options,
    );
  }

  /**
   * Find items in items collection
   */
  public searchItemsCollection$(
    requestParameters: SearchItemsCollectionRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<ItemSearchPagedResources> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      page: requestParameters.page,
      size: requestParameters.size,
    };
    const options: object = {
      context: httpContext,
      body: requestParameters.itemSearchFilter,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'ItemSearchPagedResources'),
      headers,
    };
    return this.httpClient.request<ItemSearchPagedResources>(
      'post',
      `${ BASE_PATH }/offers/searchItemsCommon/items-collection`,
      options,
    );
  }

  /**
   * Find items by search criteria
   */
  public searchItemsCommon$(
    requestParameters: SearchItemsCommonRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<ItemSearchPagedResources> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      page: requestParameters.page,
      size: requestParameters.size,
      sort: requestParameters.sort,
    };
    const options: object = {
      context: httpContext,
      body: requestParameters.itemSearchFilter,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'ItemSearchPagedResources'),
      headers,
    };
    return this.httpClient.request<ItemSearchPagedResources>(
      'post',
      `${ BASE_PATH }/offers/searchItemsCommon`,
      options,
    );
  }

  /**
   * Find data of items by search criteria for purpose of item iterator
   */
  public searchItemsIterator$(
    requestParameters: SearchItemsIteratorRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<ItemSearchIteratorResponse> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      page: requestParameters.page,
      size: requestParameters.size,
      sort: requestParameters.sort,
    };
    const options: object = {
      context: httpContext,
      body: requestParameters.itemSearchFilter,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'ItemSearchIteratorResponse'),
      headers,
    };
    return this.httpClient.request<ItemSearchIteratorResponse>(
      'post',
      `${ BASE_PATH }/offers/search-items-iterator`,
      options,
    );
  }

  /**
   * Find users favorite items
   */
  public searchItemsUsersData$(
    requestParameters: SearchItemsUsersDataRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<ItemSearchUserRelatedResources> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      itemIds: requestParameters.itemIds,
    };
    const options: object = {
      context: httpContext,
      body: requestParameters.itemSearchFilterBase,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'ItemSearchUserRelatedResources'),
      headers,
    };
    return this.httpClient.request<ItemSearchUserRelatedResources>(
      'post',
      `${ BASE_PATH }/offers/searchUserRelatedItems`,
      options,
    );
  }

  /**
   * [UCU330.1] Send question to seller
   */
  public sendItemQuestionToSeller$(
    requestParameters: SendItemQuestionToSellerRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.itemQuestionFormDto,
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ BASE_PATH }/offers/sendItemQuestion`,
      options,
    );
  }

  /**
   * Sets the end to the next full minute.
   */
  public setEarlyEnd$(
    requestParameters: SetEarlyEndRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const itemId: number = requestParameters.itemId;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ BASE_PATH }/offers/${ this.configuration.encodeParam({ name: 'itemId', value: itemId, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/setEarlyEnd`,
      options,
    );
  }

  /**
   * Add or remove item from aukro free priority list
   */
  public setItemFreePriorityList$(
    requestParameters: SetItemFreePriorityListRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<number[]> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.itemFreePriorityListForItemsDto,
      responseType: this.configuration.getResponseType(false, 'Array<number>'),
      headers,
    };
    return this.httpClient.request<number[]>(
      'post',
      `${ BASE_PATH }/offers/setItemFreePriorityList`,
      options,
    );
  }

  /**
   * Get fee amount for various propagations
   */
  public showPropagationFee$(
    requestParameters: ShowPropagationFeeRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<BillingRowDto[]> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.showPropagationFeesDto,
      responseType: this.configuration.getResponseType(false, 'Array<BillingRowDto>'),
      headers,
    };
    return this.httpClient.request<BillingRowDto[]>(
      'post',
      `${ BASE_PATH }/offers/showPropagationFee`,
      options,
    );
  }

  /**
   * Show reduced item detail. For continuous pricing state check.
   */
  public showShortOfferDetailShort$(
    requestParameters: ShowShortOfferDetailShortRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<OfferDetailShort> {
    const id: number = requestParameters.id;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'OfferDetailShort'),
      headers,
    };
    return this.httpClient.request<OfferDetailShort>(
      'get',
      `${ BASE_PATH }/offers/${ this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/offerDetail/short`,
      options,
    );
  }

  /**
   * Start watching item
   */
  public startWatchingItem$(
    requestParameters: StartWatchingItemRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const id: number = requestParameters.id;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ BASE_PATH }/offers/${ this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/watch`,
      options,
    );
  }

  /**
   * [UCU101.3] Terminate item before planned endtime
   */
  public terminateOffer$(
    requestParameters: TerminateOfferRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<boolean> {
    const offerId: number = requestParameters.offerId;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.itemTerminateDto,
      responseType: this.configuration.getResponseType(false, 'boolean'),
      headers,
    };
    return this.httpClient.request<boolean>(
      'post',
      `${ BASE_PATH }/offers/${ this.configuration.encodeParam({ name: 'offerId', value: offerId, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/terminate`,
      options,
    );
  }

  /**
   * [UCU101.2] Update offer
   */
  public updateOffer$(
    requestParameters: UpdateOfferRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<ItemFormWithMoneyDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.itemFormWithMoneyDto,
      responseType: this.configuration.getResponseType(false, 'ItemFormWithMoneyDto'),
      headers,
    };
    return this.httpClient.request<ItemFormWithMoneyDto>(
      'put',
      `${ BASE_PATH }/offers`,
      options,
    );
  }

}
