import { Injectable, NgZone } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { asyncScheduler, bufferTime, fromEvent, interval, merge, MonoTypeOperatorFunction, noop, Observable, scheduled, SchedulerLike, ThrottleConfig, timer } from 'rxjs';
import { debounceTime, map, observeOn, takeUntil, throttleTime } from 'rxjs/operators';
import { EnterZoneScheduler } from '@util/zone/domain/enter-zone-scheduler';
import { LeaveZoneScheduler } from '@util/zone/domain/leave-zone-scheduler';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { noopOperator } from '@util/rxjs-operators/noop-operator';
// eslint-disable-next-line import/no-restricted-paths
import { PlatformCommonService } from '@common/platform/service/platform-common.service';

/**
 * Whenever you are working with NgZone, you should always use this proxy service
 * so it will be easier to maintain NgZone usability
 */
@Injectable({
  providedIn: 'root',
})
export class NgZoneUtilService extends NgUnsubscribe {

  constructor(
    private readonly ngZone: NgZone,
  ) {
    super();
  }

  /**
   * It's just a proxy to {@link ngZone.runOutsideAngular}
   * - name is shorter
   * - it will be easier to maintain NgZone usage
   * - In future there will be way to opt out of Ng zone, so with this proxy it will easier
   * @param fn - function which should be run outside ng zone
   */
  public runOut<T>(fn: (...args: unknown[]) => T): T {
    return this.ngZone.runOutsideAngular(fn);
  }

  /**
   * It's just a proxy to {@link ngZone.run }
   * - it will be easier to maintain NgZone usage
   * - In future there will be way to opt out of Ng zone, so with this proxy it will easier
   * @param fn - function which should be run inside ng zone
   */
  public runIn<T>(fn: (...args: unknown[]) => T): T {
    return this.ngZone.run(fn);
  }

  /**
   * - Creates rxjs timer and runs it outside ng zone but all the following piped operators in this observable
   * (including subscribe) will run inside ng zone
   * @param due - 1. param for rxjs timer fn
   * @param intervalDuration - 2. param for rxjs timer Fn
   * @param {boolean} [observeOnNgZone=true] - Whether following operator/subscribe functions should be called inside ngZone
   *  (if true, angular will also call markForCheck()) automatically
   */
  public timerOut$(due: number = 0, intervalDuration?: number, observeOnNgZone: boolean = true): Observable<void> {
    return timer(due, intervalDuration, this.leaveNgZone())
      .pipe(
        observeOnNgZone ? this.observeOnNgZone() : noopOperator(),
        map(noop),
      );
  }

  /**
   * Calls {@link timerOut$} but automatically subscribes to it and calls {@link handleFn}
   * It also automatically unsubscribes when given {@link destroySub$} emits
   * Should be used instead of native {@link setTimeout}
   * @param handleFn - handler function, which will be called when timer finishes
   * @param destroySub$ - Observable, which will unsubscribe the subscription created in this function
   * @param due - 1. param for rxjs timer fn
   */
  public simpleTimerOut$(
    handleFn: () => void,
    destroySub$: Observable<void>,
    due: number = 0,
  ): void {
    this.timerOut$(due)
      .pipe(
        takeUntil(
          merge(destroySub$, this.ngUnsubscribe),
        ),
      )
      .subscribe(() => handleFn());
  }

  /**
   * - Creates rxjs interval and runs it outside ng zone but all the following piped operators in this observable
   * (including subscribe) will run inside ng zone
   * @param due - 1. param for rxjs interval fn
   * @param {boolean} [observeOnNgZone=true] - Whether following operator/subscribe functions should be called inside ngZone
   *  (if true, angular will also call markForCheck()) automatically
   */
  public intervalOut$(due: number, observeOnNgZone: boolean = true): Observable<void> {
    return interval(due, this.leaveNgZone())
      .pipe(
        observeOnNgZone ? this.observeOnNgZone() : noopOperator(),
        map(noop),
      );
  }

  /**
   * @returns rxjs throttleTime operator, but it runs outside ng zone but all the following piped operators after this operator
   * (including subscribe) will run inside ng zone
   * @param due - 1. param for rxjs throttleTime fn
   * @param throttleConfig - 2. param for rxjs throttleTime fn
   */
  public throttleTimeOut<T>(due: number, throttleConfig?: ThrottleConfig): (input: Observable<T>) => Observable<T> {
    return (source$: Observable<T>) => source$
      .pipe(
        throttleTime(due, this.leaveNgZone(), throttleConfig),
        this.observeOnNgZone(),
      );
  }

  /**
   * @returns rxjs debounceTime operator, but it runs outside ng zone but all the following piped operators after this operator
   * (including subscribe) will run inside ng zone
   * @param dueTime - 1. param for rxjs debounceTime fn
   */
  public debounceTimeOut<T>(dueTime: number): (input: Observable<T>) => Observable<T> {
    return (source$: Observable<T>) => source$
      .pipe(
        debounceTime(dueTime, this.leaveNgZone()),
        this.observeOnNgZone(),
      );
  }

  /**
   * @returns rxjs bufferTime operator, but it runs outside ng zone but all the following piped operators after this operator
   * (including subscribe) will run inside ng zone
   * @param dueTime - 1. param for rxjs bufferTime fn
   */
  public bufferTimeOut<T>(dueTime: number): (input: Observable<T>) => Observable<T[]> {
    return (source$: Observable<T>) => source$
      .pipe(
        bufferTime(dueTime, this.leaveNgZone()),
        this.observeOnNgZone(),
      );
  }

  /**
   * - Creates rxjs timer and runs it outside ng zone but all the following piped operators in this observable
   * (including subscribe) will run inside ng zone
   * @param targetElement 1. param for rxjs fromEvent fn
   * @param eventName 2. param for rxjs fromEvent fn
   * @param options 3. param for rxjs fromEvent fn
   */
  public fromEventOut$<
    EVENT_KEY extends keyof HTMLElementEventMap | string,
    EVENT = EVENT_KEY extends keyof HTMLElementEventMap ? HTMLElementEventMap[EVENT_KEY] : unknown>(
    targetElement: HTMLElement | Window,
    eventName: EVENT_KEY,
    options?: EventListenerOptions,
  ): Observable<EVENT> {
    return (
      scheduled(
        fromEvent(targetElement, eventName, options),
        this.leaveNgZone(),
      )
    ) as Observable<EVENT>;
  }

  /**
   * Will run all following operations inside ng zone
   */
  public observeOnNgZone<T>(
    scheduler?: SchedulerLike,
  ): MonoTypeOperatorFunction<T> {
    if (PlatformCommonService.isNativeIosApp) {
      return noopOperator();
    }
    return observeOn<T>(this.enterNgZone(scheduler));
  }

  /**
   * Will run all following operations outside ng zone
   */
  public observeOutOfNgZone<T>(
    scheduler?: SchedulerLike,
  ): MonoTypeOperatorFunction<T> {
    return observeOn<T>(this.leaveNgZone(scheduler));
  }

  /**
   * Creates ng zone scheduler (for rxjs operators/observables) which will run function inside ng zone
   */
  private enterNgZone(scheduler: SchedulerLike = asyncScheduler): SchedulerLike {
    return new EnterZoneScheduler(this.ngZone, scheduler);
  }

  /**
   * Creates ng zone scheduler (for rxjs operators/observables) which will run function outside ng zone
   */
  private leaveNgZone(scheduler: SchedulerLike = asyncScheduler): SchedulerLike {
    if (PlatformCommonService.isNativeIosApp) {
      return new EnterZoneScheduler(this.ngZone, scheduler);
    }
    return new LeaveZoneScheduler(this.ngZone, scheduler);
  }

}
