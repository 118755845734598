<div
  class="auk-icon transition-base"
  [ngClass]="iconSizeClass">
  @switch (iconType) {
    <!-- MATERIAL ICON -->
    @case ('MATERIAL') {
      <mat-icon>
        {{ source }}
      </mat-icon>
    }
    <!-- ./MATERIAL ICON -->
    <!-- SVG ICON -->
    @case ('SVG') {
      <auk-svg-icon [iconId]="source"></auk-svg-icon>
    }
    <!-- ./SVG ICON -->
    <!-- FONT_AWESOME ICON -->
    @case ('FONT_AWESOME') {
      <i class="fa fa-fw !tw-w-full !tw-h-full" [ngClass]="source"></i>
    }
    <!-- ./FONT_AWESOME ICON -->
  }
</div>
