import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { RestHttpClientService } from '@api/rest/rest-http-client.service';
import * as model from '../model';

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface GetConfigElementUsingGETParams {
  /** configElementTypeEnum */
  configElementTypeEnum: ConfigElementTypeEnumGetConfigElementUsingGETParams;
  /** keys */
  keys?: string[];
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export type ConfigElementTypeEnumGetConfigElementUsingGETParams =
  'BEHAVIOR_DIRECTIVE' |
  'BUTTON' |
  'CONTACT_FORM' |
  'DYNAMIC_SEGMENT' |
  'EMAIL_HTML_BLOCK' |
  'EMAIL_TEMPLATE' |
  'ENUM' |
  'FEED' |
  'FE_SPEC_OPS' |
  'INVOICE' |
  'MENUS_LAYOUTS' |
  'SEARCH_REDIRECT_MAP' |
  'INDEXED_SEARCH_MAP' |
  'STANDALONE_PAGE' |
  'SYSTEM_PARAMETER' |
  'SYSTEM_PARAMETER_FE' |
  'TICKET_MESSAGE_TEMPLATE' |
  'TICKET_PARAMETERS' |
  'TICKET_TYPE' |
  'TITLE_PAGE' |
  'WEB_HTML_BLOCK' |
  'LISTING_MOD';

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface AllParams {
  /** configElementTypeEnum */
  configElementTypeEnum: ConfigElementTypeEnumAllParams;
  /** previousHash */
  previousHash?: string;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export type ConfigElementTypeEnumAllParams =
  'BEHAVIOR_DIRECTIVE' |
  'BUTTON' |
  'CONTACT_FORM' |
  'DYNAMIC_SEGMENT' |
  'EMAIL_HTML_BLOCK' |
  'EMAIL_TEMPLATE' |
  'ENUM' |
  'FEED' |
  'FE_SPEC_OPS' |
  'INVOICE' |
  'MENUS_LAYOUTS' |
  'SEARCH_REDIRECT_MAP' |
  'INDEXED_SEARCH_MAP' |
  'STANDALONE_PAGE' |
  'SYSTEM_PARAMETER' |
  'SYSTEM_PARAMETER_FE' |
  'TICKET_MESSAGE_TEMPLATE' |
  'TICKET_PARAMETERS' |
  'TICKET_TYPE' |
  'TITLE_PAGE' |
  'WEB_HTML_BLOCK' |
  'LISTING_MOD';

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface CountParams {
  /** key */
  key: string;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface StandAlonePageParams {
  /** siteUrlPart */
  siteUrlPart: string;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
@Injectable({
  providedIn: 'root'
})
export class ConfiguratorService extends RestHttpClientService {
  constructor(injector: Injector) { super(injector); }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public getConfigElementUsingGET(params: GetConfigElementUsingGETParams): Observable<model.ConfigElementValueDto[]> {
    let queryParams = {
      configElementTypeEnum: params.configElementTypeEnum,
      keys: params.keys,
    };
    return this.getGenerated(`/configurator/configElement`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public all(params: AllParams): Observable<model.ConfigElementValuesDto> {
    let queryParams = {
      configElementTypeEnum: params.configElementTypeEnum,
      previousHash: params.previousHash,
    };
    return this.getGenerated(`/configurator/configElement/all`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public count(params: CountParams): Observable<number> {
    let pathParams = {
      key: params.key,
    };
    let queryParams;
    return this.getGenerated(`/configurator/configElement/dynamicSegment/count/${ pathParams.key }`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public frontend(): Observable<model.ConfigElementFrontendPackResponse> {
    return this.getGenerated(`/configurator/configElement/frontend`);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public standAlonePage(params: StandAlonePageParams): Observable<model.StandAlonePageDto> {
    let queryParams = {
      siteUrlPart: params.siteUrlPart,
    };
    return this.getGenerated(`/configurator/configElement/standAlonePage`, queryParams);
  }
}
