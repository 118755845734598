import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BannerPlacementDto } from '@api/generated/defs/BannerPlacementDto';
import { VerticalMenuGridMenuModel } from '@shared/app-header/model/vertical-menu-grid-menu.model';
import { distinctUntilChanged } from 'rxjs/operators';
import { DynamicTemplateModel } from '@shared/app-header/model/dynamic-template.model';

export type ModalPanelState = 'navigation';

@Injectable({ providedIn: 'root' })
export class AppHeaderService {

  private setTopLineBanner$ = new BehaviorSubject<BannerPlacementDto>(null);
  private openedModalPanel = new BehaviorSubject<ModalPanelState>(null); // only mobile
  private selectedVerticalMenuGridMenu = new BehaviorSubject<VerticalMenuGridMenuModel>(null);
  private headerHeight$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  private _dynamicComponentTemplate$: BehaviorSubject<DynamicTemplateModel> =
    new BehaviorSubject<DynamicTemplateModel>({ contentTemplate: null });

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
  }

  public static getBadgeCount(count: number): string {
    return count ? (count > 99 ? '99+' : count.toString()) : null;
  }

  public setTopLineBanner(value: BannerPlacementDto): void {
    this.setTopLineBanner$.next(value);
  }

  public get topLineBanner$(): Observable<BannerPlacementDto> {
    return this.setTopLineBanner$.asObservable();
  }

  public getModalPanelState(): ModalPanelState {
    return this.openedModalPanel.value;
  }

  public getModalPanelStateChange(): Observable<ModalPanelState> {
    return this.openedModalPanel.asObservable();
  }

  public setModalPanel(state: ModalPanelState): void {
    if (state) {
      this.document.body.classList.add('is-modalPanel-toggled');
    } else {
      this.document.body.classList.remove('is-modalPanel-toggled');
    }
    this.openedModalPanel.next(state);
  }

  public getSelectedVerticalMenuGridMenu(): Observable<VerticalMenuGridMenuModel> {
    return this.selectedVerticalMenuGridMenu.asObservable();
  }

  public setSelectedVerticalMenuGridMenu(value: VerticalMenuGridMenuModel): void {
    this.selectedVerticalMenuGridMenu.next(value);
  }

  // TODO(PDEV-23190) headerHeight$ is no longer set, remove logic dependent on it
  public getHeaderHeight$(): Observable<number> {
    return this.headerHeight$.asObservable()
      .pipe(
        distinctUntilChanged(),
      );
  }

  public get dynamicTemplate$(): Observable<DynamicTemplateModel> {
    return this._dynamicComponentTemplate$.asObservable();
  }

  public setDynamicTemplate(value: DynamicTemplateModel): void {
    this._dynamicComponentTemplate$.next(value);
  }

}
