import { Injectable } from '@angular/core';
import { AppInitializer } from '@util/helper-types/initializer.interface';
import { DomainService } from '@shared/platform/domain.service';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { environment } from '@environment';
import { DynamicScriptLoaderService } from '@shared/services/script/dynamic-script-loader.service';

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerInitializer implements AppInitializer {

  constructor(
    private readonly domainService: DomainService,
    private readonly dynamicScriptLoaderService: DynamicScriptLoaderService,
  ) {
  }

  public init(): void {
    // In native apps, GTM script is not inserted in index.tpl because we do not know the current language there.
    // That is why we need to load the script dynamically here.
    if (!PlatformCommonService.isNativeApp) {
      return;
    }

    const gtmId: string = this.domainService.isSkLocale() ? environment.GTM_ID_SK : environment.GTM_ID_CZ;
    const auth: string = this.domainService.isSkLocale() ? environment.GTM_AUTH_SK : environment.GTM_AUTH_CZ;
    const preview: string = this.domainService.isSkLocale() ? environment.GTM_PREVIEW_SK : environment.GTM_PREVIEW_CZ;
    const cookies: string = environment.GTM_COOKIES_WIN;

    const script: string = `(function(w,d,s,l,i){
      w[l]=w[l]||[];
      w[l].push({'gtm.start': window.appInitTime,event:'gtm.js'});
      var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
      j.async=true;
      j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+'&gtm_auth=${ auth }&gtm_preview=${ preview }&gtm_cookies_win=${ cookies }';
      f.parentNode.insertBefore(j,f);})
      (window,document,'script','dataLayer','${ gtmId }');`;

    this.dynamicScriptLoaderService.insertJsScript(script);
  }

}
