<div class="auk-basic-item-card-image-wrapper"
     [class.freeForm]="isFreeform"
     [class.loose]="type === 'loose'"
     [class.compact]="size === 'COMPACT'"
>
  <img class="auk-basic-item-card-image"
       [ngClass]="{
        'blurred': itemCardImage.isBlurred,
       }"
       [alt]="itemCardImage.name"
       [aukReplaceByPlaceholderOnError]="itemCardImage.itemImageUrl"
       [loading]="lazyLoadImage ? 'lazy' : 'eager'"
       placeholderSize="265x198">
</div>
