import { Inject, Injectable } from '@angular/core';
import { captureException, Scope, withScope } from '@sentry/browser';
import { StringUtils } from '@util/util/string.utils';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ImageLoadingService {

  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) {
  }

  public handleImageLoadingError(src: string): void {
    withScope((scope: Scope) => {
      scope.setExtra('extra', { src });
      captureException('Failed to load an image');
    });
  }

  public checkIfImageExists(imgUrl: string, callback: (exists: boolean) => void): void {
    if (StringUtils.isBlank(imgUrl)) {
      return;
    }

    const img: HTMLImageElement = this.document.createElement('img');
    img.src = imgUrl;

    if (img.complete) {
      callback(true);
    } else {
      img.onload = (): void => {
        callback(true);
      };
      img.onerror = (): void => {
        callback(false);
      };
    }
  }

}
