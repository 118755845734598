import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, OnInit } from '@angular/core';
import { captureException, withScope } from '@sentry/browser';
import { take, takeUntil } from 'rxjs/operators';
import { ConfigElementValueDto } from '@api/generated/defs/ConfigElementValueDto';
import { ConfiguratorCacheService } from '@shared/services/configurator-cache/configurator-cache.service';
import { PlatformService } from '@shared/platform/service/platform.service';
import { SeoService } from '@shared/services/seo/seo.service';
import { LocalStorageService } from '@common/services/storage/local-storage.service';
import { BrowserService } from '@shared/platform/browser.service';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { CookieConsentService } from '@shared/cookie-consent/service/cookie-consent.service';
import { GridMenuItemGroupModel } from '@common/ui-kit/component/grid-menu/component/model/grid-menu.model';
import { GridMenuItemGroupRawModel } from './model/grid-menu-item-group-raw.model';
import { stringToTranslationSource } from '@util/helper-functions/string-to-translation-source';
import { getTrackByTranslationSourceFn } from '@util/helper-functions/get-track-by-translation-source-fn';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { NativeAppConstants } from '@shared/native-app/constants/native-app.constants';
import { App, AppInfo } from '@capacitor/app';
import { CapacitorUpdater, BundleInfo } from '@capgo/capacitor-updater';
import { Device, DeviceInfo } from '@capacitor/device';
import { AppInfoModel } from './model/app-info.model';
import { environment } from '@environment';
import { NativeAppService } from '@shared/native-app/service/native-app.service';

@Component({
  selector: 'auk-app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppFooterComponent extends NgUnsubscribe implements OnInit {

  @HostBinding('class.container') public hasContainerClass: boolean = true;

  public readonly currentYear: number = (new Date()).getFullYear();
  public browserUpdateLink: string;
  public isMobile: boolean;
  public isDesktopViewToggled: boolean = false;
  public footerMenu: GridMenuItemGroupModel[] = [];
  private readonly browserWarningStatusKey = 'browserWarningWasClosed';
  public trackByTitleFn = getTrackByTranslationSourceFn('title');

  protected appInfo: AppInfoModel = {};
  protected googlePlayLink: string = NativeAppConstants.GOOGLE_PLAY_LINK;
  protected appStoreLink: string = NativeAppConstants.APP_STORE_LINK;

  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly browserService: BrowserService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly configuratorCacheService: ConfiguratorCacheService,
    private readonly platformService: PlatformService,
    private readonly seoService: SeoService,
    private readonly cookieConsentService: CookieConsentService,
    private readonly nativeAppService: NativeAppService,
  ) {
    super();
    this.getAppInfo();
  }

  private getAppInfo(): void {
    if (!PlatformCommonService.isNativeApp) {
      return;
    }

    void App.getInfo().then((appInfo: AppInfo) => {
      this.appInfo.appBuild = appInfo.build;
      this.appInfo.appVersion = appInfo.version;
    });
    void Device.getInfo().then((device: DeviceInfo) => {
      this.appInfo.deviceModel = device.model;
      this.appInfo.deviceOS = device.operatingSystem;
      this.appInfo.deviceVersion = device.osVersion;
    });
    void CapacitorUpdater.current().then((curentBundle: {
      bundle: BundleInfo;
      native: string;
    }) => {
      this.appInfo.appBundle = curentBundle?.bundle?.version;
    });

  }

  protected checkForUpdate(): void {
    void this.nativeAppService.checkUpdate();
  }

  public get browserWarningWasClosed(): boolean {
    return !!this.localStorageService.getItem(this.browserWarningStatusKey);
  }

  public get showDesktopViewToggle(): boolean {
    return !PlatformCommonService.isNativeApp;
  }

  public get isNativeApp(): boolean {
    return PlatformCommonService.isNativeApp;
  }

  protected get isProduction(): boolean {
    return PlatformCommonService.isProductionMode;
  }

  protected get environment(): string {
    return environment.ENVIRONMENT;
  }

  public ngOnInit(): void {
    if (!this.browserWarningWasClosed && !PlatformCommonService.isNativeApp) {
      this.browserUpdateLink = this.browserService.getBrowserUpdateLink();
    }

    if (!this.platformService.isServerAndMobileAndNotBot) {
      this.configuratorCacheService.menuLayouts(['FOOTER_MENU'])
        .pipe(
          take(1),
        )
        .subscribe((configElements: ConfigElementValueDto[]) => {
          try {
            //first is from BE fetched raw JSON and then all string will be transformed to the TranslationSource
            const footerMenuRaw = JSON.parse(
              configElements.find((value: ConfigElementValueDto) => value.key === 'FOOTER_MENU').value,
            ) as GridMenuItemGroupRawModel[];
            this.footerMenu = footerMenuRaw.map((itemGroup) => ({
              title: stringToTranslationSource(itemGroup.title),
              image: itemGroup.image,
              items: itemGroup.items.map((item) => stringToTranslationSource(item)),
            }));
          } catch (e) {
            withScope((scope) => {
              scope.setExtra('error', e);
              captureException(new Error('ONSITE KONFIGURATOR error in MENU_LAYOUTS - FOOTER_MENU'));
            });
          }
          this.changeDetectorRef.detectChanges();
        });
    }

    this.platformService.isMobile$
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((mobile: boolean) => {
        this.isMobile = mobile;
        this.changeDetectorRef.detectChanges();
      });

    const defView: boolean = this.localStorageService.getItem('desktopViewToggle') || false;
    if (this.isDesktopViewToggled !== defView) {
      this.toggleDesktopView(defView);
    }
  }

  public closeBrowserWarning(): void {
    this.browserUpdateLink = null;
    this.localStorageService.setItem(this.browserWarningStatusKey, true);
    this.changeDetectorRef.detectChanges();
  }

  public toggleDesktopView(toggled: boolean): void {
    if (toggled) {
      this.seoService.metaViewport = 'width=1024';
    } else {
      this.seoService.metaViewport = 'width=device-width, initial-scale=1';
    }
    this.localStorageService.setItem('desktopViewToggle', toggled);
    this.isDesktopViewToggled = toggled;
    this.changeDetectorRef.detectChanges();
  }

  public openCookiesPreferences(): void {
    this.cookieConsentService.openPreferences();
  }

}
