import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { RestHttpClientService } from '@api/rest/rest-http-client.service';
import { HttpContext } from '@angular/common/http';

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface WatchParams {
  /**
   * Offer ID
   * format: int64
   */
  id: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
@Injectable({
  providedIn: 'root'
})
export class OfferWatchingService extends RestHttpClientService {
  constructor(injector: Injector) { super(injector); }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public watch(params: WatchParams, context: HttpContext): Observable<void> {
    let pathParams = {
      id: params.id,
    };
    return this.postGenerated(`/offers/${pathParams.id}/watch`, null, null, null, null, null, context);
  }
}
