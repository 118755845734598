import { NgModule } from '@angular/core';
import { AsyncRenderDirective } from './directive/async-render.directive';

@NgModule({
  declarations: [ AsyncRenderDirective ],
  exports: [ AsyncRenderDirective ],
})
export class AsyncRenderModule {

}
