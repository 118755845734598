import { Injectable } from '@angular/core';
import { map, take, takeUntil } from 'rxjs/operators';
import { ShowListingEventResponseDto } from '@api/generated/defs/ShowListingEventResponseDto';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { PersonalizationMeasurementService } from '@shared/services/personalization/personalization-measurement.service';
import { Observable } from 'rxjs';
import { FallbackReason } from '@shared/services/personalization/model/fallback-reason.type';
import isNil from 'lodash-es/isNil';
import {
  MEASUREMENT_AUKRO_SPLIT_GROUP_VALUE,
  MEASUREMENT_AUKRO_SPLIT_GROUP_VALUES,
} from '@shared/services/personalization/personalization-measurement.helper';
import { MeasurementObjectCode } from '@shared/services/personalization/yusp-personalization.helper';
import { environmentConstants } from '@env-constants';
import { ShowEventPropertiesDto } from '@api/generated/defs/ShowEventPropertiesDto';
import { CookieService } from '@common/cookie/service/cookie.service';
import { ClickEventPropertiesDto } from '@api/generated/defs/ClickEventPropertiesDto';

@Injectable({
  providedIn: 'root',
})
export class RecommendationPersonalizationMeasurementService extends NgUnsubscribe {

  private readonly CLICK_ON_RECOMMENDATION_TITLE_RANK: number = -1;
  private readonly CLICK_ON_RECOMMENDATION_SHOW_MORE_CARD_RANK: number = -2;

  constructor(
    private readonly cookieService: CookieService,
    private readonly personalizationMeasurementService: PersonalizationMeasurementService,
  ) {
    super();
  }

  public clickOnRecommendationItem(
    itemId: number,
    itemPosition: number,
    showId: number,
    measurementObjectCode: MeasurementObjectCode | string,
    fallbackReason: FallbackReason = null,
    measurementCookie: string = environmentConstants.RECO1_COOKIE_SPLIT_GROUP_NAME,
  ): void {
    const calculatedSplitGroupValue = this.getSplitGroupValueWithFallbackReason(measurementCookie, fallbackReason);

    this.personalizationMeasurementService.clickMeasurement$({
      itemId,
      elementRank: itemPosition,
      showId,
      measurementObjectCode,
      splitGroups: { [measurementCookie]: calculatedSplitGroupValue },
    })
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  public clickOnRecommendationTitle(
    showId: number,
    measurementObjectCode: MeasurementObjectCode | string,
    fallbackReason: FallbackReason = null,
  ): void {

    const measurementCookie = environmentConstants.RECO1_COOKIE_SPLIT_GROUP_NAME;
    const calculatedSplitGroupValue = this.getSplitGroupValueWithFallbackReason(measurementCookie, fallbackReason);

    this.personalizationMeasurementService.clickMeasurement$({
      elementRank: this.CLICK_ON_RECOMMENDATION_TITLE_RANK,
      showId,
      measurementObjectCode,
      splitGroups: { [measurementCookie]: calculatedSplitGroupValue },
    })
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  public clickOnRecommendationShowMoreCard(
    showId: number,
    measurementObjectCode: MeasurementObjectCode | string,
    fallbackReason: FallbackReason = null,
  ): void {
    const measurementCookie = environmentConstants.RECO1_COOKIE_SPLIT_GROUP_NAME;
    const calculatedSplitGroupValue = this.getSplitGroupValueWithFallbackReason(measurementCookie, fallbackReason);

    this.personalizationMeasurementService.clickMeasurement$({
      elementRank: this.CLICK_ON_RECOMMENDATION_SHOW_MORE_CARD_RANK,
      showId,
      measurementObjectCode,
      splitGroups: { [measurementCookie]: calculatedSplitGroupValue },
    })
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  public clickOnFollowRecommendationItem(
    showId: number,
    measurementObjectCode: MeasurementObjectCode | string,
    itemId: number,
    isFollowed: boolean,
  ): void {

    const properties: ClickEventPropertiesDto = (isFollowed) ? { removeWatchOnly: true } : { addToWatchOnly: true };
    this.personalizationMeasurementService.clickMeasurement$({
      showId,
      itemId,
      measurementObjectCode,
      properties,
    })
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  public showRecommendationItem$(
    measurementObjectCode: MeasurementObjectCode | string,
    itemIds?: number[],
    fallbackReason: FallbackReason = null,
    showEventProperties: ShowEventPropertiesDto = null,
    measurementCookie: string = environmentConstants.RECO1_COOKIE_SPLIT_GROUP_NAME,
  ): Observable<number> {
    const calculatedSplitGroupValue = this.getSplitGroupValueWithFallbackReason(measurementCookie, fallbackReason);

    return this.personalizationMeasurementService.showMeasurement$({
      items: itemIds || [],
      measurementObjectCode,
      splitGroups: { [measurementCookie]: calculatedSplitGroupValue },
      properties: showEventProperties,
    })
      .pipe(
        map((response: ShowListingEventResponseDto) => response?.showId),
      );
  }

  private getSplitGroupValueWithFallbackReason(measurementCookie: string, fallbackReason: FallbackReason): string {
    if (isNil(fallbackReason)) {
      return this.cookieService.get(measurementCookie);
    }

    // the fallback algorithm is always marked as 'aukro' appended with the reason why the original call was wrong or couldn't happen
    return MEASUREMENT_AUKRO_SPLIT_GROUP_VALUES[fallbackReason] ?? MEASUREMENT_AUKRO_SPLIT_GROUP_VALUE;
  }

}
