import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Nil } from '@util/helper-types/nil';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';
import { ElementIdentifierUtil } from '@common/element-identifier/util/element-identifier.util';
import { BasicItemCardViewType } from '@common/ui-kit/component/item-card/component/basic-item-card/model/basic-item-card-view.type';
import { LabelComponent } from '@common/ui-kit/component/label/component/label.component';
import { trackByIndexFn } from '@util/helper-functions/track-by/track-by-index.fn';
import { Translate2Module } from '@common/translations/translate2.module';
import { BasicItemCardSizeType } from '@common/ui-kit/component/item-card/component/basic-item-card/model/basic-item-card-size.type';
import { ButtonComponent } from '@common/ui-kit/component/button/component/button.component';
import { ItemCardImageComponent } from '@common/ui-kit/component/item-card/component/item-card-image/item-card-image.component';
import { ItemCardFollowersComponent } from '@common/ui-kit/component/item-card/component/item-card-followers/item-card-followers.component';
import { ChipComponent } from '@common/ui-kit/component/chip/component/chip.component';
import { ItemCardCornerMarkComponent } from '@common/ui-kit/component/item-card/component/item-card-corner-mark/item-card-corner-mark.component';
import { ItemCardTitleComponent } from '@common/ui-kit/component/item-card/component/item-card-title/item-card-title.component';
// eslint-disable-next-line import/no-restricted-paths
import { CurrencyPipeModule } from '@shared/currency/pipe/currency-pipe.module';
import { ItemCardPriceComponent } from '@common/ui-kit/component/item-card/component/item-card-price/item-card-price.component';
import { RetailPriceComponent } from '@common/ui-kit/component/retail-price/component/retail-price.component';
import { ItemCardTimerComponent } from '@common/ui-kit/component/item-card/component/item-card-timer/item-card-timer.component';
import { RouterLink } from '@angular/router';
import { BasicItemCardItemModel } from '@common/ui-kit/component/item-card/component/basic-item-card/model/basic-item-card-item.model';
import { BasicItemCardTextSizeType } from '@common/ui-kit/component/item-card/component/basic-item-card/model/basic-item-card-text-size.type';
import { ItemCardTitleConfigModel } from '@common/ui-kit/component/item-card/component/basic-item-card/model/item-card-title-config.model';
import isNil from 'lodash-es/isNil';
import { ItemCardInfoRow } from '@common/ui-kit/component/item-card/component/basic-item-card/model/item-card-info-row.type';
import { ItemCardInfoRowPriceModel } from '@common/ui-kit/component/item-card/component/basic-item-card/model/item-card-info-row-price.model';
import { ItemCardInfoRowCountdownModel } from '@common/ui-kit/component/item-card/component/basic-item-card/model/item-card-info-row-countdown.model';
import { ItemCardInfoRowRetailPriceModel } from '@common/ui-kit/component/item-card/component/basic-item-card/model/item-card-info-row-retail-price.model';
import { ItemCardInfoRowCurrencyModel } from '@common/ui-kit/component/item-card/component/basic-item-card/model/item-card-info-row-currency.model';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { BasicItemCardInfoRowTextModel } from '@common/ui-kit/component/item-card/component/basic-item-card/model/basic-item-card-info-row-text.model';

@Component({
  selector: 'auk-basic-item-card',
  standalone: true,
  imports: [
    CommonModule,
    LabelComponent,
    Translate2Module,
    ButtonComponent,
    ItemCardImageComponent,
    ItemCardFollowersComponent,
    ChipComponent,
    ItemCardCornerMarkComponent,
    ItemCardTitleComponent,
    ItemCardTimerComponent,
    ItemCardPriceComponent,
    CurrencyPipeModule,
    RetailPriceComponent,
    RouterLink,
    MatTooltipModule,
  ],
  templateUrl: './basic-item-card.component.html',
  styleUrls: ['./basic-item-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicItemCardComponent extends NgUnsubscribe implements OnChanges {

  @Input() public item: BasicItemCardItemModel;
  @Input() public viewType: BasicItemCardViewType = 'VERTICAL';
  @Input() public size: BasicItemCardSizeType = 'DEFAULT';
  @Input() public lazyLoadImage: boolean = true;

  @Output() public itemClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() public followClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() public countdownEnd: EventEmitter<void> = new EventEmitter<void>();

  protected readonly trackByIndexFn = trackByIndexFn;

  protected itemIdAttr: string | Nil;
  protected itemCardClasses: string[] = [];

  public ngOnChanges(changes: AukSimpleChanges<typeof this>): void {
    if (changes.item) {
      this.itemIdAttr = ElementIdentifierUtil.createIdentifier('item', this.item?.id.toString());
    }

    if (changes.size || changes.item) {
      this.updateItemCardClasses();
    }
  }

  private updateItemCardClasses(): void {
    // Clear existing classes before adding new ones
    this.itemCardClasses = [];

    switch (this.size) {
      case 'SMALL':
        this.itemCardClasses.push('tw-min-w-[15rem]');
        break;
      default:
        this.itemCardClasses.push('tw-min-w-[16.5rem]');
        break;
    }

    if (this.item.hasGoldBorder) {
      this.itemCardClasses.push('tw-border', 'tw-border-solid', 'tw-border-vip');
    }
  }

  protected onItemClick(): void {
    this.itemClick.emit();
  }

  protected onFollowClick(): void {
    this.followClick.emit();
  }

  /**
   * Generate title & price text size based on card size
   */
  protected get getTextSize(): BasicItemCardTextSizeType {
    if (this.size === 'SMALL' || this.isHorizontalView) {
      return 'SMALL';
    }

    return 'DEFAULT';
  }

  protected get isSmallVersionCard(): boolean {
    return this.size === 'SMALL' || this.isHorizontalView;
  }

  protected onCountdownEnd(): void {
    this.countdownEnd.emit();
  }

  protected get isHorizontalView(): boolean {
    return this.viewType === 'HORIZONTAL';
  }

  protected get isVerticalView(): boolean {
    return this.viewType === 'VERTICAL';
  }

  protected get titleModel(): ItemCardTitleConfigModel {
    return {
      ...this.item.titleInfo,
      titleSize: this.getTextSize,
      isTwoLineClamp: this.isHorizontalView,
    };
  }

  protected canShowFollowersInfo(): boolean {
    if (isNil(this.item.followersInfo)
      || isNil(this.item.followersInfo.followersCount)
      || isNil(this.item.followersInfo.isFollowed)
      || this.item.hasEnded
    ) {
      return false;
    }
    return true;
  }

  protected getPriceInfoRow(infoRow: ItemCardInfoRow): ItemCardInfoRowPriceModel {
    return infoRow as ItemCardInfoRowPriceModel;
  }

  protected getCountdownInfoRow(infoRow: ItemCardInfoRow): ItemCardInfoRowCountdownModel {
    return infoRow as ItemCardInfoRowCountdownModel;
  }

  protected getRetailPriceInfoRow(infoRow: ItemCardInfoRow): ItemCardInfoRowRetailPriceModel {
    return infoRow as ItemCardInfoRowRetailPriceModel;
  }

  protected getCurrencyInfoRow(infoRow: ItemCardInfoRow): ItemCardInfoRowCurrencyModel {
    return infoRow as ItemCardInfoRowCurrencyModel;
  }

  protected getTextInfoRow(infoRow: ItemCardInfoRow): BasicItemCardInfoRowTextModel | Nil {
    return this.isInfoRowOfTextType(infoRow) ? infoRow : null;
  }

  protected isInfoRowOfTextType(infoRow: ItemCardInfoRow): infoRow is BasicItemCardInfoRowTextModel {
    return infoRow.type === 'TEXT';
  }

}
