import { buildEnvBase, Environment } from '@environments';

export const environment: Environment = {
  ...buildEnvBase,
  APP_HOST: '__APP_HOST__',
  APP_BACKOFFICEFRONTEND_HOST: '__APP_BACKOFFICEFRONTEND_HOST__',
  NG_PRODUCTION_MODE: '__NG_PRODUCTION_MODE__',
  API_DEFINITION_URL: null,
  API_URL: '__API_URL__',
  WS_URL: '__WS_URL__',
  SENTRY_DSN: '__SENTRY_DSN__',
  ONESIGNAL_KEY: '__ONESIGNAL_KEY__',
  ENVIRONMENT: '__ENVIRONMENT__',
  FACEBOOK_APP_ID: '__FACEBOOK_APP_ID__',
  GTM_ID_CZ: '__GTM_ID_CZ__',
  GTM_ID_SK: '__GTM_ID_SK__',
  GTM_AUTH_CZ: '__GTM_AUTH_CZ__',
  GTM_AUTH_SK: '__GTM_AUTH_SK__',
  GTM_PREVIEW_CZ: '__GTM_PREVIEW_CZ__',
  GTM_PREVIEW_SK: '__GTM_PREVIEW_SK__',
  GTM_COOKIES_WIN: '__GTM_COOKIES_WIN__',
  YUSP_SRC_1: '__YUSP_SRC_1__',
  YUSP_SRC_2: '__YUSP_SRC_2__',
  YUSP_1: '__YUSP_1__',
  YUSP_2: '__YUSP_2__',
  GMC_GSV_CZ: '__GMC_GSV_CZ__',
  GMC_GSV_SK: '__GMC_GSV_SK__',
  CAPTCHA_SITE_KEY: '__CAPTCHA_SITE_KEY__',
  HOST_FRONTEND_SK: '__HOST_FRONTEND_SK__',
  HOST_INT_FRONTEND_SK: '__HOST_INT_FRONTEND_SK__',
  MOBILE_UPDATE_VERSION_ANDROID: '__MOBILE_UPDATE_VERSION_ANDROID__',
  MOBILE_UPDATE_VERSION_IOS: '__MOBILE_UPDATE_VERSION_IOS__',
  MOBAPP_TEST_IDENTIFIER: '__MOBAPP_TEST_IDENTIFIER__',
};
