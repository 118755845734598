import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastComponent } from './component/toast.component';
import { MatIconModule } from '@angular/material/icon';
import { DefaultNoComponentGlobalConfig, TOAST_CONFIG } from 'ngx-toastr';
import { Translate2Module } from '@common/translations/translate2.module';
import { ButtonComponent } from '@common/ui-kit/component/button/component/button.component';
import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';
import { InnerHtmlDirective } from '@common/html/directive/inner-html.directive';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    ButtonComponent,
    Translate2Module,
    IconComponent,
    InnerHtmlDirective,
  ],
  declarations: [
    ToastComponent,
  ],
  providers: [
    {
      provide: TOAST_CONFIG,
      useValue: {
        default: {
          DefaultNoComponentGlobalConfig,
          toastComponent: ToastComponent,
          positionClass: 'notifications-container-bottom-left',
        },
        config: {},
      },
    },
  ],
})
export class ToastModule {
}
