import { NgModule } from '@angular/core';
import { NgFor, NgIf } from '@angular/common';
import { Translate2Module } from '@common/translations/translate2.module';
import { AukButtonDialogComponent } from '@common/ui-kit/component/form/auk-button-dialog/component/auk-button-dialog.component';
import { ButtonComponent } from '@common/ui-kit/component/button/component/button.component';
import { DialogHeaderComponent } from '@common/ui-kit/component/dialog/component/dialog-header/dialog-header.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { SvgIconComponent } from '@common/ui-kit/component/svg-icon/component/svg-icon.component';

@NgModule({
  imports: [
    NgFor,
    NgIf,
    Translate2Module,
    ButtonComponent,
    DialogHeaderComponent,
    MatDialogModule,
    SvgIconComponent,
  ],
  declarations: [
    AukButtonDialogComponent,
  ],
  exports: [
    AukButtonDialogComponent,
  ],
})
export class ButtonDialogModule {
}
