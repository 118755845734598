<div class="tw-flex tw-items-center tw-h-full"
  aukMouseOver
  (isMouseOver)="setDropdownOpenState($event)"
  >
  <auk-header-item
    (click)="onBasketClick()"
    [colorCombination]="colorCombination"
    [headerItemModel]="headerItemData"
    [isActive]="isDropdownOpened"
    />

  @if (cart?.cartItems?.length > 0) {
    <div class="dropdown-submenu right basket"
      [class.full-cart]="cartItemsRemainingCount"
      aukViewportDetector
      [checkOncePerPage]="false"
      (inViewportDetectorChange)="onPopoverInViewport($event)"
      >
      <div class="popover-header"><b>{{ 'BASKET_CONTROL_COMPONENT_HTML_1' | translate }}</b></div>
      @for (item of cart?.cartItems; track trackByCartItemId($index, item)) {
        <auk-basket-item (popoverLinkClick)="onPopoverLinkClick($event, item)" [item]="item" (remove)="removeItem(item)"></auk-basket-item>
      }
      <div class="buttons clearfix flex">
        <a class="btn-primary m-l-auto"
          routerLink="/kosik"
          (click)="reloadCart($event)"
          [aukInnerHtml]="{key: 'GO_TO_CART_BUTTON_TEXT'}"
        ></a>
      </div>
    </div>
  }
</div>
