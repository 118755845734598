import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { UserService } from '@shared/user/service/user.service';
import { UserInterestStatisticsDto } from '@api/aukro-api/model/user-interest-statistics-dto';
import { Privilege } from '@shared/user/model/privilege.enum';

@Injectable({
  providedIn: 'root',
})
export class UserPermissionsService {

  constructor(
    private readonly userService: UserService,
  ) {
  }

  public userPrivileges$(): Observable<string[]> {
    return this.userService.getActualStatistics()
      .pipe(
        map((userInfo) => userInfo?.privileges),
        take(1),
      );
  }

  public hasPrivilege(userInfo: UserInterestStatisticsDto, privilege: Privilege): boolean {
    return userInfo?.privileges?.includes(privilege);
  }

  public hasPrivilege$(privilege: Privilege): Observable<boolean> {
    return this.userService.getActualStatistics()
      .pipe(
        map((userInfo) => this.hasPrivilege(userInfo, privilege)),
        take(1),
      );
  }

}
