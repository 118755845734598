import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CookieService } from '@common/cookie/service/cookie.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import { UserMeWatchingService } from '@api/generated/api/UserMeWatching';
import { DefaultMessageSourceResolvable, WatchingItemPreviewDtoPagedModel } from '@api/generated/model';
import { AuthenticationService } from '@shared/authentication/service/authentication.service';
import { GoogleAnalyticsTrackingService } from '@shared/google-analytics/service/google-analytics-tracking.service';
import { YuspPersonalizationService } from '@shared/services/personalization/yusp-personalization.service';
import { PlatformService } from '@shared/platform/service/platform.service';
import { PushNotificationsService } from '@shared/services/push-notifications/push-notifications.service';
import { OfferWatchingService } from '@api/generated/api/OfferWatching';
import { HttpContext } from '@angular/common/http';
import { USER_ACTION_TYPE } from '@shared/user-action/token/user-action-type-http-context.token';
import { ArrayUtils } from '@util/util/array.utils';
import { IGNORE_SUGGESTED_VIEW } from '@shared/user-action-view/token/ignore-suggested-view-http-context.token';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { HttpError } from '@shared/rest/model/http-error';
import { NavigationService } from '@shared/navigation/service/navigation.service';
import { FavouritePopoverService } from '@shared/favourite/service/favourite-popover.service';

@Injectable({
  providedIn: 'root',
})
export class WatchingService extends NgUnsubscribe {

  private cookieName = 'watch-products';
  private watchingPreview$ = new BehaviorSubject<WatchingItemPreviewDtoPagedModel>(null);

  constructor(
    private readonly cookieService: CookieService,
    private readonly authService: AuthenticationService,
    private readonly yuspPersonalizationService: YuspPersonalizationService,
    private readonly platformService: PlatformService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly navigationService: NavigationService,
    private readonly googleAnalyticsTrackingService: GoogleAnalyticsTrackingService,
    private readonly userMeWatchingService: UserMeWatchingService,
    private readonly pushNotificationsService: PushNotificationsService,
    private readonly offerWatchingService: OfferWatchingService,
    private readonly favouritePopoverService: FavouritePopoverService,
  ) {
    super();

    this.authService.getLoginStatusChange()
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((isSignedIn: boolean) => {
        if (isSignedIn) {
          this.cookieService.remove(this.cookieName);
          this.setNewWatchingAfterLogin();
          this.loadWatchingPreview();
        } else {
          this.watchingPreview$.next(null);
        }
      });
    this.loadWatchingPreview();
  }

  /** Get the latest watched items for popup in the page header. */
  public getWatchingPreview$(): Observable<WatchingItemPreviewDtoPagedModel> {
    return this.watchingPreview$.asObservable();
  }

  public startWatchingItem(itemId: number, ignoreSuggestedView: boolean = false): Observable<void> {
    const httpContext: HttpContext = new HttpContext()
      .set(USER_ACTION_TYPE, 'ADD_WATCH')
      .set(IGNORE_SUGGESTED_VIEW, ignoreSuggestedView);

    return this.offerWatchingService.watch({ id: itemId }, httpContext)
      .pipe(
        tap(() => {
          this.yuspPersonalizationService.trackAddToWatchListEvent(itemId);
          this.googleAnalyticsTrackingService.trackSimpleEvent('addToWatchList');
          // Open OneSignal prompt after start watching item.
          this.pushNotificationsService.init();
          // when success, reload watching preview
          this.favouritePopoverService.refreshPopoverData();
        }),
      );

  }

  public removeWatchedItem(...watchedItemIds: number[]): Observable<void> {
    if (this.authService.isLoggedIn()) {
      return this.userMeWatchingService.removeFromWatching({ watchedItemIds })
        .pipe(
          // when success, reload watching preview
          tap(() => {
            this.favouritePopoverService.refreshPopoverData();
          }),
        );
    } else {
      return of(null)
        .pipe(
          tap(() => void this.router.navigate(['/prihlaseni'], { queryParams: { returnUrl: this.router.url } })),
        );
    }
  }

  public isAlreadyWatchedError(error: HttpError): boolean {
    return ArrayUtils.isNotEmpty(error?.body?.errors)
      && error.body.errors.some((item: DefaultMessageSourceResolvable) => item.defaultMessage === 'validation.item.watching.duplicity');
  }

  private loadWatchingPreview(): void {
    if (this.platformService.isServer || !this.authService.isLoggedIn()) {
      return;
    }

    this.userMeWatchingService.preview()
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((preview: WatchingItemPreviewDtoPagedModel) => {
        this.watchingPreview$.next(preview);
      });
  }

  private setNewWatchingAfterLogin(): void {
    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        const { watchItem } = this.route.snapshot.queryParams;
        const watchItemId: number = parseInt(watchItem, 10);
        if (watchItemId) {
          this.startWatchingItem(watchItemId)
            .pipe(
              take(1),
            )
            .subscribe();
        }
        const cleanUrl = this.navigationService.removeQueryParam('watchItem');
        void this.router.navigateByUrl(cleanUrl, { replaceUrl: true });
      });
  }

}
