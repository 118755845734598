<div class="tw-bg-secondary tw-rounded tw-flex tw-content-center tw-mt-1">
  <a class="tw-flex tw-items-center tw-tw-text-tertiary-900 hover:tw-no-underline tw-py-1"
    [aukLinkFromHref]="hrefUrl"
    [openInNewTab]="true"
    >
    <div class="tw-px-2">
      <auk-icon [source]="source"
        [colorCombination]="colorCombination"
        iconType="SVG"
        size="LG">
      </auk-icon>
    </div>
    @if (text) {
      <p class="tw-text-md tw-leading-none tw-mr-2 tw-text-tertiary-600">
        @if (spanText) {
          <span class="tw-block tw-text-xs">{{ spanText | translateSource }}</span>
        }
        {{ text | translateSource }}
      </p>
    }
  </a>
</div>


