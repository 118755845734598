import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { RestHttpClientService } from '@api/rest/rest-http-client.service';
import * as model from '../model';

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface GetWatchingItemsParams {
  /**
   * Filter by category ID
   * format: int64
   */
  categoryIdFilter?: number;
  /** State of offer from the list of allowed values */
  itemStateFilter: ItemStateFilterGetWatchingItemsParamsEnum;
  /** Type of offer from the list of allowed values */
  itemTypeFilter?: ItemTypeFilterGetWatchingItemsParamsEnum;
  /** format: int32 */
  page?: number;
  /** format: int32 */
  size?: number;
  sort?: string[];
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export type ItemStateFilterGetWatchingItemsParamsEnum =
  'ACTIVE' |
  'ENDED' |
  'SCHEDULED';

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export type ItemTypeFilterGetWatchingItemsParamsEnum =
  'BIDDING' |
  'BOTH' |
  'BUYNOW';

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface RemoveFromWatchingParams {
  /** List of items ids to remove */
  watchedItemIds: number[];
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface CategoriesParams {
  /** State of offer from the list of allowed values */
  itemStateFilter: ItemStateFilterCategoriesParamsEnum;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export type ItemStateFilterCategoriesParamsEnum =
  'ACTIVE' |
  'ENDED' |
  'SCHEDULED';

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface NotificationParams {
  /** watchingItemNotificationDto */
  watchingItemNotificationDto: model.WatchingItemNotificationDto;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
@Injectable({
  providedIn: 'root'
})
export class UserMeWatchingService extends RestHttpClientService {
  constructor(injector: Injector) { super(injector); }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public getWatchingItems(params: GetWatchingItemsParams): Observable<model.WatchingItemPagedModel> {
    let queryParams = {
      categoryIdFilter: params.categoryIdFilter,
      itemStateFilter: params.itemStateFilter,
      itemTypeFilter: params.itemTypeFilter,
      page: params.page,
      size: params.size,
      sort: params.sort,
    };
    return this.getGenerated(`/users/me/watching`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public removeFromWatching(params: RemoveFromWatchingParams): Observable<void> {
    let queryParams = {
      watchedItemIds: params.watchedItemIds,
    };
    return this.deleteGenerated(`/users/me/watching`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public categories(params: CategoriesParams): Observable<model.WatchingItemCategoryDto[]> {
    let queryParams = {
      itemStateFilter: params.itemStateFilter,
    };
    return this.getGenerated(`/users/me/watching/categories`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public notification(params: NotificationParams, headers: { [key: string]: string } = {}): Observable<number[]> {
    let bodyParams = params.watchingItemNotificationDto;
    let queryParams;
    return this.putGenerated(`/users/me/watching/notification`, bodyParams, queryParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public preview(): Observable<model.WatchingItemPreviewDtoPagedModel> {
    return this.getGenerated(`/users/me/watching/preview`);
  }
}
