<!-- DESCRIPTION -->
@if (canShowDescription) {
  @if (showMoreData?.url) {
    <a [routerLink]="getRedirectUrl(showMoreData.url)"
       [queryParams]="getRedirectUrlQueryParams(showMoreData.url)"
       (click)="onRecommendationTitleClick()"
       class="tw-inline-block tw-no-underline hover:tw-no-underline tw-text-tertiary-900 visited:tw-text-tertiary-900"
    >
      <h2 class="tw-inline-block tw-my-4 tw-font-medium">{{ description | translateSource }}</h2>
      <auk-icon
          iconType="MATERIAL"
          source="trending_flat"
          size="LG"
          colorCombination="TERTIARY_500_CONTRAST"
          class="tw-mb-1.5 tw-ml-1 tw-align-middle"
          [matTooltipShowDelay]="250"
          [matTooltip]="showMoreTooltip | translateSource"
      />
    </a>
  } @else {
    <h2 class="tw-inline-block tw-my-4 tw-font-medium tw-text-tertiary-900">{{ description | translateSource }}</h2>
  }
}
<!-- ./DESCRIPTION -->

<!-- it has [negative margins on small devices], to touch screen when scrolling -->
<div class="tw-relative tw-mx-[-0.5rem] md:tw-mx-[-1rem] lg:tw-mx-0 tw-pb-4">
  @if (isLoading) {
    <div class="tw-w-full tw-h-[360px] tw-bg-contrast">
      <auk-spinner [v2]="true" size="3XL"/>
    </div>
  } @else {
    <!-- `aukViewportDetector` has to be on element which is not displayed while `isLoading` is true -->
    <auk-native-drag-scroll [virtualScrollRef]="intersectionElRef"
                            [staticChildWidth]="staticChildWidth"
                            aukViewportDetector
                            (inViewportDetectorChange)="isInViewPortHandler($event)"
    >
      @if (processedItems.length > 0) {
        <div #aukIntersectionEl
             aukIntersectionObserver
             [intersectionThreshold]="0.1"
             [intersectionRoot]="intersectionElRef?.nativeElement"
             [children]="processedItems"
             intersectionRootMargin="80%"
             (visibilityChange)="onVisibilityChanged($event)"
             class="tw-flex tw-flex-row tw-overflow-y-hidden no-scrollbar tw-gap-2"
        >
          <!-- VIRTUAL RENDER -->
          @for (item of processedItems; let index = $index; track item.id) {
            <div class="tw-w-[240px]"
                 [attr.id]="item.id"
            >
              @if (itemsVisibilityStatusMap[item.id] === 'Visible') {
                @if (!item.extraTmpl) {
                  <!-- ITEM CARD -->
                  <auk-basic-item-card
                      [item]="item.cardItem"
                      [lazyLoadImage]="false"
                      class="tw-w-[240px]"
                      size="SMALL"
                      viewType="VERTICAL"
                      aukNativeDragScrollChild
                      (itemClick)="onItemClick(item)"
                      (followClick)="onItemFollowClick(item)"
                      (countdownEnd)="onCountdownEnd(item)"
                  />
                  <!-- ./ITEM CARD -->
                } @else if (isShowMoreCardVisible && item.extraTmpl) {
                  <!-- MORE TEMPLATE -->
                  <div aukNativeDragScrollChild
                       class="tw-w-[240px] tw-h-[348px] tw-basis-[240px] tw-grow-0 tw-flex tw-shrink-0"
                  >
                    <ng-container [ngTemplateOutlet]="showMoreCardTmpl"
                                  [ngTemplateOutletContext]="{ showMoreData: showMoreData }"
                    />
                  </div>
                  <!-- ./MORE TEMPLATE -->
                }
              } @else {
                <auk-skeleton-loading
                    [attr.id]="item.id"
                    colorCombination="FADED_INVERSED"
                    class="!tw-w-[240px]"
                    height="348px"
                />
              }
            </div>
          }
          <!-- VIRTUAL RENDER -->
        </div>
      }
    </auk-native-drag-scroll>
  }
</div>

<ng-template #showMoreCardTmpl let-showMoreData="showMoreData">
  @if (showMoreData?.url) {
    <a aukNativeDragScrollChild
       class="tw-h-full"
       [routerLink]="getRedirectUrl(showMoreData.url)"
       [queryParams]="getRedirectUrlQueryParams(showMoreData.url)"
       (click)="onRecommendationShowMoreCardClick()"
    >
      <div class="tw-grow tw-flex tw-flex-col tw-items-center tw-justify-center tw-bg-contrast tw-text-tertiary-600 tw-rounded-lg">
        <auk-icon-button
            class="tw-inline-block"
            colorCombination="PRIMARY_CONTRAST_OPPOSITE"
            [hasShadow]="false"
            [isCircle]="false"
            paddingClass="tw-p-2.5"
            [iconModel]="{ type: 'SVG', size: 'MD', colorCombination: 'PRIMARY', source: 'arrow-gray-sm' }"
        />
        @if (showMoreData.title) {
          <div class="tw-mt-2 tw-text-sm tw-text-center tw-px-2">{{ showMoreData.title }}</div>
        }
        @if (showMoreData.titleKey) {
          <div class="tw-mt-2 tw-text-sm tw-text-center tw-px-2">{{ showMoreData.titleKey | translate }}</div>
        }
      </div>
    </a>
  }
</ng-template>
