import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { BrowserService } from '@shared/platform/browser.service';

import { PlatformCommonService } from '@common/platform/service/platform-common.service';

@Component({
  selector: 'auk-go-back-button',
  templateUrl: './go-back-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [],
})
export class GoBackButtonComponent extends NgUnsubscribe implements OnInit {

  @Input() public isGlobalButton: boolean = false;
  private hasHistory: boolean = false;

  constructor(
    private readonly browserService: BrowserService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.browserService.hasHistory$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((hasHistory: boolean) => {
        this.hasHistory = hasHistory;
        this.changeDetectorRef.detectChanges();
      });
  }

  protected get canShow(): boolean {
    return this.hasHistory && PlatformCommonService.isNativeApp;
  }

  /**
   * Navigates back in browser history
   */
  protected goBack(): void {
    this.browserService.navigateBack();
  }

}
