import { Directive, Input, ElementRef, HostListener, ComponentRef, OnDestroy, OnInit, Output, EventEmitter, OnChanges, Inject, ChangeDetectorRef } from '@angular/core';
import { FlexibleConnectedPositionStrategy, Overlay, OverlayConfig, OverlayContainer, OverlayRef, ViewportRuler } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Tooltip2Component } from '../component/tooltip2.component';
import { TooltipPositionService } from '../tooltip2-position/model/service/tooltip2-position.service';
import { Tooltip2Position } from '../tooltip2-position/model/tooltip2-position.type';
import { take, Subject, takeUntil, merge, distinctUntilChanged, Subscription } from 'rxjs';
import { Tooltip2Model } from '../model/tooltip2.model';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';
import { CustomPositionStrategy } from '../domain/custom-position-strategy';
import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { CustomOverlayContainer } from '../service/custom-overlay-container';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { ResponsivenessService } from '@common/responsiveness/service/responsiveness.service';
import { Tooltip2ArrowPlacementType } from '@common/tooltip2/model/tooltip2-arrow-placement.type';

@Directive({
  selector: '[aukToolTip2]',
  providers: [
    // create new overlay container for this component with specific class and style
    Overlay,
    { provide: OverlayContainer, useClass: CustomOverlayContainer },
  ],
  standalone: true,
})
export class Tooltip2Directive extends NgUnsubscribe implements OnInit, OnDestroy, OnChanges {

  /** Model must be specified at least with text variable to be displayed in the tooltip */
  @Input() public tooltipModel: Tooltip2Model;

  /** Possible placement position */
  @Input() public placement: Tooltip2Position = 'bottom';

  /** Possible placement position */
  @Input() public delayOnClose: number = 500;

  /** Arrow placement position */
  @Input() public arrowPlacement: Tooltip2ArrowPlacementType = 'auto';

  /** Flag which tells whether the tooltip should open on init */
  @Input() public openOnInit: boolean = false;

  /** Flag which tells if tooltip is disabled */
  @Input() public isDisabled: boolean = false;

  /** Triggers for control over tooltip opening / closing events */
  @Input() public trigger: 'hover' | 'manual' = 'hover';

  /**
   * Determines whether a backdrop is displayed behind the tooltip.
   * Only applies for CUSTOM tooltip type & only for MdAndLower breakpoint.
   */
  @Input() public enableBackdrop: boolean = false;

  @Input() public showCloseButton: boolean = false;

  @Input() public hideOnCloseBtnClick: boolean = true;

  @Input() public toggleOnTouch: boolean = true;

  @Output() public clickAction: EventEmitter<string> = new EventEmitter<string>();

  @Output() public onCloseClick: EventEmitter<void> = new EventEmitter<void>();

  @Output() public isTooltipShown: EventEmitter<boolean> = new EventEmitter<boolean>();

  private tooltipRef: ComponentRef<Tooltip2Component>;

  private overlayRef: OverlayRef;

  /** To check whether mouse is over the tooltip content */
  private isMouseOverContent: boolean = false;

  /** To check whether mouse is over the tooltip element ref */
  private isMouseOverElementRef: boolean = false;

  private backdropClickSubscription: Subscription;
  private backdropEnablingSubscription: Subscription;
  private closeTimeoutSubscription: Subscription;
  private isTooltipOpen: boolean = false;
  private positionStrategy: FlexibleConnectedPositionStrategy;
  private observer: IntersectionObserver;
  private mutationObserver: MutationObserver;
  private isHostElementVisible: boolean = false;
  private positionChangesUnsubscribe: Subject<void> = new Subject<void>();
  private hasBackdrop: boolean = false;
  private delayCloseTimer: number = 500;

  constructor(
    private readonly overlay: Overlay,
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly positionService: TooltipPositionService,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly platform: Platform,
    private readonly overlayContainer: OverlayContainer,
    private readonly viewportRuler: ViewportRuler,
    private readonly platformCommonService: PlatformCommonService,
    private readonly ngZoneUtilService: NgZoneUtilService,
    private readonly responsivenessService: ResponsivenessService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  public ngOnInit(): void {
    if (this.openOnInit) {
      this.show();
    }
  }

  public ngOnChanges(changes: AukSimpleChanges<typeof this>): void {
    if (changes.isDisabled) {
      if (this.isDisabled) {
        this.closeToolTip();
      } else {
        this.updatePosition();
      }
    }

    if (changes.delayOnClose) {
      this.delayCloseTimer = changes.delayOnClose.currentValue;
    }

    if (changes.enableBackdrop) {
      if (this.enableBackdrop) {
        this.backdropEnablingSubscription = this.responsivenessService.isMdAndLower$
          .pipe(
            takeUntil(this.ngUnsubscribe),
          )
          .subscribe((isMdAndLower) => {
            this.isBackDropAvailable(isMdAndLower);
            this.changeDetectorRef.markForCheck();
          });
      } else {
        this.backdropEnablingSubscription?.unsubscribe();
      }
    }

  }

  public override ngOnDestroy(): void {
    this.removeEventListener();
    this.closeToolTip();
    this.backdropEnablingSubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  private createOverlay(withPush: boolean): void {
    // If a positionStrategy exists, dispose it before creating a new one
    if (this.positionStrategy) {
      this.positionStrategy.dispose();
      this.positionChangesUnsubscribe.next();
    }

    // ts-ignore needed as `_getExactOverlayY` is private and only accessable within FlexibleConnectedPositionStrategy
    // @ts-ignore
    this.positionStrategy = new CustomPositionStrategy(
      this.elementRef,
      this.viewportRuler,
      this.document,
      this.platform,
      this.overlayContainer,
    ).withPositions([
      this.positionService.getPosition(this.placement),
      this.positionService.getOppositePosition(this.placement),
    ])
      .withPush(true)
      .withLockedPosition(true)
      .withFlexibleDimensions(false);

    this.positionStrategy.positionChanges
      .pipe(
        distinctUntilChanged((prev, curr) => prev.connectionPair === curr.connectionPair),
        takeUntil(merge(this.positionChangesUnsubscribe, this.ngUnsubscribe)),
      )
      .subscribe(change => {
        const newPosition = this.positionService.getTooltip2Position(change.connectionPair)
          || this.positionService.getTooltip2Position(this.positionService.getOppositePosition(this.placement));
        this.tooltipRef.instance.updatePlacement(newPosition);
      });

    this.overlayRef = this.overlay.create(this.getOverlayConfig(this.tooltipModel));

    if (this.tooltipModel.type === 'CUSTOM') {
      this.backdropClickSubscription = this.overlayRef.backdropClick()
        .subscribe(() => this.closeToolTip());
    }
  }

  private isBackDropAvailable(isAvailable: boolean): void {
    if (isAvailable) {
      this.hasBackdrop = true;
      this.setBackdropTimer();
    } else {
      this.closeToolTip();
      this.hasBackdrop = false;
    }
  }

  /**
   * When backdrop is enabled we need to set delay on close, so when user click outside the tooltip it will
   * not trigger components click events under. This BUG is only in Safari and iOS Mobile App
   */
  private setBackdropTimer(): void {
    if (this.hasBackdrop && this.delayCloseTimer < 100) {
      this.delayCloseTimer = 100;
    }
  }

  public updatePosition(): void {
    if (this.overlayRef && this.overlayRef.hasAttached()) {
      this.positionStrategy.withLockedPosition(false).reapplyLastPosition();
      this.positionStrategy.withLockedPosition(true);
      this.tooltipRef.instance.updateArrowPosition();
    }
  }

  public isTooltipExist(): boolean {
    if (this.overlayRef && this.overlayRef.hasAttached()) {
      return true;
    }
  }

  private createEventListener(): void {
    if (this.trigger !== 'hover' || !this.overlayRef?.overlayElement) {
      return;
    }

    this.overlayRef?.overlayElement.addEventListener('mouseenter', this.mouseEnterListener);
    this.overlayRef?.overlayElement.addEventListener('mouseleave', this.mouseLeaveListener);
  }

  private removeEventListener(): void {
    if (this.trigger !== 'hover' || !this.overlayRef?.overlayElement) {
      return;
    }

    this.overlayRef?.overlayElement.removeEventListener('mouseenter', this.mouseEnterListener);
    this.overlayRef?.overlayElement.removeEventListener('mouseleave', this.mouseLeaveListener);
  }

  private mouseEnterListener = (): void => {
    this.isMouseOverContent = true;
    // Cancel the close timeout when the mouse enters
    if (this.closeTimeoutSubscription && !this.closeTimeoutSubscription.closed) {
      this.closeTimeoutSubscription.unsubscribe();
    }
  };

  private mouseLeaveListener = (): void => {
    this.isMouseOverContent = false;
    if (this.isTooltipOpen) {
      this.startCloseTimeout();
    }
  };

  private onTooltipClick(): void {
    this.isMouseOverElementRef = true;

    if (this.trigger !== 'hover' || this.isTooltipOpen) {
      return;
    }

    this.show();
  }

  /**
   * This method will be called whenever user touches / clicks in the Host element
   */
  @HostListener('touchstart')
  private onTouchStart(): void {
    if (this.toggleOnTouch) {
      if (this.isTooltipOpen) {
        this.hide();
      } else {
        this.onTooltipClick();
      }
    }
  }

  /**
   * This method will be called whenever mouse enters in the Host element
   */
  @HostListener('mouseenter')
  private onMouseEnter(): void {
    if (!this.platformCommonService.isTouchDevice) {
      this.onTooltipClick();
    }
  }

  /**
   * This method will be called when mouse goes out of the host element
   */
  @HostListener('mouseleave')
  private onMouseLeave(): void {
    this.isMouseOverElementRef = false;
    if (this.trigger !== 'hover') {
      return;
    }

    this.hide();
  }

  public show(): void {
    // Show tooltip if it's not disabled, also hide tooltip on server
    if (this.isDisabled || this.platformCommonService.isServer || this.isTooltipOpen) {
      return;
    }

    this.createOverlay(this.isHostElementVisible);
    this.createEventListener();

    // Start observing when the tooltip is shown
    this.createIntersectionObserver();
    this.observeDomChanges();

    if (this.overlayRef && !this.overlayRef.hasAttached()) {
      //Calculate top position where tooltip is placed so we can calculate correct height for scrolling
      const topPosition = this.elementRef?.nativeElement?.getBoundingClientRect()?.top
        + this.elementRef?.nativeElement?.getBoundingClientRect()?.height;

      this.tooltipRef = this.overlayRef.attach(new ComponentPortal(Tooltip2Component));
      this.tooltipRef.instance.tooltipModel = this.tooltipModel;
      this.tooltipRef.instance.placement = this.placement;
      this.tooltipRef.instance.arrowPlacement = this.arrowPlacement;
      this.tooltipRef.instance.hostElement = this.elementRef;
      this.tooltipRef.instance.showCloseButton = this.showCloseButton;

      if (this.hasBackdrop) {
        this.tooltipRef.instance.setCorrectHeight(topPosition);
      }

      this.tooltipRef.instance.closeClick
        .pipe(
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe(() => {
          this.onCloseClick.emit();
          if (this.hideOnCloseBtnClick) {
            this.closeToolTip();
          }
        });
      this.tooltipRef.instance.clickAction
        .pipe(
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe((event) => {
          this.clickAction.emit(event);
        });

      this.isTooltipOpen = true;
      this.isTooltipShown.emit(true);
    }
  }

  public hide(): void {
    if ((this.trigger === 'hover' && ((this.isMouseOverElementRef || this.isMouseOverContent))
      || this.isDisabled) && !this.platformCommonService.isTouchDevice) {
      return;
    }

    this.startCloseTimeout();
  }

  /**
   * Close the tooltip by detaching the component from the overlay
   */
  public closeToolTip(): void {
    if (this.overlayRef) {
      this.removeEventListener();

      // Dispose strategy to release resources
      this.positionStrategy.dispose();
      this.positionChangesUnsubscribe.next();

      // Destroy observers
      this.observer?.unobserve(this.elementRef.nativeElement);
      this.observer?.disconnect();
      this.mutationObserver?.disconnect();

      this.overlayRef.detach();
      this.overlayRef.dispose();

      this.isTooltipOpen = false;
      this.isTooltipShown.emit(false);

      this.backdropClickSubscription?.unsubscribe();
    }
  }

  /**
   * Start tooltip close timeout
   */
  private startCloseTimeout(): void {
    // Cancel the previous subscription if it exists
    if (this.closeTimeoutSubscription && !this.closeTimeoutSubscription.closed) {
      this.closeTimeoutSubscription.unsubscribe();
    }

    // Start a new timer
    this.closeTimeoutSubscription = this.ngZoneUtilService.timerOut$(this.delayCloseTimer)
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        if (this.trigger === 'hover' && ((!this.isMouseOverElementRef && !this.isMouseOverContent) || this.trigger !== 'hover')
          || this.platformCommonService.isTouchDevice) {
          this.closeToolTip();
        }
      });
  }

  private observeDomChanges(): void {
    this.mutationObserver = new MutationObserver(() => {
      if (this.overlayRef) {
        this.overlayRef.updatePosition();
        this.tooltipRef.instance.updateArrowPosition();
      }
    });

    // Start observing the document
    this.mutationObserver.observe(this.document, {
      childList: false, // don't observe direct children
      subtree: true, // and lower descendants
      characterDataOldValue: true, // pass old data to callback
    });
  }

  private createIntersectionObserver(): void {
    // updates tooltip position
    const updateTooltipPosition = (): void => {
      // Update withPush based on the visibility of the host element
      this.positionStrategy = this.positionStrategy.withPush(this.isHostElementVisible);

      // Update the overlay's position strategy
      this.overlayRef?.updatePositionStrategy(this.positionStrategy.withLockedPosition(!this.isHostElementVisible));
    };

    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const wasVisible = this.isHostElementVisible;
        this.isHostElementVisible = entry.isIntersecting;

        updateTooltipPosition();

        if (this.isHostElementVisible !== wasVisible) {
          this.overlayRef.updatePosition();
          this.tooltipRef.instance.updateArrowPosition();
        }
      });
    });

    // we need to update the position also asap and not wait for intersection observer emit, otherwise the tooltip can blink
    updateTooltipPosition();

    this.observer.observe(this.elementRef.nativeElement);
  }

  private getOverlayConfig(tooltipModel: Tooltip2Model): OverlayConfig {
    const baseConfig: OverlayConfig = {
      positionStrategy: this.positionStrategy,
      scrollStrategy: this.hasBackdrop ? this.overlay.scrollStrategies.block() : this.overlay.scrollStrategies.reposition(),
    };

    if (tooltipModel.type === 'BUTTONS') {
      return baseConfig;
    }

    if (tooltipModel.type === 'CUSTOM') {
      baseConfig.hasBackdrop = this.hasBackdrop;
      this.setBackdropTimer();
      return baseConfig;
    }

    const size = tooltipModel.size || 'MD';

    if (size === 'MD') {
      return {
        ...baseConfig,
        maxWidth: 380,
        minWidth: 300,
      };
    }
    return {
      ...baseConfig,
      maxWidth: 250,
    };
  }

}

