<auk-icon
  [colorCombination]="iconColorCombination"
  aukToolTip2
  [tooltipModel]="$any({
            type: 'INFORMATIVE',
            text: hintTooltip,
            size: 'SM',
          })"
  [delayOnClose]="0"
  [placement]="tooltipPosition"
  [isDisabled]="isTooltipDisabled"
  class="tw-cursor-pointer"
  iconType="SVG"
  size="MD"
  source="info_outline"
/>
