<div class="tw-flex tw-flex-col">
  @if (titleText) {
    <p class="tw-text-2xl md:tw-text-2xl tw-font-medium tw-pb-3">{{ titleText | translateSource }}</p>
  }
  @if (subtitleText) {
    <p class="tw-text-lg tw-font-medium tw-pb-3">{{ subtitleText | translateSource }}</p>
  }
  @for (tiles of tileListContainer; track trackByIndexFn(i, tiles); let last = $last; let i = $index) {
    @if (tiles?.length > 0) {
      <div [ngClass]="containerSpacingClass">
        <auk-tile-container
          (tileClick)="onTileClick($event)"
          [type]="type"
          [tileSize]="tileSize"
          [tileContainerStyle]="adjustedTileContainerStyles[i]"
          [tileType]="tileType"
          [tileList]="tiles"
        ></auk-tile-container>
        @if (type === 'ONE-LINE' && !last) {
          <div class="tw-h-[1px] tw-bg-silverstein-100 tw-mx-6 tw-my-2"></div>
        }
      </div>
    }
  }
</div>
