import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SubbrandType } from '@shared/subbrand/model/subbrand.type';
import { Nil } from '@util/helper-types/nil';

@Injectable({
  providedIn: 'root',
})
export class SubbrandSessionDataService {

  // TODO(PDEV-24199) Delete subbrand FF
  private _sessionSubbrandEnabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _sessionSubbrand$: BehaviorSubject<SubbrandType> = new BehaviorSubject<SubbrandType>(null);

  public get sessionSubbrand$(): Observable<SubbrandType | Nil> {
    return this._sessionSubbrand$.asObservable();
  }

  public get sessionSubbrand(): SubbrandType | Nil {
    return this._sessionSubbrand$.getValue();
  }

  public set sessionSubbrand(subbrand: SubbrandType) {
    this._sessionSubbrand$.next(subbrand);
  }

  public sessionSubbrandEnabled$(): Observable<boolean> {
    return this._sessionSubbrandEnabled$.asObservable();
  }

  public set sessionSubbrandEnabled(enabled: boolean) {
    this._sessionSubbrandEnabled$.next(enabled);
  }

}
