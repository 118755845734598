import { Injectable } from '@angular/core';

import { AppInitializer } from '@util/helper-types/initializer.interface';
import { TokenMonitoringService } from '@shared/services/token-monitoring/token-monitoring.service';
import { filter, switchMap, take, takeUntil } from 'rxjs/operators';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { RefreshTokenDataRequestParams, UsersApiService } from '@api/aukro-api/api/users-api.service';
import { UserAgentService } from '@shared/platform/service/user-agent.service';
import { AuthCacheService } from '@shared/authentication/service/auth-cache.service';
import { PlatformService } from '@shared/platform/service/platform.service';
import { isNotNil } from '@util/helper-functions/is-not-nil';
import { of } from 'rxjs';
import isEqual from 'lodash-es/isEqual';
import { CookieService } from '@common/cookie/service/cookie.service';

@Injectable({
  providedIn: 'root',
})
export class TokenInitializer extends NgUnsubscribe implements AppInitializer {

  public static readonly LAST_TOKEN_EXTRA_DATA: string = 'platform-type-user-agent';

  constructor(
    private readonly tokenMonitoringService: TokenMonitoringService,
    private readonly userAgentService: UserAgentService,
    private readonly authCacheService: AuthCacheService,
    private readonly usersApiService: UsersApiService,
    private readonly cookieStorageService: CookieService,
  ) {
    super();
  }

  public init(): void {
    const params: RefreshTokenDataRequestParams = {
      platformType: PlatformService.platformType,
      userAgent: this.userAgentService.userAgent,
    };
    this.tokenMonitoringService.checkAndGenerateToken()
      .pipe(
        take(1),
        filter(() => isNotNil(this.authCacheService.aukroToken)),
        filter(() => {
          const lastParams: RefreshTokenDataRequestParams = this.cookieStorageService
            .getObject(TokenInitializer.LAST_TOKEN_EXTRA_DATA) as RefreshTokenDataRequestParams;
          return !isEqual(params, lastParams);
        }),
        switchMap((generatedNewToken) => {
          if (generatedNewToken) {
            return of(null);
          }
          return this.usersApiService.refreshTokenData$(params);
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        this.cookieStorageService.putObject(TokenInitializer.LAST_TOKEN_EXTRA_DATA, params);
      });
  }

}
