import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CommonComponentsModule } from '@shared/legacy/component/common-components.module';
import { CommonDirectivesModule } from '@shared/legacy/directive/common-directives.module';
import { CommonPipesModule } from '@shared/legacy/pipe/common-pipes.module';
import { AppFooterComponent } from './component/app-footer.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { AccordionModule } from '@common/ui-kit/component/accordion/accordion.module';
import { GridMenuComponent } from '@common/ui-kit/component/grid-menu/component/grid-menu.component';
import { BackgroundBoxComponent } from '@common/ui-kit/component/background-box/component/background-box.component';
import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';
import { Translate2Module } from '@common/translations/translate2.module';
import { SocialIconButtonComponent } from '@common/ui-kit/component/social-icon-button/social-icon-button.component';
import { SocialStoreButtonComponent } from '@common/ui-kit/component/social-store-button/social-store-button.component';
import { HideIfMobileAppDirective } from '@shared/platform/is-mobileapp.directive';
import { SvgIconComponent } from '@common/ui-kit/component/svg-icon/component/svg-icon.component';
import { InnerHtmlDirective } from '@common/html/directive/inner-html.directive';
import { LinkFromHrefDirective } from '@common/routing/directive/link-from-href.directive';
import { RouterLinkTargetDirective } from '@common/routing/directive/router-link-target.directive';
import { ButtonComponent } from '@common/ui-kit/component/button/component/button.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CommonDirectivesModule,
    ReactiveFormsModule,
    CommonComponentsModule,
    CommonPipesModule,
    TranslateModule,
    GridMenuComponent,
    MatExpansionModule,
    AccordionModule,
    BackgroundBoxComponent,
    IconComponent,
    Translate2Module,
    HideIfMobileAppDirective,
    SocialIconButtonComponent,
    SocialStoreButtonComponent,
    SvgIconComponent,
    InnerHtmlDirective,
    LinkFromHrefDirective,
    RouterLinkTargetDirective,
    ButtonComponent,
  ],
  declarations: [
    AppFooterComponent,
  ],
  exports: [
    AppFooterComponent,
  ],
})
export class AppFooterModule {
}
