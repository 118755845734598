import { Directive, ElementRef, Injector, Input, OnChanges } from '@angular/core';
import { Nil } from '@util/helper-types/nil';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';
import { TranslationSource } from '@common/translations/model/translation-source';
import { TranslationUtil } from '@common/translations/util/translation.util';
import isNil from 'lodash-es/isNil';
import { TranslateSourcePipeService } from '@common/translations/service/translate-source-pipe.service';
import { HtmlSanitizeService } from '@common/html/service/html-sanitize.service';
import { merge, Subject } from 'rxjs';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { RoutingService } from '@common/routing/service/routing.service';
import { StringUtils } from '@util/util/string.utils';
import { isNotNil } from '@util/helper-functions/is-not-nil';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[aukInnerHtml]',
  standalone: true,
})
export class InnerHtmlDirective extends NgUnsubscribe implements OnChanges {

  /**
   * Html string, which will be passed as innerHtml on this element
   */
  @Input({ required: true }) public aukInnerHtml: TranslationSource | string | Nil;
  /**
   * see {@link HtmlSanitizeService#getSanitizedHtmlString}
   */
  @Input() public allowStrictSanitize: boolean = true;

  private readonly innerHtmlChange$: Subject<void> = new Subject<void>();

  constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly translateSourcePipeService: TranslateSourcePipeService,
    private readonly injector: Injector,
    private readonly htmlSanitizeService: HtmlSanitizeService,
    private readonly platformCommonService: PlatformCommonService,
    private readonly routingService: RoutingService,
  ) {
    super();
  }

  public ngOnChanges(changes: AukSimpleChanges<typeof this>): void {
    if (changes.aukInnerHtml) {
      this.innerHtmlChange$.next();
      this.processHtmlValue();
    }
  }

  private processHtmlValue(): void {
    const processValueSync = (htmlValue: string | Nil): void => {
      const htmlString = this.getUnifiedStringValue(htmlValue);

      if (StringUtils.isEmpty(htmlString)) {
        this.setInnerHtml('');
        return;
      }

      this.setInnerHtml(htmlString);

      this.processAnchors();
    };

    // handle async translation
    if (TranslationUtil.isTranslationSource(this.aukInnerHtml)) {
      this.translateSourcePipeService.transformWithInjector$(this.aukInnerHtml, this.injector)
        .pipe(
          takeUntil(
            merge(
              this.innerHtmlChange$,
              this.ngUnsubscribe,
            ),
          ),
        )
        .subscribe((value) => {
          processValueSync(value);
        });
      return;
    }

    processValueSync(this.aukInnerHtml);
  }

  private getUnifiedStringValue(aukInnerHtml: string | Nil): string {
    if (isNil(aukInnerHtml)) {
      return null;
    }
    if (typeof aukInnerHtml === 'string') {
      return aukInnerHtml;
    }
    return null;
  }

  private setInnerHtml(htmlString: string): void {
    this.elementRef.nativeElement.innerHTML = this.htmlSanitizeService.getSanitizedHtmlString(htmlString, this.allowStrictSanitize);
  }

  private processAnchors(): void {
    if (
      this.platformCommonService.isServer
      || !this.elementRef.nativeElement?.innerHTML?.includes('<a ')
    ) {
      return;
    }

    const anchors = this.elementRef.nativeElement.querySelectorAll('a');

    if (!anchors?.length) {
      return;
    }

    anchors.forEach(anchorElm => {
      this.processAnchorElm(anchorElm);
    });
  }

  private processAnchorElm(anchorElm: HTMLAnchorElement): void {
    const routingInfo = this.routingService.getHrefRoutingResult(
      anchorElm.href,
      anchorElm.target === '_blank',
    );

    // remove attributes if there's no routing info
    if (isNil(routingInfo)) {
      anchorElm.removeAttribute('href');
      anchorElm.removeAttribute('target');
      return;
    }

    // set attributes
    // target can be nil
    if (isNotNil(routingInfo.target)) {
      anchorElm.target = routingInfo.target;
    }
    anchorElm.href = routingInfo.href;

    // create click listener for in-app navigation
    this.routingService.createAnchorClickListener(
      routingInfo,
      anchorElm,
      merge(
        this.innerHtmlChange$,
        this.ngUnsubscribe,
      ),
    );
  }

}
