import { Injectable } from '@angular/core';
import { Params, Router, UrlTree } from '@angular/router';
import omit from 'lodash-es/omit';
import { ItemCategoryDto } from '@api/generated/defs/ItemCategoryDto';
import { PlatformService } from '@shared/platform/service/platform.service';
import { ListingConstants } from '@shared/listing/const/listing.constants';
import { NavigationService } from '@shared/navigation/service/navigation.service';
import { SearchCategoryModel } from '@shared/app-header/module/app-header-search/model/search-category.model';
import { CategoryItemSuggestParams } from '@api/generated/defs/category-item-suggest.params';
import { SubbrandService } from '@shared/subbrand/service/subbrand.service';
import { SubbrandType } from '@shared/subbrand/model/subbrand.type';
import { Nil } from '@util/helper-types/nil';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { takeUntil } from 'rxjs/operators';
import { TranslationSource } from '@common/translations/model/translation-source';
import { SearchCategoryKey } from '@shared/app-header/module/app-header-search/model/search-category.type';
import { isNotNil } from '@util/helper-functions/is-not-nil';
import { CookieService } from '@common/cookie/service/cookie.service';

/**
 * Name of cookie with information if default search should be only within subbrand or across all subbrands.
 */
const SEARCH_ALL_PREFERENCE_COOKIE_NAME: string = 'search-all-preference';

type SearchAllPreference = 'ALL' | 'WITHIN-SUBBRAND';

@Injectable({
  providedIn: 'root',
})
export class AppHeaderSearchService extends NgUnsubscribe {

  public predefinedCategories: Record<SearchCategoryKey, SearchCategoryModel> = {
    all: { catalogKey: 'all', htmlName: { key: 'MY_AUKRO_HEADER_SEARCH_ALL_CATEGORIES' } },
    allsubbrand: { catalogKey: 'allsubbrand', htmlName: null },
    user: { catalogKey: 'user', htmlName: { key: 'MY_AUKRO_HEADER_SEARCH_FIND_USERS' } },
    completed: { catalogKey: 'completed', htmlName: { key: 'MY_AUKRO_HEADER_SEARCH_FINISHED' } },
    seller: null,
  };

  private subbrandSearchNames: Record<SubbrandType, TranslationSource | Nil> = {
    ANTIQUE: { key: 'MY_AUKRO_HEADER_SEARCH_ANTIQUE_NAME' },
    BAZAAR: { key: 'MY_AUKRO_HEADER_SEARCH_BAZAAR_NAME' },
    NOT_SPECIFIED: null,
  };

  constructor(
    private readonly router: Router,
    private readonly navigationService: NavigationService,
    private readonly platformService: PlatformService,
    private readonly subbrandService: SubbrandService,
    private readonly cookieService: CookieService,
  ) {
    super();
    this.setSubbrandAllSearchModelByCurrentSubbrand();
  }

  private setSubbrandAllSearchModelByCurrentSubbrand(): void {
    this.subbrandService.sessionSubbrand$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((currentSubbrand) =>
        this.predefinedCategories.allsubbrand = currentSubbrand != null && currentSubbrand !== 'NOT_SPECIFIED'
          ? { ...this.predefinedCategories.allsubbrand, htmlName: this.subbrandSearchNames[currentSubbrand] }
          : null);
  }

  public saveAllSearchPreferenceToCookie(preference: SearchAllPreference): void {
    if (this.getAllSearchPreferenceCookie() !== preference) {
      this.cookieService.put(SEARCH_ALL_PREFERENCE_COOKIE_NAME, preference);
    }
  }

  public getAllSearchPreferenceCookie(): SearchAllPreference | Nil {
    return this.cookieService.get(SEARCH_ALL_PREFERENCE_COOKIE_NAME) as SearchAllPreference ?? 'WITHIN-SUBBRAND';
  }

  public getAllSearchPreference(): SearchCategoryModel {
    return this.getAllSearchPreferenceCookie() !== 'ALL' && isNotNil(this.predefinedCategories.allsubbrand?.htmlName)
      ? this.predefinedCategories.allsubbrand
      : this.predefinedCategories.all;
  }

  public createSuggestedCategoryItemUrlTree(params: CategoryItemSuggestParams): UrlTree {
    let urlTreeResult: UrlTree;
    let queryParams: Params = { ...params.itemSearchFilter };
    queryParams = this.prepareQueryParams(queryParams);
    if (params.specialFlag) {
      urlTreeResult = this.router.createUrlTree([], { queryParams });
    } else if (params.categorySeoUrl) {
      queryParams = this.removeFromQueryParams(queryParams, 'categoryId');
      urlTreeResult = this.router.createUrlTree(['/', params.categorySeoUrl], { queryParams });
    } else {
      urlTreeResult = this.router.createUrlTree(['vysledky-vyhledavani'], { queryParams });
    }
    return urlTreeResult;
  }

  public removeFromQueryParams(queryParams: Params, param: string): Params {
    const newQueryParams = { ...queryParams };
    delete newQueryParams[param];
    return newQueryParams;
  }

  public prepareQueryParams(
    queryParams: Params,
  ): Params {
    // flags are not needed as the information is already in the URL (eg. /doporucene-nabidky)
    queryParams = this.removeSpecialFlags(queryParams);
    queryParams = this.navigationService.removeEmptyProps(queryParams);
    queryParams = this.removeFromQueryParams(queryParams, 'page');
    queryParams = this.removeFromQueryParams(queryParams, 'splitGroups');
    queryParams = this.removeFromQueryParams(queryParams, ListingConstants.QUERY_PARAM_NAME_FAVOURITE_SEARCH);
    return queryParams;
  }

  public transformCategories(categories: ItemCategoryDto[]): SearchCategoryModel[] {
    return categories.map((category: ItemCategoryDto) =>
      ({ categoryId: category.id, htmlName: { key: null, defaultValue: category.name } }));
  }

  public renderSearchCategories(
    originalTopSeachLevelCategories: SearchCategoryModel[],
    extraCategory?: SearchCategoryModel,
  ): SearchCategoryModel[] {
    const isResponsive: boolean = this.platformService.isMobile || this.platformService.isTablet;
    if (isResponsive) {
      this.predefinedCategories.all.htmlName = { key: 'MY_AUKRO_HEADER_SEARCH_ALL' };
    }

    const separator: SearchCategoryModel = {
      htmlName: { key: null, defaultValue: '─' }, // special unicode char
      disabled: true,
    };

    const beginning = originalTopSeachLevelCategories ?
      [this.predefinedCategories.all, this.predefinedCategories.allsubbrand, separator, ...originalTopSeachLevelCategories] :
      [this.predefinedCategories.all, this.predefinedCategories.allsubbrand];

    const end = [this.predefinedCategories.user, this.predefinedCategories.completed];

    return extraCategory
      ? [...beginning, separator, extraCategory, separator, ...end]
      : [...beginning, separator, ...end]
        .filter((c) => isNotNil(c?.htmlName));
  }

  private removeSpecialFlags(queryParams: Params): Params {
    return omit(queryParams, 'ppMainPage', 'ppCategoryPage', 'specialFlagBasicStartingPrice',
      'specialFlagEndingSoon', 'sellerId', 'hotAuction');
  }

}
