import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { TranslateModule } from '@ngx-translate/core';

import { CommonComponentsModule } from '@shared/legacy/component/common-components.module';
import { CommonDirectivesModule } from '@shared/legacy/directive/common-directives.module';
import { CommonPipesModule } from '@shared/legacy/pipe/common-pipes.module';

import { AvatarComponent } from './avatar.component';
import { DialogChangeAvatarComponent } from './dialog-images/dialog-change-avatar.component';
import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { SvgIconComponent } from '@common/ui-kit/component/svg-icon/component/svg-icon.component';
import { Translate2Module } from '@common/translations/translate2.module';

@NgModule({
  imports: [
    CommonModule,
    CommonComponentsModule,
    CommonDirectivesModule,
    CommonPipesModule,
    MatDialogModule,
    TranslateModule,
    IconComponent,
    MatTooltipModule,
    SvgIconComponent,
    Translate2Module,
  ],
  declarations: [
    AvatarComponent,
    DialogChangeAvatarComponent,
  ],
  exports: [
    AvatarComponent,
  ],
})
export class AvatarModule {
}
