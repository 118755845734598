@if (subbrandsEnabled$ | async) {
  <div
    class="tw-fixed tw-w-full tw-h-full tw-top-safe-top tw-left-0 tw-z-[120] tw-overflow-auto tw-bg-[black]/25"
     [ngClass]="{
         'tw-invisible tw-opacity-0' : (categoryMenuOpened$() | async) === false,
         'tw-opacity-100': categoryMenuOpened$() | async,
         'tw-duration-150': !isLoading}"
    >
    <div class="tw-flex tw-justify-center tw-h-full tw-z-[120] tw-items-center tw-overflow-auto"
      (click)="handleHamburgerDialogClose()">
      <auk-spinner
        [ngClass]="!isLoading ? 'tw-opacity-0'  : 'tw-opacity-100'"
        size="XL"
        [v2]="true"
        >
      </auk-spinner>
    <div [ngClass]="{
         'hamburger-menu-modal-dialog' : isMdAndLower,
         'user-subbrand-center-modal-dialog': !isMdAndLower,
         'tw-opacity-0': isLoading,
         'tw-opacity-100': !isLoading}"
        class="tw-overflow-auto tw-bg-silverstein-50 md:tw-h-full lg:tw-h-auto"
        aukStopPropagationOnClick>
        @if (hasCategoryData) {
          <auk-category-dialog-content
            [categoryDialogModel]="categoryDialogModel"
            [currentSubbrand]="subbrand"
            (subbrandSelected)="subbrandSelected($event)"
            (dialogClose)="handleHamburgerDialogClose()"
            (componentInitialized)="dialogContentInitialized()"
            >
          </auk-category-dialog-content>
        }
      </div>
    </div>
  </div>
}
