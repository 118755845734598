import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DialogWrapperModule } from '@common/dialog-wrapper/dialog-wrapper.module';
import { Translate2Module } from '@common/translations/translate2.module';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { DomainPickerComponent } from './domain-picker.component';
import { DomainPickerDialogComponent } from './domain-picker-dialog/domain-picker-dialog.component';
import { ButtonComponent } from '@common/ui-kit/component/button/component/button.component';
import { IconWithTextComponent } from '@common/ui-kit/component/icon-with-text/component/icon-with-text.component';
import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    IconComponent,
    IconWithTextComponent,
    DialogWrapperModule,
    Translate2Module,
    MatFormFieldModule,
    MatSelectModule,
    ButtonComponent,
  ],
  declarations: [
    DomainPickerComponent,
    DomainPickerDialogComponent,
  ],
  exports: [
    DomainPickerComponent,
  ],
})
export class DomainPickerModule {
}
