import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { defer, EMPTY, mergeMap, Observable, of, retry, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import isNil from 'lodash-es/isNil';
import { USER_ACTION_TYPE } from '@shared/user-action/token/user-action-type-http-context.token';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { captureException, Scope, withScope } from '@sentry/browser';
import { AuthorizationFailedHandlerService } from './authorization-failed-handler.service';
import { Router } from '@angular/router';
import { BaseErrorInterceptor } from './base.error.interceptor';
import { UserActionType } from '@shared/user-action/model/user-action.type';
import { UserActionPrerequisiteModel } from '@shared/user-action/model/user-action-prerequisite.model';
import { ArrayUtils } from '@util/util/array.utils';
import { UserActionPrerequisiteService } from '@shared/user-action/service/user-action-prerequisite.service';
import { FulfillPrerequisiteResultModel } from '@shared/user-action/model/fulfill-prerequisite-result.model';
import { USER_ACTION_PREREQUISITE_FULFILL_FN_ARRAY } from '@shared/user-action/token/user-action-prerequisite-fulfill-fn-array.token';
import { UserActionPrerequisiteCombinationFulfillFnModel } from '@shared/user-action/model/user-action-prerequisite-combination-fulfill-fn.model';
import { AuthenticationService } from '@shared/authentication/service/authentication.service';
import { Nil } from '@util/helper-types/nil';
import { HttpError } from './model/http-error';

@Injectable()
export class Error4xxInterceptor extends BaseErrorInterceptor implements HttpInterceptor {

  protected readonly matcher: RegExp = /4\d\d/;

  constructor(
    private readonly router: Router,
    private readonly authorizationFailedHandler: AuthorizationFailedHandlerService,
    private readonly userActionPrerequisiteService: UserActionPrerequisiteService,
    private readonly authenticationService: AuthenticationService,
  ) {
    super();
  }

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (isNil(req.context.get(USER_ACTION_TYPE))) {
      return this.interceptGenericRequest(req, next);
    }

    return this.interceptUserActionRequest(req, next);
  }

  private interceptUserActionRequest(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let modifiedRequest: Readonly<HttpRequest<unknown>> = req;

    // Each time when all prerequisites are fulfilled, repeated request will be fired
    // Request headers, params and even body could be modified by fulfill prerequisite services
    return of(req)
      .pipe(
        mergeMap(() => defer(() => next.handle(modifiedRequest))
          .pipe(
            filter(e => e.type === HttpEventType.Response),
            retry({
              delay: (error: HttpError) => this.fulfillActionPrerequisites(error, req.context.get(USER_ACTION_TYPE), modifiedRequest)
                .pipe(tap((r: HttpRequest<unknown>) => modifiedRequest = r)),
            }),
          ),
        ),
      );
  }

  private fulfillActionPrerequisites(
    httpError: HttpError,
    actionType: UserActionType,
    httpRequest: HttpRequest<unknown>,
  ): Observable<HttpRequest<unknown>> {
    const prerequisites: UserActionPrerequisiteModel[] = httpError?.prerequisites;

    if (!this.shouldIntercept(httpError)) {
      return throwError(() => httpError);
    }

    if (ArrayUtils.isEmpty(prerequisites)) {
      // Handle other 403 errors if prerequisites not present in HttpError
      if (httpError.code === 403) {
        this.authorizationFailedHandler.handleAuthorizationFailed(httpError);
      }
      return throwError(() => httpError);
    }

    const customFulfillFns: UserActionPrerequisiteCombinationFulfillFnModel[] =
      httpRequest.context.get(USER_ACTION_PREREQUISITE_FULFILL_FN_ARRAY);

    return this.userActionPrerequisiteService.fulfillPrerequisites(prerequisites, actionType, customFulfillFns)
      .pipe(
        catchError((e) => {
          console.warn(e);
          return throwError(() => httpError);
        }),
        map((result: FulfillPrerequisiteResultModel | Nil) => httpRequest.clone(result?.requestUpdates)),
      );
  }

  private interceptGenericRequest(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req)
      .pipe(
        catchError((err: HttpError) => {
          if (!this.shouldIntercept(err)) {
            return throwError(() => err);
          }

          if (err.code === 401 && !this.authenticationService.isOnLoginPage()) {
            this.log401error(err.url, err.body?.message);
            this.redirectToLoginPage();
            return EMPTY;
          }

          // Because of PDEV-11029, no permission returns 403
          if (err.code === 403) {
            this.authorizationFailedHandler.handleAuthorizationFailed(err);
          }

          return throwError(() => err);
        }),
      );
  }

  private redirectToLoginPage(): void {
    void this.authenticationService.redirectToLoginPage({ queryParams: { returnUrl: this.router.url } });
  }

  private log401error(url: string, responseMessage: string): void {
    withScope((scope: Scope) => {
      scope.setExtra('extra', { url, responseMessage });
      captureException('401 error from the API - redirecting to login page and reseting user info');
    });
  }

}
