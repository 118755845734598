@if (showSearch) {
  <auk-icon
    (click)="buttonClicked('SEARCH')"
    class="tw-cursor-pointer tw-z-[3] tw-px-[2vw] tw-py-3 !tw-tw-flex tw-items-center"
    iconType="SVG"
    source="search-new"
    size="XMD"
    colorCombination="CONTRAST_OPPOSITE"
  ></auk-icon>
}

<auk-account-control class="nav tw-flex tw-items-center tw-min-w-[38px]" [user]="user" (click)="onUserInfoClick()"></auk-account-control>

@if (user) {
  <auk-announcements-control class="nav tw-flex tw-items-center header-icon lg:tw-mx-0.5" [user]="user"></auk-announcements-control>
  <auk-inbox-control class="nav tw-flex tw-items-center header-icon lg:tw-mx-0.5"></auk-inbox-control>
  <auk-favourites-control aukPopoverToggle
    class="nav tw-flex tw-items-center header-icon lg:tw-mx-0.5"
    [user]="user">
  </auk-favourites-control>
}

@if (showCart) {
  <auk-basket-control class="nav tw-flex tw-items-center header-icon lg:tw-mx-0.5"></auk-basket-control>
}

<div
  #menuToggleBtnElm
  class="nav last tw-flex tw-items-center hidden-tablet-and-more-bigger"
  [class.active]="isMenuOpened"
  (click)="toggleNavigationMenu()"
  >
  <span class="material-icons big pointer-events-none">
    {{ this.isMenuOpened ? 'close' : 'menu' }}
  </span>
</div>

<auk-cart-notification></auk-cart-notification>
