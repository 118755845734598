import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import isNil from 'lodash-es/isNil';
import moment, { Moment } from 'moment-mini-ts';
import { BehaviorSubject, defer, Observable, of, Subscription, throwError } from 'rxjs';
import { mergeMap, retryWhen, skip, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ConfigElementValueDto } from '@api/generated/defs/ConfigElementValueDto';
import { ConfiguratorCacheService } from '@shared/services/configurator-cache/configurator-cache.service';
import { TfvErrorCodeEnum } from './tvf-error-code.enum';
import { TwoFactorVerificationHelper } from './two-factor-verification.helper';
import { StringUtils } from '@util/util/string.utils';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { I18nPluralPipe } from '@angular/common';
import { TimeUtil } from '@common/ui-kit/common/time/class/time.util';
import { WINDOW_OBJECT } from '@util/const/window-object';
import { HttpError } from '@shared/rest/model/http-error';
import { DomainService } from '@shared/platform/domain.service';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { Nil } from '@util/helper-types/nil';

export type RequestWithHeadersCallback<T, P = null> = (params: P, headers: { [key: string]: string }) => Observable<T>;

/**
 * T - response type
 * P - params type
 */
@Component({
  selector: 'auk-two-factor-verification',
  templateUrl: './two-factor-verification.component.html',
  styleUrls: ['./two-factor-verification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TwoFactorVerificationComponent<T, P> extends NgUnsubscribe implements OnInit, OnDestroy {

  /**
   * Request method from generated services
   */
  @Input()
  public requestCallback: RequestWithHeadersCallback<T, P>;

  @Input()
  public verificationInitiallyRequired: boolean = false;

  /**
   * Whether TFV should be shown in compact inline layout
   */
  @Input()
  public inline: boolean = false;

  @Input()
  public showGoBackButton: boolean = true;

  @Input()
  public showSkipButton: boolean = false;

  @Output()
  public goBackClicked: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public skipClicked: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public onSubmit: EventEmitter<void> = new EventEmitter<void>();

  public verificationRequired: boolean = false;

  /**
   * Emits filled code when code should be submit
   */
  public submitCode: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public isSubmitting: boolean = false;

  public VERIFICATION_CODE_LENGTH: number = 4;

  public tfvErrorMessageCode: TfvErrorCodeEnum = null;

  /**
   * True if there is some error and the user has not changed the code yet
   */
  public hasErrorWithUnchangedCode: boolean = false;

  /**
   * Represents current value of TFV input
   */
  public currentValue: string = '';

  public readonly TfvErrorMessageCodeEnum = TfvErrorCodeEnum;

  /**
   * TRUE if code has been at least once submitted
   */
  private submitted: boolean = false;

  /**
   * Codes of TFV errors which could be fixed immediately by user
   */
  private readonly TFV_SOFT_ERROR_CODES = [
    TfvErrorCodeEnum.SEND,
    TfvErrorCodeEnum.EXPIRED,
    TfvErrorCodeEnum.INVALID,
  ];

  public resendDisabled: boolean = true;

  private resendCountdownIntervalSub: Subscription | Nil = null;

  public resendCountDownText: string;

  private resendDelayInSeconds: number;

  constructor(
    @Inject(WINDOW_OBJECT) private window: Window,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly configuratorCacheService: ConfiguratorCacheService,
    private readonly i18nPluralPipe: I18nPluralPipe,
    private readonly domainService: DomainService,
    private readonly ngZoneUtilService: NgZoneUtilService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.verificationRequired = this.verificationInitiallyRequired;
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.resendCountdownStop();
  }

  /**
   * Creates observable request with TFV header using request callback
   * Processed possible TFV errors and returns response stream
   * Response stream is unchanged, thus throws all HTTP errors except TFV errors
   * All TFV errors are handled inside and not present in returned stream
   * @param params - parameters which will be passed to callback method
   * @returns Observable<T> - created by callback method
   */
  public sendTfvWrappedRequest(params: P): Observable<T> {
    this.configuratorCacheService.systemParametersFE(['SMS_VERIFICATION_RESEND_DELAY'])
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((configElements: ConfigElementValueDto[]) => {
        this.resendDelayInSeconds = StringUtils.parseNumber(configElements[0]?.value);
        if (this.resendDelayInSeconds) {
          this.resendCountdownStart(this.resendDelayInSeconds);
        } else {
          this.resendDisabled = false;
          this.changeDetectorRef.detectChanges();
        }
      });

    return of(void 0)
      .pipe(
        mergeMap(() => {
          // Wait for the first code submit if verification is required from init
          // Prevents double request sending
          if (this.verificationInitiallyRequired) {
            return this.submitCode.pipe(skip(1), take(1));
          }
          return of(void 0);
        }),
        switchMap(() => defer(() => {
          const headers: { [key: string]: string } = {
            // Defer original request and add TFV header to it
            // First request must contain empty TFV header
            [TwoFactorVerificationHelper.TFV_HEADER]: this.submitCode.value,
          };
          return this.requestCallback(params, headers);
        })
          .pipe(
            // Repeat request until TFV succeed
            retryWhen((errors: Observable<HttpError>) => errors
              .pipe(
                tap(() => {
                  this.isSubmitting = false;
                  this.changeDetectorRef.markForCheck();
                }),
                switchMap((httpError: HttpError) => {
                  if (!TwoFactorVerificationHelper.isTwoFactorVerificationError(httpError)) {
                    return throwError(httpError);
                  }

                  this.tfvErrorMessageCode = TwoFactorVerificationHelper.getTfvErrorCode(httpError);
                  this.hasErrorWithUnchangedCode = true;
                  // Reset submitted flag so form will submit on code change
                  this.submitted = false;

                  this.verificationRequired = true;
                  this.changeDetectorRef.markForCheck();

                  // Skip initial empty value
                  return this.submitCode
                    .pipe(skip(1), take(1));
                }),
                tap(() => {
                  this.isSubmitting = true;
                  this.changeDetectorRef.markForCheck();
                }),
              ),
            ),
            tap(() => {
              this.verificationRequired = false;
              this.submitCode.next('');
              this.changeDetectorRef.markForCheck();
            }),
            takeUntil(this.goBackClicked),
          )),
      );
  }

  /**
   * Submits verification when code has full length
   * Submits automatically only once
   * @param value - current value of TFV input
   */
  public onCodeInput(value: string): void {
    this.currentValue = value;

    // Reset error status on code change
    // User is allowed to submit code again after make some change
    this.hasErrorWithUnchangedCode = false;

    if (StringUtils.isBlank(value) || !this.hasValidLength(value)) {
      return;
    }

    if (this.submitted) {
      return;
    }

    if (this.submitCode.value === value) {
      return;
    }

    this.onSubmit.emit();
    this.submit(value);
  }

  public submitManually(): void {
    const value: string = this.currentValue;

    if (!this.hasValidLength(value)) {
      return;
    }

    this.onSubmit.emit();
    this.submit(value);
  }

  /**
   * Calls resend code by send request again with empty header
   */
  public resendCode(): void {
    if (!this.resendDisabled) {
      this.submit('');
      this.resendCountdownStart(this.resendDelayInSeconds);
    }
  }

  public onGoBackClick(): void {
    this.currentValue = '';
    this.verificationRequired = false;
    this.submitCode.next('');
    this.goBackClicked.emit();
  }

  public onSkipClick(): void {
    this.skipClicked.emit();
  }

  /**
   * @returns whether verification has totally failed
   */
  public get isTotallyFailed(): boolean {
    if (isNil(this.tfvErrorMessageCode)) {
      return false;
    }

    // All other errors except soft ones means that verification has totally failed
    return !this.TFV_SOFT_ERROR_CODES.includes(this.tfvErrorMessageCode);
  }

  /**
   * Submits request again with Two Factor Verification
   * @param tvfCode - Two Factor Verification code
   */
  private submit(tvfCode: string): void {
    if (this.isSubmitting) {
      return;
    }

    this.submitted = true;

    this.submitCode.next(tvfCode);
  }

  public hasValidLength(tfvCode: string): boolean {
    return tfvCode?.length === this.VERIFICATION_CODE_LENGTH;
  }

  private resendCountdownStart(resendDelay: number): void {
    this.resendDisabled = true;
    const endingTime: Moment = moment().add(resendDelay, 'seconds');
    const update = (): void => {
      const diff: number = endingTime.diff();
      if (diff > 0) {
        const duration = moment.duration(diff);
        this.resendCountDownText = TimeUtil.getHumanReadableRemainingTime(duration, this.i18nPluralPipe, this.domainService.lang);
      } else {
        this.resendDisabled = false;
        this.resendCountdownStop();
      }
      this.changeDetectorRef.detectChanges();
    };
    if (!this.resendCountdownIntervalSub) {
      update();
      this.resendCountdownIntervalSub = this.ngZoneUtilService.intervalOut$(1000)
        .pipe(
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe(() => {
          update();
        });
    }
  }

  private resendCountdownStop(): void {
    if (this.resendCountdownIntervalSub) {
      this.resendCountdownIntervalSub.unsubscribe();
      this.resendCountdownIntervalSub = null;
    }
  }

}
