import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeCs from '@angular/common/locales/cs';
import { NgModule } from '@angular/core';
import { MissingTranslationHandler, TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CookieModule } from 'ngx-cookie';
import { ConfiguratorCacheService } from '@shared/services/configurator-cache/configurator-cache.service';
import { MissingTranslationsHandler } from '@common/translations/domain/missing-translations.handler';
import { TranslationsLoader } from '@common/translations/domain/translations.loader';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { TranslateMessageFormatCompilerService } from '@common/translations/message-format/translate-message-format-compiler.service';
import { UserActionModule } from '@shared/user-action/user-action.module';
import { ToastModule } from '@common/toast/toast.module';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { DomainService } from '@shared/platform/domain.service';
import { TranslationCacheService } from '@shared/services/translation-cache/translation-cache.service';

registerLocaleData(localeCs);

/**
 * @deprecated
 * DO NOT ADD NEW THINGS HERE
 * instead add them to correct places:
 *  Imports into AppComponent
 *  Providers into {@link APP_CONFIG} or {@link APP_BROWSER_CONFIG} or {@link APP_SERVER_CONFIG}
 *
 * Shared part of the app between browser and server.
 */
@NgModule({
  imports: [
    TranslateModule.forRoot({
      //Remove when Service for Language Select is Ready
      loader: {
        provide: TranslateLoader,
        useFactory: (
          translationCacheService: TranslationCacheService,
          domainService: DomainService,
        ) => new TranslationsLoader(translationCacheService, domainService),
        deps: [TranslationCacheService, DomainService],
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompilerService,
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MissingTranslationsHandler,
        deps: [ConfiguratorCacheService],
      },
    }),
    CookieModule.withOptions(),
    HttpClientModule,
    MatSnackBarModule,
    UserActionModule,
    ToastModule,
    MatBottomSheetModule,
  ],
})
export class AppModule {
}
