import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { InitBasicDataParams, UserApiService } from '@api/generated/api/User';
import { PhoneParams, UserMeService } from '@api/generated/api/UserMe';
import {
  AccountDetailDto,
  ActivationChannelEnumAccountStateDtoReq,
  ActivationProcessStatusEnumAccountStateDtoReq,
  AddressDtoRes,
  ChangeEmailDto,
  ChangeLoginDto,
  CompanyAccountDetailDto,
  JWTInfo,
  NewUserProfileAllDto,
  NewUserProfileBaseDto,
  SellerInfoDto,
  UpdateCompanyAccountDetailDto,
  UserAccountDetailDto,
  UserInterestStatisticsDto,
  UserProfileDto,
  UserProfileStatisticsDto,
  UserShowNameDto,
} from '@api/generated/model';
import { CacheAware } from '@common/cache/model/cache-aware';
import { CacheService } from '@common/cache/service/cache.service';
import { AuthenticationService } from '@shared/authentication/service/authentication.service';
import {
  actualStatisticsCacheBuster$,
  actualUserProfileStatisticsCacheBuster$,
  userEmailInfoCacheBuster$,
  userProfileCacheBuster$,
} from '../constant/cache-busters';
import isNil from 'lodash-es/isNil';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { UserActualStatisticsService } from './user-actual-statistics.service';
import { Cacheable } from '@common/cache/decorator/cacheable';
import { Nil } from '@util/helper-types/nil';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { DateUtils } from '@util/util/date.utils';

@Injectable({
  providedIn: 'root',
})
export class UserService extends NgUnsubscribe implements CacheAware {

  private readonly userProfileDataRefreshIntervalMs: number = DateUtils.convertMinutesToMilliseconds(5);

  constructor(
    public readonly cacheService: CacheService,
    private readonly authenticationService: AuthenticationService,
    private readonly platformCommonService: PlatformCommonService,
    private readonly userApiService: UserApiService,
    private readonly userMeService: UserMeService,
    private readonly userActualStatisticsService: UserActualStatisticsService,
    private readonly ngZoneUtilService: NgZoneUtilService,
  ) {
    super();

    if (this.platformCommonService.isServer) {
      return;
    }

    // refresh user profile data
    // TODO: PDEV-12794
    this.ngZoneUtilService.intervalOut$(this.userProfileDataRefreshIntervalMs)
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        actualUserProfileStatisticsCacheBuster$.next();
      });
  }

  public getActualUserProfileStatistics(ignoreCache: boolean = false): Observable<UserProfileStatisticsDto | Nil> {
    if (!this.authenticationService.isLoggedIn()) {
      return of(undefined);
    }

    if (ignoreCache) {
      return this.userApiService.userProfileStatistics();
    } else {
      return this.loadUserProfileStatistics();
    }
  }

  @Cacheable({
    cacheBuster: actualUserProfileStatisticsCacheBuster$,
    key: 'UserService#loadUserProfileStatistics',
  })
  private loadUserProfileStatistics(): Observable<UserProfileStatisticsDto> {
    return this.userApiService.userProfileStatistics();
  }

  /**
   * Returns user info if is logged in, otherwise returns null
   * @param ignoreCache - whether cache should be ignored and API call should be made
   */
  public getActualStatistics(ignoreCache: boolean = false): Observable<UserInterestStatisticsDto | Nil> {
    if (this.authenticationService.isLoggedIn()) {
      if (ignoreCache) {
        return this.userActualStatisticsService.loadActualStatisticsIgnoringCache();
      } else {
        return this.userActualStatisticsService.loadActualStatistics();
      }
    }

    return of(null);
  }

  @Cacheable({
    cacheBuster: userProfileCacheBuster$,
    key: 'UserService#getCurrentUserProfileMinimal',
  })
  public getCurrentUserProfileMinimal(): Observable<NewUserProfileBaseDto> {
    return this.getActualStatistics()
      .pipe(
        mergeMap((stats: UserInterestStatisticsDto) => stats
          ? this.getUserProfileMinimalById(stats.userId)
          : of(null)));
  }

  public getUserProfileMinimalById(userId: number): Observable<NewUserProfileBaseDto> {

    if (isNil(userId)) {
      return of(null);
    }

    return this.userApiService.getUserProfileByIdMinimalUsingGET({ id: userId });
  }

  @Cacheable({
    cacheBuster: userProfileCacheBuster$,
    key: 'UserService#getCurrentUserProfileFull',
  })
  public getCurrentUserProfileFull(): Observable<UserProfileDto> {
    return this.getActualStatistics()
      .pipe(
        mergeMap((stats: UserInterestStatisticsDto) =>
          stats
            ? this.userApiService.getUserProfileUsingGET({ id: stats.userId })
            : of(null)));
  }

  @Cacheable({
    cacheBuster: userEmailInfoCacheBuster$,
    key: 'UserService#getCurrentUserEmailInfo',
  })
  public getCurrentUserEmailInfo(): Observable<ChangeEmailDto> {
    return this.getActualStatistics()
      .pipe(
        mergeMap((stats: UserInterestStatisticsDto) =>
          stats
            ? this.userApiService.getEmailInfo({ id: stats.userId })
            : of(null)));
  }

  public getCurrentUserRegistrationDomain(): Observable<string> {
    return this.getActualStatistics()
      .pipe(map((stats: UserInterestStatisticsDto) => (stats ? stats.registrationDomainCode : 'CZ')));
  }

  public resetCurrentUser(): void {
    this.authenticationService.resetCurrentUser();
  }

  public isCurrentlyLoggedUser(userId: number): Observable<boolean> {
    return this.authenticationService.getLoginStatusChangeWithStartValue()
      .pipe(
        map(() => this.authenticationService.currentLoggedUserId),
        distinctUntilChanged(),
        map((currentLoggedUserId) => userId === currentLoggedUserId),
      );
  }

  public isSellerInfoFilled(sellerInfo: SellerInfoDto): boolean {
    if (!sellerInfo) {
      return false;
    }
    let isFilled = false;
    Object.keys(sellerInfo).forEach((key) => {
      if (sellerInfo[key] && typeof sellerInfo[key] === 'string' && sellerInfo[key] !== '') {
        isFilled = true;
      }
    });
    if (sellerInfo.hallmarkVisible) {
      isFilled = true;
    }
    return isFilled;
  }

  /**
   * Users who have verified account by MAIL or CARD cant verify account by CARD during change to company process
   * @param accountDetail
   */
  public hasChangingUserToCompanyEnabledCardVerification(accountDetail: AccountDetailDto): boolean {
    if (accountDetail.stateDto.changingToCompany &&
      this.isUserVerifiedBySpecificMethods(['CARD', 'MAIL'], accountDetail) &&
      (['STARTED', 'NO_ACTIVATION'] as ActivationProcessStatusEnumAccountStateDtoReq[])
        .includes(accountDetail.stateDto.activationProcessStatusEnum)) {
      return false;
    }
    return true;
  }

  /**
   * Check if user has verified by provided ActivationChannelEnumAccountStateDto list
   * @param methods
   * @param accountDetail
   * @returns boolean user is verified by specific methods
   */
  public isUserVerifiedBySpecificMethods(methods: ActivationChannelEnumAccountStateDtoReq[], accountDetail: AccountDetailDto): boolean {
    return methods.includes(accountDetail.stateDto.activationChannelEnum);
  }

  /**
   * Updates current user's phone
   * ActualStatistics cache is invalidated on success because field phoneVerificationRequired of actualStatistics could change
   * @param params - update phone params
   * @param headers - HTTP headers
   * @returns - API call source
   */
  public updatePhone(params: PhoneParams, headers: { [key: string]: string } = {}): Observable<void> {
    return this.userMeService.phone(params, headers)
      .pipe(
        tap(() => actualStatisticsCacheBuster$.next()),
      );
  }

  public detail(userId: number): Observable<AccountDetailDto> {
    if (isNil(userId)) {
      return of(null);
    }

    return this.userApiService.detail({ id: userId });
  }

  public resendActivationEmail(userId: number): Observable<number> {
    if (isNil(userId)) {
      return of(null);
    }

    return this.userApiService.resendActivationEmail({ id: userId });
  }

  public address(userId: number): Observable<AddressDtoRes | Nil> {
    if (isNil(userId)) {
      return of(null);
    }

    return this.userApiService.address({ id: userId });
  }

  public showName(suggestText: string): Observable<UserShowNameDto[]> {
    if (isNil(suggestText)) {
      return of(null);
    }

    return this.userApiService.showName({ text: suggestText });
  }

  public getLoginInfo(userId: number): Observable<ChangeLoginDto> {
    if (isNil(userId)) {
      return of(null);
    }

    return this.userApiService.getLoginInfo({ id: userId });
  }

  public private(userId: number): Observable<CompanyAccountDetailDto> {
    if (isNil(userId)) {
      return of(null);
    }

    return this.userApiService.private({ id: userId });
  }

  public initBasicData(params: InitBasicDataParams): Observable<JWTInfo> {
    if (isNil(params)) {
      return of(null);
    }

    return this.userApiService.initBasicData(params);
  }

  public getSellerInfo(userId: number): Observable<SellerInfoDto> {
    if (isNil(userId)) {
      return of(null);
    }

    return this.userApiService.getSellerInfoUsingGET({ userId });
  }

  public getEmailInfo(userId: number): Observable<ChangeEmailDto> {
    if (isNil(userId)) {
      return of(null);
    }

    return this.userApiService.getEmailInfo({ id: userId });
  }

  public updateLoginInfo(userId: number, changeLoginDto: ChangeLoginDto): Observable<ChangeLoginDto> {
    if (isNil(userId) || isNil(changeLoginDto)) {
      return of(null);
    }

    return this.userApiService.updateLoginInfo({ id: userId, changeLoginDto });
  }

  public updateUserDetail(userId: number, userAccountDetailDto: UserAccountDetailDto): Observable<void> {
    if (isNil(userId) || isNil(userAccountDetailDto)) {
      return of(null);
    }

    return this.userApiService.updateUserDetail({ id: userId, userAccountDetailDto });
  }

  public updateCompanyDetail(userId: number, updateCompanyAccountDetailDto: UpdateCompanyAccountDetailDto): Observable<void> {
    if (isNil(userId) || isNil(updateCompanyAccountDetailDto)) {
      return of(null);
    }

    return this.userApiService.updateCompanyDetail({ id: userId, updateCompanyAccountDetailDto });
  }

  public updateSellerInfo(sellerInfoDto: SellerInfoDto): Observable<SellerInfoDto> {
    if (isNil(sellerInfoDto)) {
      return of(null);
    }

    return this.userApiService.updateSellerInfoUsingPOST({ sellerInfoDto });
  }

  public getUserProfileByUsernameAll(username: string): Observable<NewUserProfileAllDto> {
    if (isNil(username)) {
      return of(null);
    }

    return this.userApiService.getUserProfileByUsernameAllUsingGET({ username });
  }

}

