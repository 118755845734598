@if ((priceModel?.price | currency); as price) {
  <div
    class="tw-flex tw-space-x-1 tw-items-baseline tw-font-bold !tw-leading-custom1"
    [ngClass]="getPriceSizeClass(price)"
    >
    @if (priceModel.withBadge && !itemHasEnded) {
      <auk-icon
        colorCombination="PRIMARY_CONTRAST"
        source="shield-check2"
        iconType="SVG"
        [size]="getPriceIconSize"
        [matTooltip]="'ITEM_CARD_MONEY_BACK_GUARANTEE' | translate"
        matTooltipClass="tooltip-lg"
        matTooltipPosition="above"
        [matTooltipShowDelay]="250"
        class="tw-my-auto"
      ></auk-icon>
    }
    <span>{{ price }}</span>
  </div>
}
