import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { combineLatest, merge, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, finalize, map, mergeMap, startWith, takeUntil, tap } from 'rxjs/operators';
import { ConfigElementValueDto } from '@api/generated/defs/ConfigElementValueDto';
import { UserInterestStatisticsDto } from '@api/generated/defs/UserInterestStatisticsDto';
import { RoutesService } from '@shared/services/app/routes.service';
import { AuthenticationService } from '@shared/authentication/service/authentication.service';
import { ConfiguratorCacheService } from '@shared/services/configurator-cache/configurator-cache.service';
import { PlatformService } from '@shared/platform/service/platform.service';
import { actualStatisticsCacheBuster$ } from '@shared/user/constant/cache-busters';
import { UserService } from '@shared/user/service/user.service';
import { BrowserService } from '@shared/platform/browser.service';
import { AppHeaderService, ModalPanelState } from '../../service/app-header.service';
import { ResponsivenessService } from '@common/responsiveness/service/responsiveness.service';
import { SkeletonLoadingService } from '@shared/skeleton-loading/service/skeleton-loading.service';
import { WINDOW_OBJECT } from '@util/const/window-object';
import { BannerPlacementDto } from '@api/generated/defs/BannerPlacementDto';
import { Nil } from '@util/helper-types/nil';
import isNil from 'lodash-es/isNil';
import { WithSubbrandData } from '@shared/subbrand/util/with-subbrand.data';
import { SubbrandUtil } from '@shared/subbrand/util/subbrand.util';
import { SubbrandType } from '@shared/subbrand/model/subbrand.type';
import { SubbrandService } from '@shared/subbrand/service/subbrand.service';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { HeaderNavbarContainerModel } from '../horizontal-header-navbar/model/header-navbar-container.model';
import { trackByIdFn } from '@util/helper-functions/track-by/track-by-id-fn';
import { HeaderNavbarModel } from '../horizontal-header-navbar/model/header-navbar.model';
import { HorizontalMenuSubbrandModel } from './model/horizontal-menu-subbrand.model';
import { HorizontalMenuSubbrandTabGroupModel } from './model/horizontal-menu-subbrand-tab-group.model';
import { AppHeaderHamburgerService } from '@shared/app-header/service/app-header-hamburger.service';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { captureException } from '@sentry/browser';

@Component({
  selector: 'auk-app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppHeaderComponent extends NgUnsubscribe implements OnInit, OnDestroy {

  @Input() public showGlobalGoBackButton: boolean = false;
  @Input() public notHideGlobalGoBackButton: boolean = false;
  @Input() public menuToggleBtnYPosition: number = 0;

  @Output() public dummySearchChange = new EventEmitter<ElementRef<HTMLInputElement>>();
  @Output() public openSuggestionsDropdown = new EventEmitter<void>();

  @ViewChild('headerSearchContainerPlaceholder') private readonly appHeaderSearchContainerPlaceholderCmp: ElementRef<HTMLDivElement>;

  protected trackByOptionIdFn = trackByIdFn;
  protected hideHeaderSearchBar: boolean = false;
  protected isMdAndLower: boolean = false;
  protected isLgAndLower: boolean = false;
  protected isMyAukroOpened: boolean = false;
  protected user: UserInterestStatisticsDto;
  protected isTablet: boolean = false;
  protected isScrolledUp: boolean = true;
  protected displaySkeletonsOnTablet: boolean = false;
  protected displaySkeletonsOnMobile: boolean = false;
  protected displaySkeletons: boolean = false;
  protected modalPanelState: ModalPanelState;
  protected horizontalMenuSubbrand: HeaderNavbarContainerModel | Nil;
  protected bannerPlacement: BannerPlacementDto;
  protected isSubbrandsEnabled: boolean = false;
  protected searchOpened: boolean = false;
  protected stickyHeader: boolean = false;
  protected dummySearchInput: ElementRef<HTMLInputElement>;

  private verticalMenuThrottleSubscription: Subscription;
  private headerSizeChangeObserver: ResizeObserver | Nil;

  constructor(
    @Inject(WINDOW_OBJECT) private readonly window: Window,
    private readonly authenticationService: AuthenticationService,
    private readonly appHeaderService: AppHeaderService,
    private readonly appHeaderHamburgerService: AppHeaderHamburgerService,
    private readonly browserService: BrowserService,
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly configuratorCacheService: ConfiguratorCacheService,
    private readonly platformService: PlatformService,
    private readonly routesService: RoutesService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly skeletonLoadingService: SkeletonLoadingService,
    private readonly subbrandService: SubbrandService,
    private readonly responsivenessService: ResponsivenessService,
    private readonly ngZoneUtilService: NgZoneUtilService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.isTablet = this.platformService.isTablet;
    this.initHideHeaderSearchBarListener();
    this.initTopLineBannerListener();
    this.onWindowScroll();

    this.prepareHorizontalMenuData();

    this.subbrandService.subbrandsEnabled$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((isSubbrandsEnabled) => {
        this.isSubbrandsEnabled = isSubbrandsEnabled;
        this.changeDetectorRef.detectChanges();
      });

    combineLatest([
      this.skeletonLoadingService.displaySkeletonsOnMobile$,
      this.skeletonLoadingService.displaySkeletonsOnTablet$,
      this.skeletonLoadingService.displaySkeletons$,
    ])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([mobile, tablet, desktop]: [boolean, boolean, boolean]) => {
        this.displaySkeletonsOnMobile = mobile;
        this.displaySkeletonsOnTablet = tablet;
        this.displaySkeletons = desktop && !mobile;
        this.changeDetectorRef.detectChanges();
      });

    this.initNavigationListener();

    merge(
      actualStatisticsCacheBuster$,
      this.authenticationService.getLoginStatusChange(),
    )
      .pipe(
        startWith(null),
        filter((data: boolean) => {
          if (this.authenticationService.isLoggedIn() || data === undefined) {
            return true;
          } else {
            if (data !== null) {
              this.user = null;
              this.changeDetectorRef.detectChanges();
            }
            return false;
          }
        }),
        mergeMap(() => this.userService.getActualStatistics()),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((data: UserInterestStatisticsDto) => {
        this.user = data;
        this.changeDetectorRef.detectChanges();
      });

    this.platformService.isMobile$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((isMobile: boolean) => {
        if (!isMobile) {
          this.hideHeaderSearchBar = false;
        }
        this.changeDetectorRef.detectChanges();
      });

    this.platformService.isTablet$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((isTablet: boolean) => {
        if (!isTablet) {
          this.hideHeaderSearchBar = false;
        }
        this.isTablet = isTablet;
        this.changeDetectorRef.markForCheck();
      });

    this.appHeaderService.getModalPanelStateChange()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((state: ModalPanelState) => {
        if (this.isTablet && state) {
          this.hideHeaderSearchBar = false;
        }
        this.modalPanelState = state;
        this.changeDetectorRef.markForCheck();
      });

    this.initIsMyAukroListener();

    this.responsivenessService.isMdAndLower$
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((isMdAndLower) => {
        this.isMdAndLower = isMdAndLower;
      });

    this.responsivenessService.isLgAndLower$
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((isLgAndLower) => this.isLgAndLower = isLgAndLower);
  }

  @HostListener('window:scroll', [])
  protected onWindowScroll(): void {
    if (!this.appHeaderSearchContainerPlaceholderCmp?.nativeElement || !this.isMdAndLower) {
      if (this.stickyHeader) {
        this.stickyHeader = false;
      }
      return;
    }

    const offset = this.appHeaderSearchContainerPlaceholderCmp.nativeElement.offsetTop;
    const scrollPosition = window.scrollY;

    if (scrollPosition > offset) {
      this.stickyHeader = true;
    } else {
      this.stickyHeader = false;
    }
  }

  protected setDummySearchInput(ref: ElementRef<HTMLInputElement>): void {
    this.dummySearchInput = ref;
    this.dummySearchChange.emit(ref);
  }

  private prepareHorizontalMenuData(): void {
    combineLatest([
      this.configuratorCacheService.menuLayoutsWithSubbrand(['HORIZONTAL_MENU']),
      this.subbrandService.sessionSubbrand$,
      this.subbrandService.subbrandsEnabled$,
    ])
      .pipe(
        map(([data, sessionSubbrand, isSubbrandsEnabled]) => [data[0], sessionSubbrand, isSubbrandsEnabled]),
        finalize(() => {
          this.changeDetectorRef.markForCheck();
        }),
      )
      .subscribe({
        next: ([data, sessionSubbrand]: [WithSubbrandData<ConfigElementValueDto>, SubbrandType, boolean]) => {
          const horizontalMenuJSON = SubbrandUtil.getBySubbrand(data, sessionSubbrand)?.value;
          if (isNil(horizontalMenuJSON)) {
            return;
          }

          let horizontalMenu = null;
          try {
            horizontalMenu = JSON.parse(horizontalMenuJSON) as HorizontalMenuSubbrandModel;
          } catch (err) {
            captureException(new Error('Cannot parse horizontal menu JSON'));
            return;
          }

          const tabGroupLeft = horizontalMenu?.tabGroupLeft?.map(tab => this.convertHorizontalMenuLabel(tab));
          const tabGroupRight = horizontalMenu?.tabGroupRight?.map(tab => this.convertHorizontalMenuLabel(tab));
          this.horizontalMenuSubbrand = { tabGroupLeft, tabGroupRight };
          this.changeDetectorRef.detectChanges();
        },
      });
  }

  private convertHorizontalMenuLabel(tab: HorizontalMenuSubbrandTabGroupModel): HeaderNavbarModel {
    return {
      ...tab,
      label: { defaultValue: tab?.label },
      data: {
        operation: tab.onClickOperation ?? 'openUrl',
        link: tab.url,
        subbrand: tab.subbrand as SubbrandType,
        moCode: tab.moCode,
      },
      tooltip: { defaultValue: tab.tooltip },
      canvasMenu: {
        ...tab.canvasMenu,
        items: tab.canvasMenu?.items.map(canvasItem => ({
          ...canvasItem,
          label: { defaultValue: canvasItem?.label },
          items: canvasItem?.items.map(item => ({
            ...item,
            label: { defaultValue: item?.label },
          })),
        })),
      },
    };
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.destroyVerticalMenuThrottle();
    this.headerSizeChangeObserver?.disconnect();
  }

  protected openDropdown(): void {
    this.openSuggestionsDropdown.emit();
  }

  protected categoryMenuOpened$(): Observable<boolean> {
    return this.appHeaderHamburgerService.categoryMenuOpened$();
  }

  protected get showGoBackButton(): boolean {
    return this.showGlobalGoBackButton && (!this.isScrolledUp || this.notHideGlobalGoBackButton);
  }

  protected get isHomepage(): boolean {
    return !this.isTablet && this.router.url.split('?')[0] === '/'; // HomepageComponent route (ignores query params)
  }

  /** Inits hiding header search bar on scroll in tablet width. */
  private initHideHeaderSearchBarListener(): void {
    this.ngZoneUtilService.fromEventOut$(this.window, 'scroll')
      .pipe(
        startWith(null), // Check current scroll position on app start.
        filter(() => {
          // Do not hide header on desktop or when search is opened.
          if (!this.isTablet || this.searchOpened || this.modalPanelState) {
            this.hideHeaderSearchBar = false;
            return false;
          } else {
            return true;
          }
        }),
        map(() => this.browserService.getScroll().top),
        tap((scroll) => {
          this.hideHeaderSearchBar = scroll > 0;
          this.isScrolledUp = scroll === 0;
          this.changeDetectorRef.markForCheck();
        }),
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  private initTopLineBannerListener(): void {
    this.appHeaderService.topLineBanner$
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: (placement: BannerPlacementDto) => {
          this.bannerPlacement = placement;
          this.changeDetectorRef.markForCheck();
        },
      });
  }

  private destroyVerticalMenuThrottle(): void {
    if (this.verticalMenuThrottleSubscription) {
      this.verticalMenuThrottleSubscription.unsubscribe();
    }
  }

  private initIsMyAukroListener(): void {
    this.routesService.isMyAukroPage$
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((isMyAukro: boolean) => {
        this.isMyAukroOpened = isMyAukro;
        this.changeDetectorRef.markForCheck();
      });
  }

  private initNavigationListener(): void {
    this.router.events
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          if (this.appHeaderService.getModalPanelState()) {
            this.appHeaderService.setModalPanel(null);
          }
          this.changeDetectorRef.detectChanges();
        } else if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
          this.changeDetectorRef.detectChanges();
        }
      });
  }

  protected get showHorizontalMenu(): boolean {
    return this.isSubbrandsEnabled;
  }

  protected get showSearch(): boolean {
    return !(this.isMyAukroOpened && this.isLgAndLower);
  }

  protected get showNavbarMargin(): boolean {
    return !this.isSubbrandsEnabled && this.isMdAndLower;
  }

}
