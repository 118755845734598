import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { SvgIconComponent } from '@common/ui-kit/component/svg-icon/component/svg-icon.component';
import { SubbrandService } from '@shared/subbrand/service/subbrand.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'auk-app-header-logo',
  templateUrl: 'app-header-logo.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    SvgIconComponent,
    RouterLink,
  ],
})
export class AppHeaderLogoComponent {

  @Output() public closeModalPanel: EventEmitter<void> = new EventEmitter<void>();

  @Input() public forceSmallLogo: boolean = false;

  constructor(
    private readonly subbrandService: SubbrandService,
  ) {
  }

  protected onLogoClick(): void {
    this.closeModalPanel.emit();
  }

  protected getLogoId$(): Observable<string> {
    return this.subbrandService.sessionSubbrandLogoLocalized$;
  }

}
