import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { CartItemOverviewDto, CartOverviewDto } from '@api/generated/model';
import { CartService } from '@shared/cart/service/cart.service';
import { PlatformService } from '@shared/platform/service/platform.service';
import { AppHeaderService } from '../../../../service/app-header.service';
import { UserHeaderControlComponent } from '../user-header-control/user-header-control.component';
import { WINDOW_OBJECT } from '@util/const/window-object';
import { AddToCartService } from '@shared/cart/service/add-to-cart.service';
import { HeaderUserPersonalizationMeasurementService } from '@shared/services/personalization/header-user-personalization-measurement.service';
import isNil from 'lodash-es/isNil';
import { ResponsivenessService } from '@common/responsiveness/service/responsiveness.service';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { HeaderItemModel } from '@shared/app-header/module/app-header-user-controls/component/header-item/model/header-item.model';
import { ColorCombinationId } from '@common/colors/model/color-combination-id';
import { FavouriteClickPropertiesModel } from '@shared/favourite/model/favourite-click-properties.model';
import { FavouritePopoverDataService } from '@shared/favourite/service/favourite-popover-data.service';
import { Nil } from '@util/helper-types/nil';

const FULL_CART_ITEMS_DELIMITED_COUNT: number = 10;

@Component({
  selector: 'auk-basket-control',
  templateUrl: './basket-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasketControlComponent extends UserHeaderControlComponent implements OnInit, OnDestroy {

  public displayedCount: string;
  public cartItemsRemainingCount: number;
  public cart: CartOverviewDto;

  protected isDropdownOpened: boolean = false;

  private readonly CART_URL: string = '/kosik';
  private lastPopoverShowId: number;

  private urgentFirstToEndPopoverItemId: number | Nil = null;

  constructor(
    private readonly router: Router,
    private readonly platformService: PlatformService,
    private readonly cartService: CartService,
    private readonly addToCartService: AddToCartService,
    private readonly headerUserPersonalizationMeasurementService: HeaderUserPersonalizationMeasurementService,
    private readonly favouritePopoverDataService: FavouritePopoverDataService,
    @Inject(WINDOW_OBJECT) window: Window,
    changeDetectorRef: ChangeDetectorRef,
    responsivenessService: ResponsivenessService,
    ngZoneUtilService: NgZoneUtilService,
  ) {
    super(window, changeDetectorRef, responsivenessService, ngZoneUtilService);
  }

  public ngOnInit(): void {
    if (!this.platformService.isServerAndMobileAndNotBot) {
      this.cartService.getCartOverview()
        .pipe(
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe((cart: CartOverviewDto) => {
          this.cart = cart;
          this.cartItemsRemainingCount = this.recalculateCartItemsRemainingCount();

          let count = null;
          if (cart && cart.cart) {
            count = AppHeaderService.getBadgeCount(cart.cart.quantity);
          }
          this.displayedCount = count;

          this.changeDetectorRef.detectChanges();
        });
    }

    this.favouritePopoverDataService.firstToEndPopoverUrgentItem$()
      .pipe(
        distinctUntilChanged((a, b) => a?.itemId === b?.itemId),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((urgentItem) => this.urgentFirstToEndPopoverItemId = urgentItem?.itemId);
  }

  public onBasketClick(): void {
    void this.router.navigate([this.CART_URL]);
    this.reloadCart();
  }

  @HostListener('mouseenter')
  public closeCartUpdateNotification(): void {
    this.addToCartService.setBasketNotification(false);
  }

  public removeItem(item: CartItemOverviewDto): void {
    this.cartService.removeItem(item.cartItemId);
    this.measureClick(this.lastPopoverShowId, null, item.itemId, false, false, { action: 'removeItemFromCart' });
  }

  public reloadCart(event?: MouseEvent): void {
    if (isNil(event)) {
      const isDesktop = this.responsivenessService.isLgAndHigher;
      this.measureClick(this.lastPopoverShowId, this.CART_URL, null, isDesktop, !isDesktop, null);
    } else {
      this.measureClick(this.lastPopoverShowId, this.CART_URL, null, false, false, null);
    }

    this.closeDropdown();
    if (this.router.url === this.CART_URL) {
      if (event) {
        event.preventDefault();
      }
      this.cartService.reloadCart();
    }
  }

  public trackByCartItemId(index: number, cartItem: CartItemOverviewDto): number {
    return cartItem.itemId || 0;
  }

  private recalculateCartItemsRemainingCount(): number {
    let count = 0;
    if (this.cart && this.cart.cartItems) {
      count = this.cart.cartItems.length - FULL_CART_ITEMS_DELIMITED_COUNT;
      if (count < 0) {
        count = 0;
      }
    }
    return count;
  }

  protected onPopoverLinkClick(url: string, item: CartItemOverviewDto): void {
    this.closeDropdown();

    this.measureClick(this.lastPopoverShowId, url, item?.itemId, false, false, { action: 'showItemDetail' });
  }

  protected measureClick(
    showId: number,
    targetUrl: string,
    itemId: number,
    desktopIconClick: boolean,
    mobileIconClick: boolean,
    action: FavouriteClickPropertiesModel): void {
    this.headerUserPersonalizationMeasurementService.clickCartPopover({
      showId,
      itemId,
      properties: {
        targetUrl,
        desktopIconClick,
        mobileIconClick,
        urgencyItemId: this.urgentFirstToEndPopoverItemId,
        ...action,
      },
    });
  }

  protected override measurePopoverShow(): void {
    this.headerUserPersonalizationMeasurementService.showCartPopover$()
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((showId) => this.lastPopoverShowId = showId);
  }

  protected setDropdownOpenState(isOpen: boolean): void {
    this.isDropdownOpened = isOpen;
  }

  protected get headerItemData(): HeaderItemModel {
    return {
      badgeText: this.displayedCount,
      icon: {
        source: 'shopping_cart',
        type: 'MATERIAL',
        colorCombination: this.colorCombination,
      },
    };
  }

  protected get colorCombination(): ColorCombinationId {
    return this.isDropdownOpened ? 'SILVERSTEIN_200_900' : 'SILVERSTEIN_CONTRAST_200_600';
  }

}
