import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { combineLatestWith, mergeMap, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { StringUtils } from '@util/util/string.utils';

import { TimeService } from '@common/time/service/time.service';
import moment, { Moment } from 'moment-mini-ts';
import isNil from 'lodash-es/isNil';
// eslint-disable-next-line import/no-restricted-paths
import { WebsocketsService } from '@shared/websocket/service/websockets.service';
import { RxStompState } from '@stomp/rx-stomp';
import { SERVER_TIME_INTERCEPTOR_CONFIG, ServerTimeInterceptorConfig } from '@common/time/model/server-time-interceptor-config';
import { captureMessage, Scope, withScope } from '@sentry/browser';

/**
 * Seeks for X-Server-Time header in responses for sync server time diff to synchronize backend and frontend time.
 * Fallback mechanism when websockets are not working.
 */
@Injectable()
export class ServerTimeInterceptorService implements HttpInterceptor {

  constructor(private readonly timeService: TimeService,
              @Inject(SERVER_TIME_INTERCEPTOR_CONFIG) private config: ServerTimeInterceptorConfig,
              private readonly websocketsService: WebsocketsService<unknown>) {
  }

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if (!this.config.endpoints.find((ep) => req.url.includes(ep))) {
      return next.handle(req);
    }

    return next.handle(req)
      .pipe(
        // Combine with timestamp before processing of response. Timestamp must be before mergeMap.
        combineLatestWith(of(moment())),
        mergeMap(([httpEvent, timestamp]) =>
          this.hasServerTimeHeader(httpEvent) && this.websocketsService.currentConnectionState() !== RxStompState.OPEN
            ? of(httpEvent)
              .pipe(
                tap((httpResponse) => this.processServerTime(httpResponse as HttpResponse<unknown>, timestamp)),
              )
            : of(httpEvent),
        ),
      );
  }

  private hasServerTimeHeader(httpEvent: HttpEvent<unknown>): boolean {
    return httpEvent instanceof HttpResponse &&
      httpEvent.headers.has(this.config.responseHeaderName);
  }

  private processServerTime(httpResponse: HttpResponse<unknown>, responseStartTime: Moment): void {
    const headerValue: string = httpResponse.headers.get(this.config.responseHeaderName);

    if (StringUtils.isBlank(headerValue) || isNil(responseStartTime)) {
      return;
    }

    // Compute server time without of request transmission time. Skip when diff reach treshold as accuracy decreases rapidly
    const now = moment();
    const diff: number = now.diff(responseStartTime);
    if (Math.abs(diff) > this.config.maxTresholdMilis) {
      withScope((scope: Scope) => {
        scope.setTag('responseDiff', diff);
        captureMessage('Threshold reached in ServerTimeInterceptorService', 'info');
      });
      return;
    }

    const serverTime: Moment = StringUtils.parseDate(headerValue);
    const serverTimeWithoutTransmissionTime: Moment = serverTime.add(moment.duration(diff));
    this.timeService.syncServerDiff(serverTimeWithoutTransmissionTime, now);
  }

}
