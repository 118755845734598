import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppBackToTopBtnComponent } from './component/app-back-to-top-btn.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    AppBackToTopBtnComponent,
  ],
  exports: [
    AppBackToTopBtnComponent,
  ],
})
export class AppBackToTopBtnModule { }
