<!-- #ssr-done is used for ssr error detection -->
<div [ngClass]="{
       'blur': disclaimerModalOpened,
       'aukro-main-wrapper': !hideHeaderFooter,
       'aukro-main-wrapper-portal': hideHeaderFooter,
       'has-fixed-full-page-height': hasFixedFullPageHeight,
       'tw-bg-tertiary-100' : hasBackgroundColor,
       '!tw-bg-transparent' : showHeaderV2
     }"
     id="ssr-done"
>

  <!-- IOS TITLE BAR -->
  <div *ngIf="isNativePlatform" class="tw-w-full tw-h-safe-top tw-bg-contrast tw-top-0">
    <div class="tw-fixed tw-w-full tw-h-safe-top tw-z-10000 tw-bg-contrast"></div>
  </div>
  <!-- ./IOS TITLE BAR -->

  <!-- HEADER -->
  <!-- Do not show any header until type of header is evaluated (prevents double creating and destroying component) -->
  <ng-container *ngIf="isShowHeaderV2Evaluated">
    <auk-app-header-v2 *ngIf="showHeaderV2; else legacyHeader"></auk-app-header-v2>
  </ng-container>

  <ng-template #legacyHeader>
    <ng-container *aukIfIsActiveBreakpoint="{ min: 'LG' }">
      <auk-app-header-sticky-panel/>
      <auk-top-panel/>
    </ng-container>

    <auk-app-header-menu [class.hidden]="hideHeaderFooter" *ngIf="isMdAndLower" (menuToggleBtnYPosition)="setMenuToggleBtnYPosition($event)" [dummySearchInput]="dummySearchInput" class="tw-sticky tw-z-100 tw-top-0 tw-top-safe-top tw-block"></auk-app-header-menu>

    <auk-app-header
        [class.hidden]="hideHeaderFooter"
        [showGlobalGoBackButton]="showGlobalGoBackButton"
        [notHideGlobalGoBackButton]="notHideGlobalGoBackButton"
        [menuToggleBtnYPosition]="menuToggleBtnYPosition"
        (dummySearchChange)="setDummySearchRef($event)"
        (openSuggestionsDropdown)="openSuggestionsDropdown()"
    ></auk-app-header>
  </ng-template>
  <!-- ./HEADER -->

  <!-- LOADER -->
  <auk-spinner-legacy *ngIf="loading" class="tw-absolute tw-z-100" [isFullPage]="true"></auk-spinner-legacy>
  <!-- ./LOADER -->

  <!-- MAIN APP -->
  <main class="main"
        [class.tw-opacity-20]="loading"
  >
    <ng-container *aukIfIsActiveBreakpoint="{ max: 'LG' }">
      <auk-top-panel *ngIf="!hasFixedFullPageHeight" [class.hidden]="hideHeaderFooter"></auk-top-panel>
    </ng-container>

    <router-outlet></router-outlet>

  </main>

  <auk-app-back-to-top-button
      *ngIf="showBackToTopBtn"
      [class.tw-hidden]="routesService.isSimpleExposePage$ | async"
      [isHiddenOnTablet]="isProductPage"
  ></auk-app-back-to-top-button>
  <!-- ./MAIN APP -->

</div>

<!-- FOOTER -->
<ng-container *ngIf="showFooter">
  <auk-app-footer *aukAsyncRender
                  [ngClass]="{
                  'blur': disclaimerModalOpened,
                  'hidden': hideHeaderFooter || isMyMessages
                }"
  ></auk-app-footer>
</ng-container>
<!-- ./FOOTER -->

<!-- JSON LD -->
<auk-json-ld></auk-json-ld>
<!-- ./JSON LD -->
