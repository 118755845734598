import { Directive, EventEmitter, HostBinding, HostListener, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ImageLoadingService } from '../service/image-loading.service';
import { ImagePlaceholderSizeType } from '../model/image-placeholder-size.type';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';
import { DummyImagePlaceholderModel } from '../model/dummy-image-placeholder.model';

const DUMMY_IMAGE_COM_URL: string = 'https://dummyimage.com/';

@Directive({
  selector: 'img[aukReplaceByPlaceholderOnError]',
  standalone: true,
})
export class ReplaceByPlaceholderOnErrorDirective implements OnInit, OnChanges {

  /**
   * Image source (image url link), which will be set into src attribute
   */
  @Input() public aukReplaceByPlaceholderOnError: string;

  /** default placeholder */
  @Input() public placeholderSize: ImagePlaceholderSizeType = '400x300';

  /**
   * optional placeholder
   * if specified, has priority over default
   */
  @Input() public dummyImagePlaceholder: DummyImagePlaceholderModel;

  @Output() public imageLoadError: EventEmitter<void> = new EventEmitter<void>();

  @HostBinding('src') public srcAttr: string;

  private placeholderImgUrl: string;
  private dummyImagePlaceholderImgUrl: string;

  constructor(
    private readonly imageLoadingService: ImageLoadingService,
  ) {
  }

  public ngOnInit(): void {
    this.placeholderImgUrl = ReplaceByPlaceholderOnErrorDirective.getPlaceholderImgUrl(this.placeholderSize);
  }

  public ngOnChanges(changes: AukSimpleChanges<ReplaceByPlaceholderOnErrorDirective>): void {
    if (changes.placeholderSize) {
      this.placeholderImgUrl = ReplaceByPlaceholderOnErrorDirective.getPlaceholderImgUrl(this.placeholderSize);
    }
    if (changes.dummyImagePlaceholder) {
      this.dummyImagePlaceholderImgUrl = ReplaceByPlaceholderOnErrorDirective.getDummyImagePlaceholderImgUrl(this.dummyImagePlaceholder);
    }

    if (changes.aukReplaceByPlaceholderOnError) {
      this.srcAttr = this.aukReplaceByPlaceholderOnError ?? this.placeholderImgUrl;
    }
  }

  @HostListener('error')
  public onImageLoadError(): void {
    this.imageLoadingService.handleImageLoadingError(this.srcAttr);
    this.imageLoadError.emit();
    this.srcAttr = this.dummyImagePlaceholderImgUrl ?? this.placeholderImgUrl;
  }

  private static getPlaceholderImgUrl(placeholderSize: ImagePlaceholderSizeType): string {
    // TODO: [PDEV-15679] - create utility function, to get base assets path
    return `/app/common/images/image-placeholder/${ placeholderSize }.webp`;
  }

  private static getDummyImagePlaceholderImgUrl(dummyImagePlaceholder: DummyImagePlaceholderModel): string {
    const config: DummyImagePlaceholderModel = {
      text: dummyImagePlaceholder.text,
      width: dummyImagePlaceholder.width ?? 400,
      height: dummyImagePlaceholder.height ?? 300,
      bgColor: dummyImagePlaceholder.bgColor ?? 'cccccc',
      textColor: dummyImagePlaceholder.textColor ?? 'ffffff',
    };
    return `${ DUMMY_IMAGE_COM_URL }${ config.width }x${ config.height }/${ config.bgColor }/${ config.textColor }&text=${ config.text }`;
  }

}
