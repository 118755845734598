import { dom } from '@fortawesome/fontawesome-svg-core';

export class FontawesomeUtils {

  public static inlineSvgs(document: Document): Promise<void> {
    return dom.i2svg({ node: document, callback: undefined });
  }

  public static addCssTag(document: Document): void {
    const css = dom.css();
    const style = document.createElement('style');
    style.id = 'FA_STYLE';

    document.head.appendChild(style);
    style.appendChild(document.createTextNode(css));
  }

}
