<!--HEADER-->
<auk-dialog-header
  [dialogTitle]="data.name"
  [showCloseButton]="data.showCloseButton"
  (closeClick)="closeClick()"
  >
</auk-dialog-header>
<!--./HEADER-->

<!--CONTENT-->
<mat-dialog-content class="!tw-p-0 !tw-m-0">
  <div class="tw-flex tw-flex-col tw-gap-y-1">

    @if (data.text) {
      <p class="tw-p-6 tw-text-lg tw-text-silverstein-900 tw-rounded-lg">
        {{ data.text | translateSource }}
      </p>
    }

    @if (data.description) {
      <p class="tw-text-md tw-text-silverstein-900 tw-rounded-lg tw-mb-[40px]">
        {{ data.description | translateSource }}
      </p>
    }

    @for (button of data.buttons; track $any(trackByIdFn)($index, button)) {
      <auk-button
        class="tw-max-h-[52px]"
        (buttonClick)="buttonClick(button)"
        weight="NORMAL"
        [colorCombination]="button.colorCombination || 'PRIMARY'">
        @if (button.icon) {
          <div class="tw-w-[18px] tw-h-[18px] tw-mr-2">
            <auk-svg-icon class="!tw-flex tw-justify-end" [iconId]="button.icon"></auk-svg-icon>
          </div>
        }
        {{ button.text| translateSource }}
      </auk-button>
    }

  </div>
</mat-dialog-content>
<!--./CONTENT-->
