import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { RestHttpClientService } from '@api/rest/rest-http-client.service';
import * as model from '../model';
import { MoneyDto } from '../model';
import { HttpContext } from '@angular/common/http';
import { OfferNameDto } from '@api/generated/defs/OfferNameDto';
import { ItemFormTypeDto } from '@api/generated/defs/ItemFormTypeDto';

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface CreateNewOfferUsingPOSTParams {
  /** Offer data */
  itemFormDto: model.ItemFormWithMoneyDtoReq;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface GetValidationsForCreateNewOfferV2UsingPOSTParams {
  /** Offer data */
  itemV2FormDtoReq: model.ItemV2FormDtoReq;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface GetValidationsForCurrentOfferV2UsingPOSTParams {
  /** Offer data */
  itemV2FormDtoReq: model.ItemV2FormDtoReq;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface CreateNewOfferV2UsingPOSTParams {
  /** Offer data */
  itemV2FormDtoReq: model.ItemV2FormDtoReq;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface UpdateOfferV2UsingPATCHParams {
  /** Offer data */
  itemId: number;
  itemV2FormDtoReq: model.ItemV2FormDtoReq;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface UpdateOfferUsingPUTParams {
  /** Offer data */
  itemFormDto: model.ItemFormWithMoneyDtoReq;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface CarouselOffersParams {
  /** ids */
  ids: number[];
  /** format: int32 */
  page?: number;
  /** format: int32 */
  size?: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface CarouselOffersSellerCategoryParams {
  /** item id */
  itemId: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface ExposeFeeSummaryParams {
  /** Selected propagation choices */
  itemPropagationDto: model.ItemPropagationDto;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface HideParams {
  /** dto */
  dto: model.ItemListHideDto;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface ReexposureParams {
  /** Form that contains ID of items ant attributes to set to all items. */
  itemBulkReexposeFormDto: model.ItemBulkReexposeFormDto;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface PrepareParams {
  /** Collection of offers ids which we want to reexpose */
  itemIdsCollectionDto: model.ItemIdsCollectionDto;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface RemoveScheduledParams {
  /** List of scheduled offer ids to remove */
  dto: model.ItemIdsCollectionDto;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface ReportParams {
  /** itemReportDto */
  itemReportDto: model.ItemReportDto;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface SearchItemsCommonParams {
  /** Search criteria */
  itemSearchCommon: model.ItemSearchFilter;
  /** format: int32 */
  page?: number;
  /** format: int32 */
  size?: number;
  sort?: string[];
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface SearchUserRelatedItemsParams {
  /** Watching item ids from unsigned user (from cookie) */
  itemIds?: number[];
  /** Search criteria */
  searchUsersWatchedItems: model.ItemSearchFilterBase;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface SendItemQuestionParams {
  /** Question */
  itemQuestionFormDto: model.ItemQuestionFormDto;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface SetItemFreePriorityListParams {
  /** itemFreePriorityList */
  itemFreePriorityList: model.ItemFreePriorityListDto;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface ShowPropagationFeeParams {
  /** Selected propagation choices */
  showPropagationFeesDto: model.ShowPropagationFeesDto;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export type ItemTypeFilterEnumActiveParams =
  'BIDDING' |
  'BOTH' |
  'BUYNOW';

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface NotificationParams {
  /** watchingItemNotificationDto */
  watchingItemNotificationDto: model.WatchingItemNotificationDto;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface BuyerItemQuestionFormParams {
  /**
   * Deal ID
   * format: int64
   */
  dealId: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface CreateOfferFromExistingParams {
  /**
   * id
   * format: int64
   */
  id: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface ShortParams {
  /**
   * id
   * format: int64
   */
  id: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface DetailCommonParams {
  /**
   * id
   * format: int64
   */
  id: number;
  requestedFor?: PageTypeItemDetailRequestDtoEnum;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export type PageTypeItemDetailRequestDtoEnum =
  'APP_AUKRO' |
  'APP_STRELTO' |
  'CART' |
  'CATEGORY_LISTING' |
  'CATEGORY_PAGE' |
  'DETAIL' |
  'HOMEPAGE' |
  'MY_AUKRO' |
  'OUTER' |
  'PUBLIC_USER_PROFILE' |
  'SEARCH' |
  'WATCHED_ITEMS';

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface OfferDetailGaParams {
  /**
   * id
   * format: int64
   */
  id: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface DetailUserDataParams {
  /**
   * id
   * format: int64
   */
  id: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface ReportFormParams {
  /**
   * Offer ID
   * format: int64
   */
  id: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface SellerItemQuestionFormParams {
  /**
   * Offer ID
   * format: int64
   */
  id: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface TransactionParams {
  /**
   * List of deal ids we want to retrieve
   * format: int64
   */
  dealId: number;
  /**
   * Offer ID
   * format: int64
   */
  id: number;
  /** format: int32 */
  page?: number;
  /** format: int32 */
  size?: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface ActivateAndExposeParams {
  /**
   * itemId
   * format: int64
   */
  itemId: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface SetEarlyEndParams {
  /**
   * Item ID
   * format: int64
   */
  itemId: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface Params {
  /** Collection of bidders for cancellation */
  dto: model.CancelBidCollectionDto;
  /**
   * Offer ID
   * format: int64
   */
  offerId: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface TerminateParams {
  /**
   * Offer ID
   * format: int64
   */
  offerId: number;
  /** Offer terminate data */
  offerTerminateDto: model.ItemTerminateDto;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface FeePreviewForItemParams {
  /**
   * Category ID
   * format: int64
   */
  categoryId: number;
  /** Offer terminate data */
  startingPrice: MoneyDto;
  buyNowPrice: MoneyDto;
  durationEnum: string;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface FeePreviewForItemUsingIdParams extends FeePreviewForItemParams {
  id: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface GetNameParams {
  offerId: number;
  lang?: string;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface ReportNameParams {
  offerId: number;
  lang: string;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface FormTypeParams {
  itemId: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface CreateExpertReviewUsingPOSTParams {
  /** format: int64 */
  itemId: number;
  /** expert review */
  expertReview: model.ExpertReviewDto;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
@Injectable({
  providedIn: 'root',
})
export class OfferApiService extends RestHttpClientService {
  constructor(injector: Injector) {
    super(injector);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public feePreviewForItem(params: FeePreviewForItemParams): Observable<model.BillingFeePreviewDto> {
    return this.postGenerated(`/offers-v2/fee-preview`, params);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public feePreviewForItemUsingId(params: FeePreviewForItemUsingIdParams): Observable<model.BillingFeePreviewDto> {
    return this.postGenerated(`/offers-v2/${ params.id }/fee-preview`, params);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public checkPrerequisitesForCreateNewOfferUsingPOST(edit: boolean, httpContext: HttpContext): Observable<void> {
    let queryParams = {
      edit,
    };
    return this.postGenerated(`/offers/check-prerequisites`, undefined, queryParams, undefined, undefined, undefined, httpContext);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public createNewOfferUsingPOST(params: CreateNewOfferUsingPOSTParams, headers: {
    [key: string]: string
  } = {}): Observable<model.ItemFormWithMoneyDtoRes> {
    let bodyParams = params.itemFormDto;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/offers`, bodyParams, queryParams, formDataParams, headers);
  }
/**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public getValidationsForCreateNewOfferV2UsingPOST(params: GetValidationsForCreateNewOfferV2UsingPOSTParams): Observable<model.ItemValidationsDto> {
    let bodyParams = params.itemV2FormDtoReq;
    return this.postGenerated(`/offers-v2/validations`, bodyParams);
  }
/**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public getValidationsForCurrentOfferV2UsingPOST(itemId: number, params: GetValidationsForCurrentOfferV2UsingPOSTParams): Observable<model.ItemValidationsDto> {
    let bodyParams = params.itemV2FormDtoReq;
    return this.postGenerated(`/offers-v2/${ itemId }/validations`, bodyParams);
  }
/**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public getPreparedData(params: CreateOfferFromExistingParams): Observable<model.CreateItemFormDto> {
    let pathParams = {
      id: params.id,
    };
    return this.getGenerated(`/offers-v2/${ pathParams.id }/prepare`);
  }
/**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public getPreparedDataForEdit(params: CreateOfferFromExistingParams): Observable<model.CreateItemFormDto> {
    let pathParams = {
      id: params.id,
    };
    return this.getGenerated(`/offers-v2/${ pathParams.id }/prepare-edit`);
  }
/**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public createNewOfferV2UsingPOST(params: CreateNewOfferV2UsingPOSTParams): Observable<model.ItemInfoDto> {
    let bodyParams = params.itemV2FormDtoReq;
    return this.postGenerated(`/offers-v2`, bodyParams);
  }
/**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public updateOfferV2UsingPATCH(params: UpdateOfferV2UsingPATCHParams): Observable<model.ItemInfoDto> {
    let pathParams = {
      id: params.itemId,
    };
    let bodyParams = params.itemV2FormDtoReq;
    return this.patch(`/offers-v2/${ pathParams.id }`, bodyParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public updateOfferUsingPUT(params: UpdateOfferUsingPUTParams, headers: {
    [key: string]: string
  } = {}): Observable<model.ItemFormWithMoneyDtoRes> {
    let bodyParams = params.itemFormDto;
    let queryParams;
    return this.putGenerated(`/offers`, bodyParams, queryParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public carouselOffers(params: CarouselOffersParams): Observable<model.HomepageItemDtoPagedResources> {
    let queryParams = {
      ids: params.ids,
      page: params.page,
      size: params.size,
    };
    return this.getGenerated(`/offers/carouselOffers`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public carouselOffersSellerCategory(params: CarouselOffersSellerCategoryParams): Observable<model.HomepageItemDto[]> {
    let queryParams = {
      itemId: params.itemId,
    };
    return this.getGenerated(`/offers/carousel-offers-seller-category`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public exposeFeeSummary(params: ExposeFeeSummaryParams, headers: { [key: string]: string } = {}): Observable<model.BillingSummaryDto> {
    let bodyParams = params.itemPropagationDto;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/offers/exposeFeeSummary`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public hide(params: HideParams, headers: { [key: string]: string } = {}): Observable<void> {
    let bodyParams = params.dto;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/offers/hide`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public reexposure(params: ReexposureParams, headers: { [key: string]: string } = {}): Observable<model.ItemReexposeOffersResultDto> {
    let bodyParams = params.itemBulkReexposeFormDto;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/offers/reexposure`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public prepare(params: PrepareParams, headers: { [key: string]: string } = {}): Observable<model.ItemBulkReexposeDto> {
    let bodyParams = params.itemIdsCollectionDto;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/offers/reexposure/prepare`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public removeScheduled(params: RemoveScheduledParams, headers: { [key: string]: string } = {}): Observable<model.DeletedItemInfoDto[]> {
    let bodyParams = params.dto;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/offers/removeScheduled`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public report(params: ReportParams, headers: { [key: string]: string } = {}, context?: HttpContext): Observable<void> {
    let bodyParams = params.itemReportDto;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/offers/report`, bodyParams, queryParams, formDataParams, headers, null, context);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public searchItemsCommon(params: SearchItemsCommonParams, headers: {
    [key: string]: string
  } = {}): Observable<model.ItemSearchPagedResources> {
    let queryParams = {
      page: params.page,
      size: params.size,
      sort: params.sort,
    };
    let bodyParams = params.itemSearchCommon;
    let formDataParams;
    return this.postGenerated(`/offers/searchItemsCommon`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public searchItemsCollection(params: SearchItemsCommonParams, headers: {
    [key: string]: string
  } = {}): Observable<model.ItemSearchPagedResources> {
    let queryParams = {
      page: params.page,
      size: params.size,
    };
    let bodyParams = params.itemSearchCommon;
    let formDataParams;
    return this.postGenerated(`/offers/searchItemsCommon/items-collection`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public searchUserRelatedItems(params: SearchUserRelatedItemsParams, headers: {
    [key: string]: string
  } = {}): Observable<model.ItemSearchUserRelatedResources> {
    let queryParams = {
      itemIds: params.itemIds,
    };
    let bodyParams = params.searchUsersWatchedItems;
    let formDataParams;
    return this.postGenerated(`/offers/searchUserRelatedItems`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public sendItemQuestion(params: SendItemQuestionParams, headers: {
    [key: string]: string
  } = {}, context?: HttpContext): Observable<void> {
    let bodyParams = params.itemQuestionFormDto;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/offers/sendItemQuestion`, bodyParams, queryParams, formDataParams, headers, null, context);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public setItemFreePriorityList(params: SetItemFreePriorityListParams, headers: { [key: string]: string } = {}): Observable<number[]> {
    let bodyParams = params.itemFreePriorityList;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/offers/setItemFreePriorityList`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public showPropagationFee(params: ShowPropagationFeeParams, headers: { [key: string]: string } = {}): Observable<model.BillingRowDto[]> {
    let bodyParams = params.showPropagationFeesDto;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/offers/showPropagationFee`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public buyerItemQuestionForm(params: BuyerItemQuestionFormParams): Observable<model.ItemQuestionFormDto> {
    let pathParams = {
      dealId: params.dealId,
    };
    let queryParams;
    return this.getGenerated(`/offers/${ pathParams.dealId }/buyerItemQuestionForm`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public createOfferFromExisting(params: CreateOfferFromExistingParams, headers: {
    [key: string]: string
  } = {}): Observable<model.ItemFormWithMoneyDtoRes> {
    let pathParams = {
      id: params.id,
    };
    let bodyParams;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/offers/${ pathParams.id }/createOfferFromExisting`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public offerDetail(params: DetailCommonParams): Observable<model.OfferDetailDto> {
    let pathParams = {
      id: params.id,
      itemDetailRequestDto: {
        pageType: 'DETAIL',
      },
    };
    let queryParams = {
      requestedFor: params.requestedFor,
    };
    return this.getGenerated(`/offers/${ pathParams.id }/offerDetail`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public offerDetailGa(params: OfferDetailGaParams): Observable<model.OfferDetailGaDto> {
    let pathParams = {
      id: params.id
    };
    return this.getGenerated(`/offers/${ pathParams.id }/offerDetailGa`, null);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public withDepositItemDetailData(params: DetailCommonParams): Observable<model.WithDepositItemData> {
    let pathParams = {
      id: params.id,
    };
    let queryParams;
    return this.getGenerated(`/items/${ pathParams.id }/detail/with-deposit`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public privateWithDepositItemDetailData(params: DetailCommonParams): Observable<model.PrivateWithDepositItemData> {
    let pathParams = {
      id: params.id,
    };
    let queryParams;
    return this.getGenerated(`/items/${ pathParams.id }/detail/private-with-deposit`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public inItemsCollectionItemDetailData(params: DetailCommonParams): Observable<model.InItemsCollectionItemData> {
    let pathParams = {
      id: params.id,
    };
    let queryParams;
    return this.getGenerated(`/items/${ pathParams.id }/detail/in-items-collection`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public privateItemDetailData(params: DetailCommonParams): Observable<model.PrivateItemData> {
    let pathParams = {
      id: params.id,
    };
    let queryParams;
    return this.getGenerated(`/items/${ pathParams.id }/detail/private`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public offerDetailShort(params: ShortParams): Observable<model.OfferDetailShortDto> {
    let pathParams = {
      id: params.id,
    };
    let queryParams;
    return this.getGenerated(`/offers/${ pathParams.id }/offerDetail/short`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public detailUserData(params: DetailUserDataParams): Observable<model.ItemDetailUserDataDto> {
    let pathParams = {
      id: params.id,
    };
    return this.getGenerated(`/offers/${ pathParams.id }/detailUserData`);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */  public reportForm(params: ReportFormParams): Observable<model.ItemReportFormDto> {
    let pathParams = {
      id: params.id,
    };
    let queryParams;
    return this.getGenerated(`/offers/${ pathParams.id }/reportForm`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public sellerItemQuestionForm(params: SellerItemQuestionFormParams): Observable<model.ItemQuestionFormDto> {
    let pathParams = {
      id: params.id,
    };
    let queryParams;
    return this.getGenerated(`/offers/${ pathParams.id }/sellerItemQuestionForm`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public transaction(params: TransactionParams): Observable<model.ItemDealDtoPagedModel> {
    let queryParams = {
      dealId: params.dealId,
      page: params.page,
      size: params.size,
    };
    let pathParams = {
      id: params.id,
    };
    return this.getGenerated(`/offers/${ pathParams.id }/transaction`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public activateAndExpose(params: ActivateAndExposeParams, headers: { [key: string]: string } = {}): Observable<string> {
    let pathParams = {
      itemId: params.itemId,
    };
    let bodyParams;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/offers/${ pathParams.itemId }/activateAndExpose`, bodyParams, queryParams, formDataParams, headers, 'text');
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public setEarlyEnd(params: SetEarlyEndParams, headers: { [key: string]: string } = {}): Observable<void> {
    let pathParams = {
      itemId: params.itemId,
    };
    let bodyParams;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/offers/${ pathParams.itemId }/setEarlyEnd`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public postponingEnd(headers: { [key: string]: string } = {}): Observable<void> {
    let pathParams;
    let bodyParams;
    let queryParams;
    return this.putGenerated(`/offers/enable-postponing-end`, bodyParams, queryParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public cancelBid(params: Params, headers: { [key: string]: string } = {}): Observable<boolean> {
    let bodyParams = params.dto;
    let pathParams = {
      offerId: params.offerId,
    };
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/offers/${ pathParams.offerId }/cancelBid/`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public terminate(params: TerminateParams, headers: { [key: string]: string } = {}): Observable<boolean> {
    let pathParams = {
      offerId: params.offerId,
    };
    let bodyParams = params.offerTerminateDto;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/offers/${ pathParams.offerId }/terminate`, bodyParams, queryParams, formDataParams, headers);
  }
/**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public originalName(params: GetNameParams): Observable<OfferNameDto> {
    let pathParams = {
      offerId: params.offerId,
    };
    return this.getGenerated(`/offers/${ pathParams.offerId }/detail/name/original`);
  }
/**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public nameByLanguage(params: GetNameParams): Observable<OfferNameDto> {
    let pathParams = {
      offerId: params.offerId,
    };
    let queryParams = {
      lang: params.lang,
    };
    return this.getGenerated(`/offers/${ pathParams.offerId }/detail/name`, queryParams);
  }
/**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public reportNameTranslation(params: ReportNameParams): Observable<void> {
    let pathParams = {
      offerId: params.offerId,
    };
    let bodyParams;
    let queryParams = {
      lang: params.lang,
    };
    let formDataParams;
    return this.putGenerated(`/offers/${ pathParams.offerId }/detail/name/reportTranslation`, bodyParams, queryParams, formDataParams);
  }
/**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public formType(params: FormTypeParams): Observable<ItemFormTypeDto> {
    let pathParams = {
      itemId: params.itemId,
    };
    return this.getGenerated(`/offers/${ pathParams.itemId }/formType`);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public createExpertReviewUsingPOST(params: CreateExpertReviewUsingPOSTParams): Observable<void> {
    let pathParams = {
      itemId: params.itemId,
    };
    let bodyParams = params.expertReview;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/offers/${ pathParams.itemId }/expert-review`, bodyParams, queryParams, formDataParams);
  }
}
