@if (bannerPlacement) {
  <auk-top-line-banner
    class="tw-relative tw-overflow-hidden"
    [bannerPlacement]="bannerPlacement"
    [class.p-0]="isTablet"
  ></auk-top-line-banner>
}

<!-- HEADER LOGO AND BUTTONS  -->
@if (!isMdAndLower) {
  <auk-app-header-menu  [dummySearchInput]="dummySearchInput"></auk-app-header-menu>
}
<!-- ./HEADER LOGO AND BUTTONS  -->

<!-- GO BACK BUTTON -->
<auk-go-back-button class="tw-fixed tw-z-20 tw-block tw-overflow-visible"
  [class.tw-invisible]="!showGoBackButton"
  [isGlobalButton]="true"
></auk-go-back-button>
<!-- ./GO BACK BUTTON -->

<!-- HEADER NAVBAR V2 -->
@if (showSearch) {
  <div class="tw-max-h-28 tw-max-w-full tw-overflow-y-hidden tw-bg-contrast tw-relative">
    @if (isMdAndLower) {
      @if (!displaySkeletonsOnMobile) {
        <div [ngClass]="{'tw-mb-4': showNavbarMargin }">
          <auk-app-header-search-input
            type="dummy"
            (inputClick)="openDropdown()"
            (searchRef)="setDummySearchInput($event)"
          ></auk-app-header-search-input>
        </div>
      } @else {
        <div class="tw-mx-2 tw-mt-2 tw-mb-4">
          <auk-skeleton-loading height="2.5rem"
            colorCombination="FADED_INVERSED"
          ></auk-skeleton-loading>
        </div>
      }
    }
    @if (showHorizontalMenu) {
      <auk-horizontal-menu-navbar
        [itemData]="horizontalMenuSubbrand"
      ></auk-horizontal-menu-navbar>
    }
  </div>
}
<!-- ./HEADER NAVBAR V2 -->

<!-- Hidden prerendered hamburger dialog for faster opening up -->
<auk-category-dialog-modal [ngClass]="{'tw-invisible' : (categoryMenuOpened$() | async) === false}"></auk-category-dialog-modal>
