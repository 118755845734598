import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ButtonComponent } from '@common/ui-kit/component/button/component/button.component';
import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';
import { IconWithTextComponent } from '@common/ui-kit/component/icon-with-text/component/icon-with-text.component';
import { IconModel } from '@common/ui-kit/component/icon/component/model/icon.model';
import { ColorCombinationId } from '@common/colors/model/color-combination-id';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslationSource } from '@common/translations/model/translation-source';
import { Translate2Module } from '@common/translations/translate2.module';
import { BasicItemCardFollowersModel } from '@common/ui-kit/component/item-card/component/basic-item-card/model/basic-item-card-followers.model';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';
import { Nil } from '@util/helper-types/nil';

@Component({
  selector: 'auk-item-card-followers',
  templateUrl: './item-card-followers.component.html',
  standalone: true,
  imports: [
    ButtonComponent,
    IconComponent,
    IconWithTextComponent,
    MatTooltipModule,
    Translate2Module,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemCardFollowersComponent implements OnChanges {

  @Input() public followersInfo: BasicItemCardFollowersModel;

  @Output() public followClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  protected followersIconModel: IconModel | Nil = null;

  public ngOnChanges(changes: AukSimpleChanges<ItemCardFollowersComponent>): void {
    if (changes.followersInfo) {
      this.evaluateFollowersIconModel();
    }
  }

  protected evaluateFollowersIconModel(): void {
    this.followersIconModel = {
      source: this.followersInfo?.isFollowed ? 'heart-filled' : 'heart',
      type: 'SVG',
      size: 'MD',
    };
  }

  protected get colorCombination(): ColorCombinationId {
    return this.followersInfo?.isFollowed ? 'JUNGLE_GREEN_500' : 'SILVERSTEIN_100_PRIMARY';
  }

  protected get getTooltipText(): TranslationSource {
    return this.followersInfo?.isFollowed
      ? {
        key: 'ITEM_CARD_FOLLOWING',
      }
      : {
        key: 'ITEM_CARD_NOT_FOLLOWING',
      };
  }

  protected onButtonClick(event: MouseEvent): void {
    // prevent event, so the item card won't do navigation
    event.preventDefault();
    event.stopPropagation();

    this.followClick.emit(event);
  }

}
