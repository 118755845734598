import { Inject, Injectable } from '@angular/core';
import { addTracingExtensions, browserTracingIntegration, captureException, init, setUser } from '@sentry/browser';
import { Integration } from '@sentry/types';
import { takeUntil } from 'rxjs/operators';
import { environment } from '@environment';
import { AppInitializer } from '@util/helper-types/initializer.interface';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { AuthCacheService } from '@shared/authentication/service/auth-cache.service';
import { AuthenticationService } from '@shared/authentication/service/authentication.service';
import { BuildInfoService } from '@shared/version/service/build-info.service';
import { StringUtils } from '@util/util/string.utils';
import { SentryUserModel } from './sentry-user.model';
import { AukWindow } from '@shared/model/auk-window.interface';
import { WINDOW_OBJECT } from '@util/const/window-object';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';

@Injectable({
  providedIn: 'root',
})
export class SentryService extends NgUnsubscribe implements AppInitializer {

  constructor(
    private readonly platformCommonService: PlatformCommonService,
    private readonly authenticationService: AuthenticationService,
    private readonly authCacheService: AuthCacheService,
    @Inject(WINDOW_OBJECT) private readonly window: AukWindow,
  ) {
    super();
    this.initUserChangeCheck();
  }

  public get sentryEnabled(): boolean {
    return this.platformCommonService.isBrowser && !PlatformCommonService.isDevMode && !StringUtils.isBlank(environment.SENTRY_DSN);
  }

  public init(): void {
    if (!this.sentryEnabled) {
      return;
    }
    addTracingExtensions();
    init({
      dsn: environment.SENTRY_DSN,
      release: BuildInfoService.version,
      environment: environment.ENVIRONMENT,
      attachStacktrace: true,
      normalizeDepth: 0,
      allowUrls: [
        /.+\.aukro\.cloud/,
        /aukro\.cz/,
      ],
      tracesSampleRate: PlatformCommonService.isProductionMode ? 0.01 : 1,
      beforeSend(event) {
        // https://github.com/DethAriel/ng-recaptcha/issues/123
        if (['Non-Error promise rejection captured with value: Timeout',
          'Uncaught (in promise): Timeout (n)',
          'Uncaught (in promise): Timeout',
          'Non-Error promise rejection captured with value: Timeout (n)',
        ].includes(event.exception.values[0].value)) {
          return null;
        }

        return event;
      },
      integrations: (integrations: Integration[]) => {
        integrations = integrations.filter(i => i.name !== 'TryCatch'); // prevents double logging failed XHR

        integrations.push(browserTracingIntegration({
          enableInp: true,
        }));

        return integrations;
      },
    });

    // Make Sentry logging globally accessible to be used in GTM
    this.window.captureExceptionToSentry = captureException;
  }

  private initUserChangeCheck(): void {
    this.updateUser();

    this.authenticationService.getLoginStatusChange()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((isLoggedIn: boolean) => {
        this.updateUser();
      });
  }

  private updateUser(): void {
    setUser(this.getCurrentUser());
  }

  private getCurrentUser(): SentryUserModel {
    return {
      username: this.authenticationService.isLoggedIn() ? this.authCacheService.decodedToken?.sub : null,
      id: this.authCacheService.aukroToken,
    };
  }

}
