import { Injectable, Inject, Renderer2, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { ResponsivenessService } from '@common/responsiveness/service/responsiveness.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable()
export class CustomOverlayContainer extends OverlayContainer implements OnDestroy {

  protected ngUnsubscribe = new Subject<void>();
  protected isMdAndLower: boolean = false;

  constructor(
    _platform: Platform,
    @Inject(DOCUMENT) _document: Document,
    private readonly renderer: Renderer2,
    private readonly responsivenessService: ResponsivenessService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super(_document, _platform);

    this.responsivenessService.isMdAndLower$
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((isMdAndLower) => {
        this.isMdAndLower = isMdAndLower;
        this.updateContainerZIndex();
        this.changeDetectorRef.markForCheck();
      });
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  protected updateContainerZIndex(): void {
    if (this._containerElement) {
      this.renderer.setStyle(this._containerElement, 'z-index', this.isMdAndLower ? '90' : '110');
    }
  }

  protected override _createContainer(): void {
    super._createContainer();
    this.renderer.addClass(this._containerElement, 'cdk-overlay-tooltip2-container');
    this.updateContainerZIndex();
  }

}
