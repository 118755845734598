<auk-button
    (buttonClick)="onButtonClick($event)"
    [colorCombination]="colorCombination"
    size="SM"
    [matTooltip]="getTooltipText | translateSource"
    matTooltipClass="tooltip-lg"
    matTooltipPosition="above"
    [matTooltipShowDelay]="250"
    [isLoading]="followersInfo.isLoading"
    [transitionDurationMs]="100"
    class="tw-drop-shadow-lg !tw-touch-auto"
>

  <auk-icon-with-text [iconModel]="followersIconModel">
    @if (followersInfo?.followersCount > 0) {
      <span class="tw-text-xs tw-transition tw-duration-100">
        {{ followersInfo.followersCount }}
      </span>
    }
  </auk-icon-with-text>
</auk-button>
