<a *ngIf="tile"
   class="auk-tile"
   [ngClass]="containerDesignClass"
   [class.auk-tile-mobile-small]="size === 'MOBILE_SMALL'"
   [aukLinkFromHref]="tile.link"
   [enableInAppNavigation]="false"
   [title]="tile.tooltip | translateSource | nilToEmptyString"
   (click)="onTileClick($event)"
   [ngSwitch]="tile.type"
>
  <ng-container *ngSwitchCase="'IMAGE_ONLY'" [ngTemplateOutlet]="imageOnlyTmpl"></ng-container>
  <ng-container *ngSwitchCase="'TEXT_ONLY'" [ngTemplateOutlet]="textOnlyTmpl"></ng-container>
  <ng-container *ngSwitchDefault [ngTemplateOutlet]="imageTextTmpl"></ng-container>

  <ng-template #imageOnlyTmpl>
    <div class="tw-flex tw-w-full tw-h-full tw-justify-center tw-items-center tw-px-2">
      <img [src]="tile.imgUrl"
           [alt]="tile.tooltip | translateSource"
           class="tw-max-w-full tw-max-h-3/4"
      >
    </div>
  </ng-template>

  <ng-template #textOnlyTmpl>
    <p [aukInnerHtml]="tile.label"
       [ngClass]="textSize"
       class="tw-line-clamp-3 tw-overflow-hidden tw-whitespace-pre-wrap tw-w-full"
       [class.tw-text-center]="size !== 'MOBILE_SMALL'"
    ></p>
  </ng-template>

  <ng-template #imageTextTmpl>
    <div class="tw-w-6 tw-flex tw-justify-center"
         [ngClass]="size === 'MOBILE_SMALL' ? 'tw-pl-0' : 'tw-pl-3'"
    >
      <img *ngIf="tile.imgUrl; else iconTmpl"
           [src]="tile.imgUrl"
           [alt]="tile.label | translateSource"
           class="tw-h-6 tw-w-6 tw-object-contain"
      >
      <ng-template #iconTmpl>
        <div class="tw-w-6 tw-flex tw-justify-center"
        >
          <!-- CHECKBOX TILE ICON -->
          <mat-checkbox *ngIf="type === 'CHECKBOX'"
                        class="!tw-flex tw-items-center tw-min-w-[18px] tw-min-h-[18px]"
                        [checked]="isSelected"
          />
          <!-- ./CHECKBOX TILE ICON -->

          <auk-icon *ngIf="type !== 'CHECKBOX' && tile.defaultIcon as icon"
                    [source]="icon.source"
                    [iconType]="icon.type"
                    [size]="icon.size"
                    [colorCombination]="icon.colorCombination"
          ></auk-icon>
        </div>
      </ng-template>
    </div>

    <div class="tw-flex tw-items-center tw-justify-between tw-space-x-4 tw-w-full">
      <p [aukInnerHtml]="tile.label"
         [ngClass]="textSize"
         class="tw-line-clamp-3 w-overflow-hidden tw-whitespace-pre-wrap"
      ></p>
      <p *ngIf="tile.count"
         [ngClass]="textSize"
         class="tw-text-silverstein-500 tw-text-xs tw-not-italic tw-font-medium"
      >
        {{ tile.count }}
      </p>
      <auk-icon *ngIf="tile.suffixIcon as suffixIcon"
                [source]="suffixIcon.source"
                [iconType]="suffixIcon.type"
                [size]="suffixIcon.size"
                [colorCombination]="suffixIcon.colorCombination"
      ></auk-icon>
    </div>
  </ng-template>
</a>
