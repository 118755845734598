<!-- SMALL BREAKPOINT HEADER -->
@if (isMdAndLower) {
  <div
    class="tw-fixed tw-top-safe-top tw-w-full tw-flex tw-items-center tw-h-16 tw-z-10 tw-bg-surface-neutral-0 tw-px-2 tw-border-b tw-border-solid tw-border-surface-neutral-4"
    >
    <h2 class="tw-text-silverstein-700 tw-text-md tw-font-bold tw-m-0">
      {{ 'CATEGORIES_SELECTION_OPEN_DIALOG_BUTTON_TEXT'| translate }}
    </h2>
    <auk-icon
      class="tw-ml-auto tw-p-2"
      iconType="MATERIAL"
      source="close"
      size="LG"
      colorCombination="SILVERSTEIN_700_CONTRAST"
      (click)="clickDialogClose()"
      />
  </div>
} @else {
  <div class="tw-absolute tw-right-5 tw-rounded-lg tw-bg-contrast tw-p-2 tw-mt-5 tw-z-50"
    (click)="clickDialogClose()">
    <auk-icon
      class="tw-cursor-pointer"
      type="MATERIAL"
      source="close"
      size="LG"
      colorCombination="SILVERSTEIN_700_CONTRAST"
      aukAnalyticsTracking
      />
  </div>
}
<!-- ./SMALL BREAKPOINT HEADER -->


<div class="header-dialog-content tw-h-[calc(var(--vh,1vh)_*_100_-_env(safe-area-inset-top))] tw-pt-16 tw-bg-surface-neutral-2 md:tw-h-auto md:tw-max-h-[calc(var(--vh,1vh)_*_80)] md:tw-pt-0 md:tw-bg-inherit"
  [class.tw-overflow-hidden]="isLoadingSubcategories"
  >
  <!-- LOADER -->
  @if (isLoadingSubcategories) {
    <auk-spinner
      [v2]="true" size="3XL"
      class="tw-absolute tw-z-50 tw-m-auto tw-left-0 tw-right-0"/>
  }
  <!-- ./LOADER -->

  <!-- SUBLEVEL CATEGORY HEADER -->
  @if (isMdAndLower && hasSubLevelCategories) {
    <div
      class="tw-flex tw-flex-col tw-space-y-2 tw-px-2 tw-pt-2"
      >
      <div class="tw-flex tw-items-center">
        @if (hasSubLevelCategories) {
          <auk-icon
            class="tw-mr-0.5"
            type="MATERIAL"
            source="chevron_left"
            size="XL"
            colorCombination="SILVERSTEIN_700_CONTRAST"
            slot="before-title"
            (click)="onBackClick()"
            />
        }
        <h4 class="tw-m-0 tw-text-md tw-font-medium">
          {{ currentSubLevelCategory.shortName }}
        </h4>
      </div>
      <auk-button
        class="tw-h-12"
        [colorCombination]="this.selectedTabId === 'ANTIQUE' ? 'ANTIQUE_FULL' : 'BAZAAR_FULL'"
        size="SM-NORMAL"
        (buttonClick)="onShowAllFromSelectedCategory(currentSubLevelCategory.seoUrl)"
        >
        <div class="tw-w-full tw-flex tw-items-center tw-space-x-2 tw-text-matter-neutral-primary-invert">
          <auk-icon
            type="MATERIAL"
            source="grid_view"
            size="MD"
            colorCombination="PRIMARY"
            />
          <span [aukInnerHtml]="{ key: 'CATEGORY_DIALOG_COMPONENT_SHOW_ALL' }"></span>
        </div>
      </auk-button>
    </div>
  }
  <!-- ./SUBLEVEL CATEGORY HEADER -->

  @if (!isMdAndLower) {
    <div class="tw-flex tw-w-full tw-pr-4 !tw-pb-0 tw-h-20 tw-justify-center tw-items-center tw-pl-4">
      <auk-tab-control
        (tabChange)="tabChange($any($event))"
        [tabList]="tabList"
        [defaultSelectedTabId]="selectedTabId"
        class="tw-ml-auto tw-mr-auto tw-mt-auto tw-w-[24rem]"
        />
    </div>
  }

  <div class="tw-bg-inherit md:tw-p-8">

    @if (!isMdAndLower) {
      <!-- Each subbrand variant prerendered intentionally -->
      <auk-list-menu
        [ngClass]="{'tw-hidden': this.selectedTabId !== 'ANTIQUE'}"
        tileSize="BIG"
        [tileListContainer]="getForTab('ANTIQUE')"
        (tileClick)="onTileClick($event)"/>
      <auk-list-menu
        tileSize="BIG"
        [ngClass]="{'tw-hidden': this.selectedTabId !== 'BAZAAR'}"
        [tileListContainer]="getForTab('BAZAAR')"
        (tileClick)="onTileClick($event)"/>
    }

    @if (isMdAndLower) {
      <!-- First level of categories -->
      @if (!hasSubLevelCategories) {
        <div
          [ngClass]="{ 'tw-pt-11': isMobileApp }"
          class="tw-px-2 tw-pt-3 tw-pb-2 tw-pb-safe-bottom"
          >
          <auk-list-menu
              [tileContainerStyle]="{
                minTileWidth: '11.5rem'
              }"
            (tileClick)="onTileClick($event)"
            type="ONE-LINE"
            [tileListContainer]="tileListContainerHeader"
            />
          <auk-tab-control
            (tabChange)="tabChange($any($event))"
            type="NORMAL"
            tabBackground="CONTRAST"
            class="tw-justify-center tw-flex tw-my-4"
            [tabList]="tabList"
            [defaultSelectedTabId]="selectedTabId"
            />
          <!-- Each subbrand variant prerendered intentionally -->
          <auk-list-menu
              [tileContainerStyle]="{
                minTileWidth: '11.5rem'
              }"
            (tileClick)="onTileClick($event)"
            [ngClass]="{'tw-hidden': this.selectedTabId !== 'ANTIQUE'}"
            type="ONE-LINE"
            [tileListContainer]="getForTab('ANTIQUE')"
            />
          <auk-list-menu
              [tileContainerStyle]="{
                minTileWidth: '11.5rem'
              }"
            (tileClick)="onTileClick($event)"
            [ngClass]="{'tw-hidden': this.selectedTabId !== 'BAZAAR'}"
            type="ONE-LINE"
            [tileListContainer]="getForTab('BAZAAR')"
            />
        </div>
      } @else {
        <div class="tw-flex tw-flex-col tw-px-2 tw-py-3 tw-gap-2 tw-bg-surface-neutral-2">
          @for (subLevelCategory of currentSubLevelCategories; track trackByIndexFn($index)) {
            @if (subLevelCategory.itemsCount > 0) {
              <div
                class="tw-flex tw-h-12 tw-shadow tw-justify-between tw-items-center tw-rounded tw-bg-surface-neutral-0 tw-pl-2 tw-pr-1	tw-cursor-pointer"
                (click)="onSubCategoryTileClick(subLevelCategory)">
                <span class="tw-text-sm tw-line-clamp-2">{{ subLevelCategory.shortName }}</span>
                <div class="tw-flex tw-items-center">
                  <!-- COUNT -->
                  <span class="tw-text-matter-neutral-tertiary tw-text-xs tw-font-medium tw-whitespace-nowrap">{{ subLevelCategory.itemsCount }}</span>
                  <!-- ./COUNT -->
                  <!-- ICON -->
                  @if (!subLevelCategory.leaf) {
                    <auk-icon
                      iconType="MATERIAL"
                      source="chevron_right"
                      size="XL"
                      [colorCombination]="this.selectedTabId === 'ANTIQUE' ? 'ANTIQUE_FULL' : 'BAZAAR_FULL'"
                      />
                  } @else {
                    <div class="tw-w-[30px]"></div>
                  }
                  <!-- ./ICON -->
                </div>
              </div>
            }
          }
        </div>
      }
      <!-- ./First level of categories -->
    }

  </div>
</div>
