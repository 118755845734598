@if (itemHasEnded) {
  <span>{{ 'ITEM_CARD_TIMER_ENDED_LABEL' | translate }}</span>
} @else {
  @if (timeToEnd) {
    @if (isMoreThenOneDay && humanReadableTime) {
      <span>{{ humanReadableTime }}</span>
    }
    @if (!isMoreThenOneDay) {
      <auk-countdown-panel
        [ngClass]="{'tw-hidden': lessThanMinute}"
        [endingTime]="timeToEnd"
        (lessThanOneMinute)="onLessThanOneMinute($event)"
        (countdownEnd)="onCountdownEnd()"
      ></auk-countdown-panel>
      <span [ngClass]="{'tw-hidden': !lessThanMinute}">{{ 'ITEM_CARD_TIMER_LESS_THAN_MINUTE' | translate }}</span>
    }
  }
}

<!--
The <auk-countdown-panel> is hidden with CSS ('tw-hidden') instead of being removed from the DOM.
This allows event handlers, like countdownEnd, to remain active even when the countdown is under one minute.
-->

