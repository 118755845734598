<div class="container tw-h-auto tw-p-0 tw-z-[100] tw-bg-contrast tw-top-0 lg:tw-h-[initial] lg:tw-min-h-[4.75rem]">

  <auk-app-header-sticky-panel *aukIfIsActiveBreakpoint="{ max: 'LG' }"/>

  <div class="tw-flex tw-flex-wrap tw-items-center tw-px-4 tw-pr-4 tw-py-2 md:tw-py-0 md:!tw-pt-2 lg:tw-flex-nowrap lg:tw-gap-x-4">
    <!-- MAIN LOGO -->
    <div #appHeaderLogo class="tw-grow">
      <auk-app-header-logo [forceSmallLogo]="forceSmallLogo" (closeModalPanel)="closeModalPanel()"></auk-app-header-logo>
    </div>
    <!-- ./MAIN LOGO -->

    @if (!displaySkeletons) {
      <!-- HEADER SEARCH -->
      <auk-app-header-search
        [ngClass]="{'tw-mt-4' : !isMdAndLower && !displaySkeletonsOnTablet, 'tw-mb-[1.35rem]': displaySkeletonsOnTablet && !displaySkeletonsOnMobile}"
        class="tw-flex tw-justify-center md:tw-mt-0"
        [dummySearchInput]="dummySearchInput"
        (searchDropdownToggle)="onSearchDropdownToggle($event)"
      ></auk-app-header-search>
      <!-- ./HEADER SEARCH -->
      <!-- MOBILE DOMAIN PICKER -->
      @if (isNativeApp) {
        <auk-domain-picker class="tw-hidden lg:tw-block" [showTitle]="false"></auk-domain-picker>
      }
      <!-- ./MOBILE DOMAIN PICKER -->
    } @else {
      <auk-skeleton-loading height="2.75rem"
        class="tw-my-1.5"
        [ngClass]="{'tw-order-2' : displaySkeletonsOnTablet}"
        colorCombination="FADED_INVERSED"
      ></auk-skeleton-loading>
    }


    <!-- HEADER USER CONTROLS SKELETON -->
    @if (displaySkeletonsOnTablet || displaySkeletons) {
      <div
        class="tw-items-center tw-mb-1.5 tw-mt-1 tw-flex tw-flex-1 -tw-mr-3 tw-justify-end"
        [ngClass]="{'tw-h-16 !tw-mt-0 !tw-mb-0 tw-mr-0': !isMdAndLower, 'tw-h-14': isMdAndLower && !displaySkeletonsOnMobile}"
        >
        @for (item of skeletonIconsList; let last = $last; let i = $index; track i) {
          <span class="tw-cursor-pointer tw-bg-surface-neutral-3 tw-w-6 tw-h-6 tw-mx-1 tw-mt-3 tw-mb-0.5 tw-rounded-full"
            [ngClass]="{'tw-mr-[13px]': !last, 'tw-w-8 tw-h-8 !tw-mt-1': !isMdAndLower}"
          ></span>
        }
      </div>
    } @else {
      <div #appHeaderUserControlsElm class="tw-flex tw-ml-auto tw-relative tw-z-10 tw-order-2 lg:tw-order-none">
        <auk-app-header-user-controls class="tw-flex"
          [user]="user"
          [showSearchButton]="showSearchButton"
          (menuToggleBtnYPositionChange)="onMenuToggleBtnYPositionChange($event)"
          (clickAction)="userControlClick($event)"
        ></auk-app-header-user-controls>
      </div>
    }
    <!-- ./HEADER USER CONTROLS SKELETON -->

    <!-- HEADER USER CONTROLS -->
    <!-- ./HEADER USER CONTROLS -->

    <!-- SELL BTN SKELETON -->
    @if (displaySkeletons && !displaySkeletonsOnTablet) {
      <auk-skeleton-loading height="2.75rem"
        class="!tw-w-[104px] tw-shrink-0"
        colorCombination="FADED_INVERSED"
      ></auk-skeleton-loading>
    } @else {
      <a [routerLink]="sellButtonLink.url"
        [queryParams]="sellButtonLink.queryParams"
        class="tw-hidden lg:tw-block"
        data-gtm="new-item-primary-button"
        >
        <auk-button class="tw-w-[102px] tw-h-10" size="MD" [chin]="true" colorCombination="PRIMARY">
          <div class="tw-text-sm tw-flex tw-items-center tw-gap-2 tw-px-4 tw-py-0 tw-justify-center">
            <auk-icon source="plus-round" iconType="SVG" size="MMD"></auk-icon>
            {{ 'COMMON__SELL' | translate }}
          </div>
        </auk-button>
      </a>
    }
    <!-- ./SELL BTN SKELETON -->

    <!-- SELL BTN -->
    <!-- ./SELL BTN -->

  </div>
</div>
