<!-- POPOVER WRAPPER-->
<div class="tw-pt-3 md:tw-pt-0">
  <!-- POPOVER -->
  <div class="popover">

    <!-- HEADER -->
    <div class="header">
      <!-- TITLE -->
      <div class="tw-flex tw-text-md tw-font-bold">
        {{ { key: 'FAVOURITES_POPOVER_TITLE' } | translateSource }}
      </div>
      <!--./TITLE -->

      <!-- TABS -->
      <div class="tw-flex tw-items-start tw-gap-5">
        <!-- TAB -->
        @for (tab of favourites?.favouriteTabs; track trackByIndexFn($index)) {
          <div
            class="tab"
            [ngClass]="(activeTab === tab.name) ? 'active': ''"
            (click)="onTabClick(tab.name)">
            <span>{{ tab.label | translateSource }}</span>
            <span>({{ tab.count }})</span>
          </div>
        }
        <!-- ./TAB -->

      </div>
      <!-- ./TABS -->

    </div>
    <!--./ HEADER -->

    <!-- POPOVER CONTENT -->
    @switch (activeTab) {

      <!-- ITEMS CONTENT -->
      @case ('ITEMS') {
        <auk-favourite-items-popover
          class="tw-w-full"
          [favouriteItems]="favourites?.favouriteItemsPreviewData"
          (buyItem)="handleBuyItem($event)"
          (measuredClick)="handleMeasuredClick($event)"/>
      }
      <!-- ./ITEMS CONTENT -->

      <!-- SELLERS CONTENT -->
      @case ('SELLERS') {
        <auk-favourite-sellers-popover
          class="tw-w-full"
          [favouriteSellers]="favourites?.favouriteSellersPreviewData"
          (measuredClick)="handleMeasuredClick($event)"/>
      }
      <!-- ./SELLERS CONTENT -->

      <!-- SEARCH CONTENT -->
      @case ('SEARCH') {
        <auk-favourite-search-popover
          class="tw-w-full"
          (measuredClick)="handleMeasuredClick($event)"/>
      }
      <!-- ./SEARCH CONTENT -->

    }
    <!-- ./POPOVER CONTENT -->

    <!-- FOOTER -->
    @if (shouldShowFooter()) {
      <div
        class="footer">
        <auk-button
          (buttonClick)="showAllClicked()"
          [size]="isMdAndLower ? 'MD-NORMAL' : 'SM'"
          colorCombination="PRIMARY"
          >
          {{ { key: 'FAVOURITES_POPOVER_SHOW_ALL' } | translateSource }}
        </auk-button>
      </div>
    }
    <!--./FOOTER -->

  </div>
  <!-- ./POPOVER -->
</div>
<!-- ./POPOVER WRAPPER -->
