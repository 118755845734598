import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, TrackByFunction } from '@angular/core';
import { TileModel } from '@common/ui-kit/component/tile/model/tile.model';
import { Translate2Module } from '@common/translations/translate2.module';
import { TileContainerComponent } from '@common/ui-kit/component/tile/component/tile-container/tile-container.component';
import { trackByIndexFn } from '@util/helper-functions/track-by/track-by-index.fn';
import { TranslationSource } from '@common/translations/model/translation-source';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';
import { TileContainerStyleModel } from '../../tile/model/tile-container-style.model';
import { TileModelWithCustomColumns } from '@common/ui-kit/component/tile/model/tile-model-with-custom-columns.model';

@Component({
  selector: 'auk-list-menu',
  templateUrl: './list-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    Translate2Module,
    TileContainerComponent,
  ],
})
export class ListMenuComponent<T> implements OnChanges, OnInit {

  @Input() public tileListContainer: TileModelWithCustomColumns<T>[][] = [];

  @Input() public titleText: TranslationSource;

  @Input() public subtitleText: TranslationSource;

  @Input() public tileType: 'SIMPLE' | 'NORMAL' = 'NORMAL';

  @Input() public tileSize: 'BIG' | 'SMALL' = 'SMALL';

  @Input() public type: 'ONE-LINE' | 'MULTI-LINE' = 'MULTI-LINE';

  /**
   * @deprecated
   * see comment on TileContainerStyleModel
   */
  @Input() public tileContainerStyle: TileContainerStyleModel;

  @Output() public tileClick: EventEmitter<T> = new EventEmitter<T>();

  protected trackByIndexFn: TrackByFunction<TileModel<T>[]> = trackByIndexFn;

  protected containerSpacingClass: string = '';
  protected adjustedTileContainerStyles: TileContainerStyleModel[] = [];

  public ngOnInit(): void {
    this.generateSpacingClass();
    this.calculateTileContainerStyle();
  }

  public ngOnChanges(changes: AukSimpleChanges<ListMenuComponent<T>>): void {
    if (changes.type) {
      this.generateSpacingClass();
    }
    if (changes.tileListContainer) {
      this.calculateTileContainerStyle();
    }
  }

  private generateSpacingClass(): void {
    if (this.type === 'ONE-LINE') {
      this.containerSpacingClass = 'tw-gap-y-4 md:tw-p-4';
    } else {
      this.containerSpacingClass = 'tw-pb-2 md:tw-pb-7';
    }
  }

  protected onTileClick(data: T): void {
    this.tileClick.emit(data);
  }

  /**
   * Adjusts the tile container style
   */
  private calculateTileContainerStyle(): void {
    this.adjustedTileContainerStyles = this.tileListContainer.map((tiles, index) => (
      {
        ...this.tileContainerStyle,
        columnsOnMobile: tiles[0]?.columnsOnMobile,
      }
    ));
  }

}
