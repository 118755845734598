import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InboxControlComponent } from '@shared/app-header/module/app-header-user-controls/component/inbox-control/inbox-control.component';
import { AppHeaderUserControlsComponent } from '@shared/app-header/module/app-header-user-controls/component/app-header-user-controls/app-header-user-controls.component';
import { AccountControlComponent } from '@shared/app-header/module/app-header-user-controls/component/account-control/account-control.component';
import { AnnouncementsControlComponent } from '@shared/app-header/module/app-header-user-controls/component/announcements-control/announcements-control.component';
import { CartNotificationComponent } from '@shared/app-header/module/app-header-user-controls/component/cart-notification/cart-notification.component';
import { FavouritesControlComponent } from '@shared/app-header/module/app-header-user-controls/component/favourites-control/favourites-control.component';
import { BasketControlComponent } from '@shared/app-header/module/app-header-user-controls/component/basket-control/basket-control.component';
import { BasketItemComponent } from '@shared/app-header/module/app-header-user-controls/component/basket-control/basket-item/basket-item.component';
import { AvatarModule } from '@shared/avatar/avatar.module';
import { Translate2Module } from '@common/translations/translate2.module';
import { RouterModule } from '@angular/router';
import { HideIfMobileAppDirective } from '@shared/platform/is-mobileapp.directive';
import { RelativeTimePipe } from '@shared/legacy/pipe/relative-time.pipe';
import { OfferUrlPipe } from '@shared/pipes/offer-url/offer-url.pipe';
import { CurrencyApproximationModule } from '@shared/legacy/component/currency-approximation/currency-approximation.module';
import { CurrencyPipeModule } from '@shared/currency/pipe/currency-pipe.module';
import { UnitPluralizeModule } from '@common/ui-kit/common/unit-pluralize/unit-pluralize.module';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';
import { ReplaceByPlaceholderOnErrorDirective } from '@common/image-loading/directive/replace-by-placeholder-on-error.directive';
import { InViewportDetectorDirective } from '@shared/legacy/directive/in-viewport/in-viewport-detector.directive';
import { FavouritePopoverModule } from '@shared/favourite/favourite-popover.module';
import { PopoverModule } from '@shared/legacy/directive/popover/popover.module';
import { Tooltip2Directive } from '@common/tooltip2/directive/tooltip2.directive';
import { HeaderItemComponent } from '@shared/app-header/module/app-header-user-controls/component/header-item/header-item.component';
import { MouseOverDirective } from '@common/mouse-over/directive/mouse-over.directive';
import { InnerHtmlDirective } from '@common/html/directive/inner-html.directive';

@NgModule({
  imports: [
    CommonModule,
    AvatarModule,
    Translate2Module,
    RouterModule,
    HideIfMobileAppDirective,
    RelativeTimePipe,
    OfferUrlPipe,
    ReplaceByPlaceholderOnErrorDirective,
    CurrencyApproximationModule,
    CurrencyPipeModule,
    UnitPluralizeModule,
    MatTooltipModule,
    IconComponent,
    InViewportDetectorDirective,
    FavouritePopoverModule,
    PopoverModule,
    Tooltip2Directive,
    HeaderItemComponent,
    MouseOverDirective,
    InnerHtmlDirective,
  ],
  declarations: [
    AppHeaderUserControlsComponent,
    AccountControlComponent,
    InboxControlComponent,
    AnnouncementsControlComponent,
    CartNotificationComponent,
    FavouritesControlComponent,
    BasketControlComponent,
    BasketItemComponent,
  ],
  exports: [
    AppHeaderUserControlsComponent,
  ],
})
export class AppHeaderUserControlsModule {
}
