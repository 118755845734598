import { BehaviorSubject, EMPTY, merge, mergeMap, Observable, throwError } from 'rxjs';
import { UserTaskType } from '../model/user-task.type';
import { UserTaskStepType } from '../model/user-task-step.type';
import { UserTaskStepDefinitionModel } from '../model/user-task-step-definition.model';
import { UserTaskPayloadModel } from '../model/user-task-payload.model';
import { UserTaskStepUnionModel } from '../model/user-task-step-union.model';
import {
  MatLegacyDialogConfig as MatDialogConfig,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { UserTaskDialogComponent } from '../component/user-task-dialog.component';
import { Injector, Type, ViewContainerRef } from '@angular/core';
import { UserTaskStepComponent } from '../component/user-task-step.component';
import { catchError, expand, finalize, last, map, take, tap } from 'rxjs/operators';
import { UserTaskStepDefinitionUnionModel } from '../model/user-task-step-definition-union.model';
import isNil from 'lodash-es/isNil';
import { UserTaskStepResolutionHandlerService } from './user-task-step-resolution-handler.service';
import { UserTaskStepResolutionUnionModel } from '../model/user-task-step-resolution-union.model';
import { ModalViewsConfigService } from './modal-views-config.service';
import { UserTaskAnalyticsDataModel } from '../model/user-task-analytics-data.model';
import { PopupFlowGaService } from '@shared/popup-flow/service/popup-flow-ga.service';
import { Nil } from '@util/helper-types/nil';
import { AukMatDialogService } from '@shared/dialog/service/auk-mat-dialog-service';
import { DialogUtil } from '@common/dialog/utils/dialog.util';

export abstract class UserTaskExecutorService<TASK_TYPE extends UserTaskType> {

  protected matDialogConfigOverride: MatDialogConfig;

  protected abstract steps: { [STEP_TYPE in UserTaskStepType<TASK_TYPE>]: UserTaskStepDefinitionModel<TASK_TYPE, STEP_TYPE> };

  protected constructor(
    private readonly injector: Injector,
    private readonly modalViewsConfigService: ModalViewsConfigService,
    private readonly popupFlowGaService: PopupFlowGaService,
    private readonly aukMatDialogService: AukMatDialogService,
  ) {
  }

  public executeTask$(payload: UserTaskPayloadModel<TASK_TYPE>, analyticsData: UserTaskAnalyticsDataModel): Observable<void> {

    const dialogConfig: MatDialogConfig = {
      ...this.modalViewsConfigService.getModalConfig(),
      ...this.matDialogConfigOverride,
    };

    const dialog$: Observable<MatDialogRef<UserTaskDialogComponent>> = this.aukMatDialogService.open$(
      UserTaskDialogComponent,
      DialogUtil.of('POPUP_FLOW', this.taskType),
      dialogConfig,
    );

    return dialog$
      .pipe(
        take(1),
        mergeMap((dialog) => {
          const [currentStepType$, executeSteps$] = this.executeSteps$(payload, dialog, analyticsData);

          return merge(
            this.afterClose(dialog),
            executeSteps$,
          )
            .pipe(
              take(1),
              finalize(() => dialog.close()),
              catchError((error) => currentStepType$
                .pipe(
                  tap((currentStepType) => {
                    void this.popupFlowGaService.trackPopupFlowInterruptedTask(
                      analyticsData.action,
                      this.taskType,
                      currentStepType,
                    );
                  }),
                  mergeMap(() => throwError(error)),
                )),
            );
        },
        ),
      );
  }

  private executeSteps$(
    payload: UserTaskPayloadModel<TASK_TYPE>,
    dialog: MatDialogRef<UserTaskDialogComponent>,
    analyticsData: UserTaskAnalyticsDataModel,
  ): [Observable<UserTaskStepType<TASK_TYPE>>, Observable<void>] {
    const currentOpenedStepSubject$: BehaviorSubject<UserTaskStepType<TASK_TYPE> | Nil> =
      new BehaviorSubject<UserTaskStepType<TASK_TYPE> | Nil>(null);

    return [
      currentOpenedStepSubject$.asObservable(),
      this.getFirstStep$(payload)
        .pipe(
          tap((step) => {
            currentOpenedStepSubject$.next(step.type);
          }),
          mergeMap((step) => this.executeStep$(dialog, step, analyticsData)),
          expand((
            [step, resolution]: [UserTaskStepUnionModel<TASK_TYPE>, UserTaskStepResolutionUnionModel<TASK_TYPE, typeof step.type>],
          ) => {
            const nextStep: UserTaskStepUnionModel<TASK_TYPE> = this.getNextStep(step, resolution, payload);

            if (isNil(nextStep)) {
              return EMPTY;
            }

            currentOpenedStepSubject$.next(nextStep.type);
            return this.executeStep$(dialog, nextStep, analyticsData);
          }),
          last(),
          map<unknown, void>(() => void 0),
        ),
    ];

  }

  private getNextStep(
    currentStep: UserTaskStepUnionModel<TASK_TYPE>,
    resolution: UserTaskStepResolutionUnionModel<TASK_TYPE, typeof currentStep.type>,
    taskPayload: UserTaskPayloadModel<TASK_TYPE>,
  ): UserTaskStepUnionModel<TASK_TYPE> {

    const resolutionHandler: Type<UserTaskStepResolutionHandlerService<TASK_TYPE, typeof currentStep.type>> =
      this.steps[currentStep.type].resolutionHandler;

    return this.injector.get(resolutionHandler).handle(resolution, taskPayload);
  }

  private executeStep$(
    dialog: MatDialogRef<UserTaskDialogComponent>,
    step: UserTaskStepUnionModel<TASK_TYPE>,
    analyticsData: UserTaskAnalyticsDataModel,
  ): Observable<[UserTaskStepUnionModel<TASK_TYPE>, UserTaskStepResolutionUnionModel<TASK_TYPE, typeof step.type>]> {

    const dialogViewContainerRef: ViewContainerRef = dialog.componentInstance.stepHost.viewContainerRef;
    dialogViewContainerRef.clear();

    const stepDefinition: UserTaskStepDefinitionUnionModel<TASK_TYPE> = this.steps[step.type];
    const stepRef = dialogViewContainerRef
      .createComponent<UserTaskStepComponent<TASK_TYPE, typeof step.type>>(stepDefinition.component);

    stepRef.instance.payload = step.payload;
    stepRef.changeDetectorRef.detectChanges();

    void this.popupFlowGaService.trackPopupFlowStepDisplayedTask(analyticsData.action, this.taskType, step.type);

    return stepRef.instance.resolve
      .pipe(
        take(1),
        map((resolution: UserTaskStepResolutionUnionModel<TASK_TYPE, typeof step.type>) => [step, resolution]),
      );
  }

  private afterClose(dialog: MatDialogRef<UserTaskDialogComponent>): Observable<never> {
    return dialog?.afterClosed()
      .pipe(
        map(() => {
          throw new Error('User closed the dialog before the task completed');
        }),
      );
  }

  protected abstract taskType: TASK_TYPE;

  protected abstract getFirstStep$(payload: UserTaskPayloadModel<TASK_TYPE>): Observable<UserTaskStepUnionModel<TASK_TYPE>>;

}
