import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslationSource } from '@common/translations/model/translation-source';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { NativeDragScrollComponent } from '@common/ui-kit/component/native-drag-scroll/native-drag-scroll.component';
import { NativeDragScrollChildDirective } from '@common/ui-kit/component/native-drag-scroll/directive/native-drag-scroll-child.directive';
import { Translate2Module } from '@common/translations/translate2.module';
import { InViewportDetectorDirective } from '@shared/legacy/directive/in-viewport/in-viewport-detector.directive';
import { BasicItemCardItemModel } from '@common/ui-kit/component/item-card/component/basic-item-card/model/basic-item-card-item.model';
import { BasicItemCardComponent } from '@common/ui-kit/component/item-card/component/basic-item-card/basic-item-card.component';
import { Nil } from '@util/helper-types/nil';
import { ItemScrollSliderItemModel } from '@shared/item-scroll-slider/model/item-scroll-slider-item.model';
import { ItemScrollSliderTrackItemModel } from '@shared/item-scroll-slider/model/item-scroll-slider-track-item.model';
import { IconButtonComponent } from '@common/ui-kit/component/icon-button/component/icon-button.component';
import { isNotNil } from '@util/helper-functions/is-not-nil';
import { ArrayUtils } from '@util/util/array.utils';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { ItemScrollSliderShowMoreModel } from '@shared/item-scroll-slider/model/item-scroll-slider-show-more.model';
import { UrlUtils } from '@util/util/url.utils';
import { Params, RouterLink } from '@angular/router';
import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { IntersectionObserverDirective } from '@common/intersection/directive/intersection-observer.directive';
import { IntersectionStatus } from '@common/intersection/model/intersection-status.type';
import { SpinnerComponent } from '@common/ui-kit/component/spinner/component/spinner.component';
import isNil from 'lodash-es/isNil';
import { ResponsivenessService } from '@common/responsiveness/service/responsiveness.service';
import { MediaBreakpointsWithMinType } from '@common/responsiveness/type/media-breakpoints-with-min.type';
import { SkeletonLoadingComponent } from '@shared/skeleton-loading/component/skeleton-loading.component';

/**
 * Tells, how many item cards can be possibly rendered in single row at which screen size
 */
const MAX_VISIBLE_ITEMS_IN_VIEWPORT_BY_BREAKPOINT_MAP: Record<MediaBreakpointsWithMinType, number> = {
  MIN: 3,
  SM: 3,
  MD: 3,
  LG: 6,
  XL: 6,
  '2XL': 6,
};

@Component({
  selector: 'auk-item-scroll-slider',
  templateUrl: './item-scroll-slider.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NativeDragScrollComponent,
    Translate2Module,
    NativeDragScrollChildDirective,
    InViewportDetectorDirective,
    NgTemplateOutlet,
    BasicItemCardComponent,
    NgClass,
    IconButtonComponent,
    RouterLink,
    IconComponent,
    MatLegacyTooltipModule,
    SkeletonLoadingComponent,
    IntersectionObserverDirective,
    SpinnerComponent,
  ],
})
export class ItemScrollSliderComponent extends NgUnsubscribe implements OnChanges {

  @Input() public description: TranslationSource | Nil;
  @Input() public items: ItemScrollSliderItemModel[] = [];
  @Input() public showMoreData: ItemScrollSliderShowMoreModel | Nil;
  /**
   * Tells, whether the items are loading (so we can show skeleton loading)
   */
  @Input() public isLoading: boolean = false;

  @Output() public itemClick: EventEmitter<ItemScrollSliderTrackItemModel> = new EventEmitter<ItemScrollSliderTrackItemModel>();
  @Output() public itemFollowClick: EventEmitter<ItemScrollSliderTrackItemModel> = new EventEmitter<ItemScrollSliderTrackItemModel>();
  @Output() public showMoreTitleClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() public showMoreCardClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() public itemCountdownEnd: EventEmitter<BasicItemCardItemModel> = new EventEmitter<BasicItemCardItemModel>();
  @Output() public isInViewPort: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('aukIntersectionEl', { static: false }) protected readonly intersectionElRef: ElementRef<HTMLDivElement>;

  protected staticChildWidth: number = 248;
  protected processedItems: ItemScrollSliderItemModel[] = [];
  protected showMoreTooltip: TranslationSource | Nil;
  /**
   * Map of item ids to their visibility status, which determines if is rendered item card or only skeleton
   */
  protected itemsVisibilityStatusMap: Record<number | string, IntersectionStatus> = {};

  private isInViewPortEmitted: boolean = false;

  constructor(
    protected readonly platformCommonService: PlatformCommonService,
    protected readonly responsivenessService: ResponsivenessService,
  ) {
    super();
  }

  public ngOnChanges(changes: AukSimpleChanges<ItemScrollSliderComponent>): void {
    if (changes.items || changes.showMoreData) {
      this.processItems();
      this.setShowMoreTooltip();
    }
  }

  protected onVisibilityChanged({ status, value }: { status: IntersectionStatus; value: string }): void {
    if (this.itemsVisibilityStatusMap[value] !== status) {
      this.itemsVisibilityStatusMap[value] = status;
    }
  }

  /**
   * Whether description can be shown in UI
   */
  protected get canShowDescription(): boolean {
    // don't show if desc doesn't exist
    if (isNil(this.description)) {
      return false;
    }

    // if loading is true, show it
    if (this.isLoading) {
      return true;
    }

    // otherwise only if there are items
    return !!this.processedItems?.length;
  }

  protected get isShowMoreCardVisible(): boolean {
    return isNotNil(this.showMoreData);
  }

  protected isInViewPortHandler(isInViewport: boolean): void {
    if (isInViewport  && !this.isInViewPortEmitted) {
      this.isInViewPort.emit(true);
      this.isInViewPortEmitted = true;
    }
  }

  protected onItemClick(item: ItemScrollSliderItemModel): void {
    this.itemClick.emit(item.trackItem);
  }

  protected onItemFollowClick(item: ItemScrollSliderItemModel): void {
    this.itemFollowClick.emit({
      id: item.trackItem.id,
      followersCount: item.trackItem.followersCount,
      isFollowed: item.trackItem.isFollowed,
    });
  }

  protected onCountdownEnd(item: ItemScrollSliderItemModel): void {
    this.itemCountdownEnd.next(item.cardItem);
  }

  protected onRecommendationTitleClick(): void {
    this.showMoreTitleClick.emit();
  }

  protected onRecommendationShowMoreCardClick(): void {
    this.showMoreCardClick.emit();
  }

  protected getRedirectUrl(url: string): string {
    return UrlUtils.getPathnameFromUrl(url);
  }

  protected getRedirectUrlQueryParams(url: string): Params {
    return UrlUtils.getQueryParamsFromUrl(url);
  }

  private processItemsVisibility(): void {
    // if is bot, we need to set all items to visible (for SEO purposes)
    if (this.platformCommonService.isBot) {
      this.processedItems.forEach((item) => {
        this.itemsVisibilityStatusMap[item.id] = IntersectionStatus.Visible;
      });
      return;
    }

    // always set first x items (based on screen size) to visible status (because we can assume they are always in viewport)
    const maxVisibleItems = MAX_VISIBLE_ITEMS_IN_VIEWPORT_BY_BREAKPOINT_MAP[this.responsivenessService.activeBreakpoint];
    this.processedItems.slice(0, maxVisibleItems).forEach((item) => {
      this.itemsVisibilityStatusMap[item.id] = IntersectionStatus.Visible;
    });
  }

  private processItems(): void {
    this.processedItems = [...this.items];
    if (this.isShowMoreCardVisible && ArrayUtils.isNotEmpty(this.items)) {
      this.processedItems.push({ extraTmpl: true, trackItem: null, cardItem: null, id: -1 });
    }

    this.processItemsVisibility();
  }

  private setShowMoreTooltip(): void {
    if (isNotNil(this.showMoreData?.title)) {
      this.showMoreTooltip = {
        defaultValue: this.showMoreData.title,
      };
    } else if (isNotNil(this.showMoreData?.titleKey)) {
      this.showMoreTooltip = {
        key: this.showMoreData.titleKey,
      };
    } else {
      this.showMoreTooltip = null;
    }
  }

}
