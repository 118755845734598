<div class="tw-grid tw-auto-rows-[49px]"
     [ngClass]="{
      'tw-gap-2': tileType === 'NORMAL',
      'tw-grid-flow-col	tw-auto-cols-[minmax(160px,1fr)] !tw-grid-cols-[repeat(auto-fill,minmax(160px,1fr))] tw-overflow-auto tw--mr-2 tw-pr-2': hasHorizontalScroll
     }"
  [ngStyle]="{ 'grid-template-columns': columnsStyle }"
  >
  @for (tile of tiles; track trackByIndexFn($index, tile)) {
    <auk-tile
      [tile]="tile"
      [size]="tileSize"
      [type]="tileType"
      (tileClick)="onTileClick($event)"
    ></auk-tile>
  }

  @for (toggle of toggleTiles; track toggle) {
    <auk-toggle-tile
      (tileChange)="onTileToggleChange($event)"
      [data]="toggle"
    ></auk-toggle-tile>
  }

  @if (showMoreButton) {
    <auk-tile
      [tile]="moreTile"
      [type]="tileType"
      [size]="tileSize"
      (tileClick)="onMoreClick()"
      class="show-more"
    ></auk-tile>
  }

  @if (showLessButton) {
    <auk-tile
      [tile]="lessTile"
      [type]="tileType"
      [size]="tileSize"
      (tileClick)="onLessClick()"
      class="show-less"
    ></auk-tile>
  }
</div>
