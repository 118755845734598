@if ((state$ | async); as state) {
  <div
    class="tw-flex tw-flex-col tw-rounded md:tw-w-[400px] tw-bg-background-color tw-text-foreground-color tw-p-3"
    [@fadeAnimation]
    >
    <div class="tw-flex tw-items-center">
      <!-- ICON -->
      <auk-icon [iconType]="state.iconType"
        [source]="state.iconSource"
        size="XL">
      </auk-icon>
      <!-- ./ICON -->
      <div class="tw-flex tw-flex-grow tw-flex-col tw-ml-2">
        <!-- TITLE -->
        @if (state.title) {
          <span
            class="tw-font-bold tw-flex-grow tw-mb-2 tw-text-md"
            [aukInnerHtml]="state.title">
          </span>
        }
        <!-- ./TITLE -->
        <!-- MESSAGE-->
        @if (state.message) {
          <span class="tw-text-md" [aukInnerHtml]="state.message"></span>
        }
        <!-- ./MESSAGE -->
      </div>
      <!-- CLOSE ICON -->
      @if (state.showCloseIcon) {
        <auk-icon
          class="tw-cursor-pointer tw-self-start"
          iconType="MATERIAL"
          source="close"
          size="XL"
          (click)="onCloseToast()">
        </auk-icon>
      }
      <!-- ./CLOSE ICON -->
    </div>
    <!-- ACTION BUTTONS -->
    @if (state.actions?.length) {
      <div class="tw-flex tw-mt-2">
        @for (action of state.actions; track action) {
          <auk-button
            class="tw-mr-3"
            [colorCombination]="action.colorCombination"
            type="button"
            (buttonClick)="action.actionCallBack($event)"
            >
            {{ action.label | translateSource }}
          </auk-button>
        }
      </div>
    }
    <!-- ./ACTION BUTTONS -->
  </div>
}
