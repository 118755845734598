import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { DisabledHint } from '../model/disabled-hint';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { HintDisablerService } from '../service/hint-disabler.service';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';

@Component({
  selector: 'auk-hint-disabler',
  templateUrl: './hint-disabler.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HintDisablerComponent extends NgUnsubscribe {

  public isSubmitting: boolean = false;

  constructor(
    private readonly hintDisablerService: HintDisablerService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  @Input()
  public hintKey: DisabledHint;

  public onChange(e: MatCheckboxChange): void {
    if (e.checked) {
      this.addKey();
    } else {
      this.removeKey();
    }
  }

  private removeKey(): void {
    this.isSubmitting = true;
    this.hintDisablerService.removeKey$(this.hintKey)
      .pipe(
        finalize(() => {
          this.isSubmitting = false;
          this.changeDetectorRef.markForCheck();
        }),
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  private addKey(): void {
    this.isSubmitting = true;
    this.hintDisablerService.addKey$(this.hintKey)
      .pipe(
        finalize(() => {
          this.isSubmitting = false;
          this.changeDetectorRef.markForCheck();
        }),
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

}
