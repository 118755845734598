import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { captureException, Scope, withScope } from '@sentry/browser';
import {
  GetOfferDealsAndTransactionsRequestParams, GetOfferDetailRequestParams,
  GetOfferDetailUsersDataWithPageTypeRequestParams,
  OffersApiService,
  ShowShortOfferDetailShortRequestParams,
} from '@api/aukro-api/api/offers-api.service';
import { OfferDetailDto } from '@api/aukro-api/model/offer-detail-dto';
import { OfferDetailShort } from '@api/aukro-api/model/offer-detail-short';
import { ItemDetailUsersDataDto } from '@api/aukro-api/model/item-detail-users-data-dto';
import { PagedModelItemDealDto } from '@api/aukro-api/model/paged-model-item-deal-dto';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CustomOfferService {

  constructor(
    private offersApiService: OffersApiService,
              private router: Router) {
  }

  public getOfferDetail(offerId: number): Observable<OfferDetailDto> {
    return this.offerDetail(offerId).pipe(
      catchError((error: HttpErrorResponse) => {
        if (Math.floor(error.status / 100) === 5) {
          withScope((scope: Scope) => {
            scope.setExtra('extra', error);
            captureException(new Error('Error 500 – Can not get offer detail in OfferService'));
          });
          void this.router.navigate(['/500']);
        }
        throw error;
      }));
  }

  public offerDetail(offerId: number, requestedFor?: GetOfferDetailRequestParams['requestedFor']): Observable<OfferDetailDto> {
    return this.offersApiService.getOfferDetail$({ id: offerId, requestedFor, pageType: 'DETAIL' });
  }

  public offerDetailShort(params: ShowShortOfferDetailShortRequestParams): Observable<OfferDetailShort> {
    return this.offersApiService.showShortOfferDetailShort$(params);
  }

  public detailUserData(params: GetOfferDetailUsersDataWithPageTypeRequestParams): Observable<ItemDetailUsersDataDto> {
    return this.offersApiService.getOfferDetailUsersDataWithPageType$(params);
  }

  // TODO(PDEV-9704) Refactor this/ remove Payment tab after New My aukro sold/bought page are implemented
  public getOfferTransactions(offerId: number, meta?: { page?: number; size?: number }, dealId?: number):
    Observable<PagedModelItemDealDto> {

    const params: GetOfferDealsAndTransactionsRequestParams = {
      id: offerId,
      dealId,
      page: meta.page,
      size: meta.size,
    };

    return this.offersApiService.getOfferDealsAndTransactions$(params);
  }

}
