import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { takeUntil } from 'rxjs/operators';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { isNotNil } from '@util/helper-functions/is-not-nil';
import { Nil } from '@util/helper-types/nil';

@Injectable({
  providedIn: 'root',
})
export class LayoutService extends NgUnsubscribe {

  private readonly _isAppScrollable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private readonly _hasMainPadding$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _hasFixedFullPageHeight$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly renderer2: Renderer2;
  private savedScrollPosition: number | Nil = null;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly rendererFactory2: RendererFactory2,
    private readonly platformCommonService: PlatformCommonService,
  ) {
    super();

    this.renderer2 = this.rendererFactory2.createRenderer(null, null);

    this.isAppScrollable$
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((isScrollable) => {
        const htmlElement = this.document.querySelector('html');
        const notScrollableClassName = 'app-not-scrollable';

        if (isScrollable) {
          this.renderer2.removeClass(htmlElement, notScrollableClassName);
        } else {
          this.savedScrollPosition = this.document.documentElement.scrollTop;
          this.renderer2.addClass(htmlElement, notScrollableClassName);
        }
      });
  }

  public get isAppScrollable$(): Observable<boolean> {
    return this._isAppScrollable$.asObservable();
  }

  public get hasFixedFullPageHeight$(): Observable<boolean> {
    return this._hasFixedFullPageHeight$.asObservable();
  }

  public updateHasFixedFullPageHeight(hasFixedFullPageHeight: boolean): void {
    this._hasFixedFullPageHeight$.next(hasFixedFullPageHeight);
  }

  public updateIsAppScrollable(isScrollable: boolean, returnToPosition: boolean = false): void {
    this._isAppScrollable$.next(isScrollable);

    // don't scroll on SSR
    if (this.platformCommonService.isServer) {
      return;
    }

    if (isScrollable) {
      // we need to make sure, that we scroll into top of the body element, if we are setting overflow hidden
      this.document.body.scrollIntoView();

      // Restore the scroll position when enabling scrolling
      if (returnToPosition && isNotNil(this.savedScrollPosition)) {
        this.document.documentElement.scrollTop = this.savedScrollPosition;
        this.savedScrollPosition = null;
      }
    }
  }

  public addBodyPositionFixedClass(): void {
    this.renderer2.addClass(this.document.body, 'tw-fixed');
  }

  public removeBodyPositionFixedClass(): void {
    this.renderer2.removeClass(this.document.body, 'tw-fixed');
  }

}
