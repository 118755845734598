import { CommonModule, DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Event as RouterEvent, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, ParamMap, Router, RouterModule, RoutesRecognized } from '@angular/router';
import { config as faConfig, dom as faDom, library as faLibrary } from '@fortawesome/fontawesome-svg-core';
import { captureException, captureMessage } from '@sentry/browser';
import { faBabyCarriage } from '@fortawesome/free-solid-svg-icons/faBabyCarriage';
import { faBandAid } from '@fortawesome/free-solid-svg-icons/faBandAid';
import { faChartLine } from '@fortawesome/free-solid-svg-icons/faChartLine';
import { faCoins } from '@fortawesome/free-solid-svg-icons/faCoins';
import { faCouch } from '@fortawesome/free-solid-svg-icons/faCouch';
import { faFeatherAlt } from '@fortawesome/free-solid-svg-icons/faFeatherAlt';
import { faHeadSideMask } from '@fortawesome/free-solid-svg-icons/faHeadSideMask';
import { faTshirt } from '@fortawesome/free-solid-svg-icons/faTshirt';
import isNil from 'lodash-es/isNil';
import { combineLatest, mergeMap, of } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { ConsentStanceBasicWithContent, ConsentStancesModalClose } from '../typings/original/internal';
import { AdultContentService } from '@shared/services/adult-content/adult-content.service';
import { AnnouncementCustomService } from '@shared/services/annoucement/announcement-custom.service';
import { RoutesService } from '@shared/services/app/routes.service';
import { AuthenticationService } from '@shared/authentication/service/authentication.service';
import { LayoutService } from '@common/layout/layout.service';
import { PlatformService } from '@shared/platform/service/platform.service';
import { PushNotificationsService } from '@shared/services/push-notifications/push-notifications.service';
import { SeoService } from '@shared/services/seo/seo.service';
import { LocalStorageService } from '@common/services/storage/local-storage.service';
import { UserService } from '@shared/user/service/user.service';
import { BrowserService } from '@shared/platform/browser.service';
import { FontawesomeUtils } from '@shared/util/fontawesome.utils';
import { ConsentTermsComponent } from '@shared/legacy/component/consent-terms/consent-terms.component';
import { UnpaidAlertComponent } from '@shared/legacy/component/unpaid-alert/component/unpaid-alert.component';
import { SkeletonLoadingService } from '@shared/skeleton-loading/service/skeleton-loading.service';
import { UnpaidAlertUtil } from '@shared/legacy/component/unpaid-alert/util/unpaid-alert.util';
import { UserMessageAsyncDataGlobalAdapterService } from '@shared/services/async/user-message/user-message-async-data-global-adapter.service';
import { isChangeOnlyQuery } from '@util/util/method/is-change-only-query.util';
import { CrossTabMessagingService } from '@shared/services/cross-tab-messaging/service/cross-tab-messaging.service';
import { WINDOW_OBJECT } from '@util/const/window-object';
import { LegacyDateAdapter as DateAdapter } from '@angular/material/legacy-core';
import { CurrencyResolverService } from '@shared/currency/service/currency-resolver.service';
import { SubbrandService } from '@shared/subbrand/service/subbrand.service';
import { BackToTopService } from '@shared/services/back-to-top/back-to-top.service';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { BrowserUtils } from '@util/util/browser.utils';
import { ResponsivenessService } from '@common/responsiveness/service/responsiveness.service';
import { MailApiService } from '@api/aukro-api/api/mail-api.service';
import { HttpClientModule } from '@angular/common/http';
import { AppHeaderModule } from '@shared/app-header/app-header.module';
import { AppFooterModule } from '@shared/app-footer/app-footer.module';
import { SocialLoginModule } from '@abacritt/angularx-social-login';
import { HideOnMobileSsrDirectiveModule } from '@shared/legacy/directive/hide-on-mobile-ssr/hide-on-mobile-ssr.directive.module';
import { TopPanelModule } from '@shared/legacy/component/top-panel/top-panel.module';
import { AppBackToTopBtnModule } from '@shared/legacy/component/app-back-to-top-button/app-back-to-top-btn.module';
import { JsonLdModule } from '@shared/legacy/component/json-ld/json-ld.module';
import { AsyncRenderModule } from '@shared/async-render/async-render.module';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { UserActionModule } from '@shared/user-action/user-action.module';
import { UserActionViewModule } from '@shared/user-action-view/user-action-view.module';
import { ToastModule } from '@common/toast/toast.module';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { SpinnerModule } from '@shared/legacy/component/spinner/spinner.module';
import { HideIfMobileAppDirective } from '@shared/platform/is-mobileapp.directive';
import { IfIsActiveBreakpointDirective } from '@common/responsiveness/directive/if-is-active-breakpoint/if-is-active-breakpoint.directive';
import { UserInterestStatisticsDto } from '@api/aukro-api/model/user-interest-statistics-dto';
import { ConsentStanceBasicDto } from '@api/aukro-api/model/consent-stance-basic-dto';
import { AppHeaderMenuComponent } from '@shared/app-header/component/app-header-menu/app-header-menu.component';
import { DomainService } from '@shared/platform/domain.service';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { DateUtils } from '@util/util/date.utils';
import { Nil } from '@util/helper-types/nil';
import { AppTrackingTransparency } from 'capacitor-ios-app-tracking';
import { AppHeaderStickyPanelComponent } from '@shared/app-header/component/app-header-sticky-panel/app-header-sticky-panel.component';
import { ButtonDialogModule } from '@common/ui-kit/component/form/auk-button-dialog/button-dialog.module';
import { GoogleAnalyticsTrackingService } from '@shared/google-analytics/service/google-analytics-tracking.service';
import { GaClientInformationService } from '@shared/google-analytics/service/ga-client-information.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'aukro-app',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    AppHeaderModule,
    AppFooterModule,
    SocialLoginModule,
    HideOnMobileSsrDirectiveModule,
    TopPanelModule,
    AppBackToTopBtnModule,
    JsonLdModule,
    AsyncRenderModule,
    MatSnackBarModule,
    UserActionModule,
    UserActionViewModule,
    ToastModule,
    MatBottomSheetModule,
    SpinnerModule,
    HideIfMobileAppDirective,
    IfIsActiveBreakpointDirective,
    AppHeaderStickyPanelComponent,
    ButtonDialogModule,
  ],
})
export class AppComponent extends NgUnsubscribe implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(AppHeaderMenuComponent) private readonly appHeaderMenuCmp: AppHeaderMenuComponent;

  private readonly didomiCheckIntervalSeconds: number = 30;

  public loading: boolean = true;
  public disclaimerModalOpened: boolean = false;
  public consentModalData: ConsentStancesModalClose;
  public importantAnnouncementsAllowed: boolean;
  public hideHeaderFooter: boolean = false;
  public hasFixedFullPageHeight: boolean = false;
  public isMyMessages: boolean = false;

  /**
   * Whether global go back button should be visible. It is hidden on pages with its own go back button
   */
  protected showGlobalGoBackButton: boolean = false;
  /**
   * Whether global go back button should be visible even if user is scrolled up
   */
  protected notHideGlobalGoBackButton: boolean = false;
  public isProductPage: boolean = false;
  private oldPath: string;
  private consentDialogRef: MatDialogRef<ConsentTermsComponent, ConsentStancesModalClose>;

  protected isMdAndLower: boolean = false;
  protected isSubbrandClassificationPage = false;
  protected dummySearchInput: ElementRef<HTMLInputElement>;
  protected menuToggleBtnYPosition: number = 0;
  protected showHeaderV2: boolean | Nil = null;

  constructor(
    @Inject(WINDOW_OBJECT) private readonly window: Window,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly localStorageService: LocalStorageService,
    private readonly router: Router,
    private readonly browserService: BrowserService,
    private readonly platformService: PlatformService,
    private readonly platformCommonService: PlatformCommonService,
    private readonly announcementService: AnnouncementCustomService,
    private readonly adultContent: AdultContentService,
    private readonly userService: UserService,
    private readonly authService: AuthenticationService,
    private readonly dialog: MatDialog,
    private readonly pushNotificationsService: PushNotificationsService,
    private readonly mailApiService: MailApiService,
    protected readonly routesService: RoutesService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly googleAnalyticsTrackingService: GoogleAnalyticsTrackingService,
    private readonly gaClientInformationService: GaClientInformationService,
    private readonly renderer: Renderer2,
    private readonly layoutService: LayoutService,
    private readonly initializationInfoService: SkeletonLoadingService,
    private readonly userMessageAsyncDataGlobalAdapterService: UserMessageAsyncDataGlobalAdapterService,
    private readonly crossTabMessagingService: CrossTabMessagingService,
    private readonly currencyResolverService: CurrencyResolverService,
    private readonly dateAdapter: DateAdapter<string>,
    private readonly subbrandService: SubbrandService,
    private readonly backToTopService: BackToTopService,
    private readonly ngZoneUtilService: NgZoneUtilService,
    private readonly responsivenessService: ResponsivenessService,
    private readonly domainService: DomainService,
    seoService: SeoService,
  ) {
    super();

    seoService.init();
    this.initFaIcons();
  }

  protected get isShowHeaderV2Evaluated(): boolean {
    return !isNil(this.showHeaderV2);
  }

  public ngOnInit(): void {
    this.userMessageAsyncDataGlobalAdapterService.init();

    this.initFixedFullPageHeightHandler();
    this.initShowHeaderV2Evaluation();
    this.browserService.initHasHistory();
    this.dateAdapter.setLocale(this.domainService.lang);

    this.activatedRoute.queryParamMap
      .pipe(
        take(2), // first params are empty
        filter((queryParamMap: ParamMap) => queryParamMap.keys.length > 0),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((queryParamMap: ParamMap) => {
        this.checkClickThroughRecord(queryParamMap);
        this.checkRedirect(queryParamMap);

        if (this.platformCommonService.isBrowser) {
          this.gaClientInformationService.setCampaignDataWhenGaClientIdAvailable(queryParamMap);
        }
      });

    this.router.events
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((event: RouterEvent) => {
        if (event instanceof NavigationStart) {
          this.detectPageType();

          if (this.platformCommonService.isBrowser) {
            this.checkNeedToScroll(event);
          }

          if (this.showSpinnerOnNavigationStart(event)) {
            this.loading = true;
          }
        } else if (event instanceof NavigationEnd) {
          this.cleanFlagGoBackFrom500(event.url);
          this.detectPageType();
          this.loading = false;
          const route: ActivatedRoute = BrowserUtils.getActiveRoute(this.activatedRoute);
          this.hideHeaderFooter = this.isHeaderFooterHidden(route);
          this.showGlobalGoBackButton = !route.snapshot.data.doNotShowGoBackButton;
          this.notHideGlobalGoBackButton = route.snapshot.data.notHideGlobalGoBackButton;
          this.isProductPage = route.snapshot.data.pagetype === 'Product';
        } else if (event instanceof NavigationCancel || event instanceof NavigationError) {
          this.loading = false;
        }
      });

    this.adultContent.isAdultConfirmDialogOpened$
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((isOpened) =>
        this.disclaimerModalOpened = isOpened,
      );

    this.responsivenessService.isMdAndLower$
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((isMdAndLower) => {
        this.isMdAndLower = isMdAndLower;
      });

    this.pushNotificationsService.initOnAppStart();

    this.announcementService.showImportantAnnouncements();

    if (this.platformCommonService.isBrowser) {
      this.authService.getLoginStatusChangeWithStartValueDistinct$()
        .pipe(
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe((isLoggedIn: boolean) => {
          if (isLoggedIn) {
            this.checkStances();
          } else {
            if (this.consentDialogRef) {
              this.consentDialogRef.close({ closed: true, actualStatistics: null });
            }
          }
        });
    }

    this.crossTabMessagingService.listenToMessageType$('CART_CHECKOUT')
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        // redirect other tabs to first cart step when cart checkout is completed
        // do not redirect if current url is thank you cart page
        if (this.router.url.startsWith('/kosik/') && this.router.url !== '/kosik/objednavka-odeslana') {
          void this.router.navigate(['/kosik']);
        }
      });

    // get initial queryParams
    // https://github.com/angular/angular/issues/12157#issuecomment-799331302
    this.router.events
      .pipe(
        filter((event): event is RoutesRecognized => !!event && event instanceof RoutesRecognized),
        take(1),
        map(event => event.state.root.queryParamMap),
      )
      .subscribe(queryParams => {
        this.trackGclid(queryParams);
      });

    if (this.platformService.isIos) {
      this.browserService.setViewportMaximumScale('1.0');
    }

    // get initial currency preference
    this.authService.getLoginStatusChangeWithStartValueDistinct$()
      .pipe(
        mergeMap((isLoggedIn) => isLoggedIn ?
          this.userService.getActualStatistics().pipe(map((actualStatistics) => actualStatistics?.currency)) :
          of(null)),
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((currency) => this.currencyResolverService.resolvePreferredCurrency(currency));

    this.listenOnSubbrandFeature();
  }

  protected openSuggestionsDropdown(): void {
    this.appHeaderMenuCmp.openSuggestionsDropdown();
  }

  private listenOnSubbrandFeature(): void {
    this.subbrandService.shouldSpecifySubbrand$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((subbrandNotSpecified) => this.isSubbrandClassificationPage = subbrandNotSpecified);
  }

  protected get hasBackgroundColor(): boolean {
    return !this.isSubbrandClassificationPage;
  }

  protected initShowHeaderV2Evaluation(): void {
    combineLatest([
      this.subbrandService.shouldSpecifySubbrand$,
      this.routesService.isHomepagePage$,
    ])
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(([isSubbrandClassificationPage, userIsOnHomepage]: [boolean, boolean]) => {
        this.showHeaderV2 = isSubbrandClassificationPage && userIsOnHomepage;
      });
  }

  /**
   * Checks whether the Didomi element exists in the DOM and logs appropriate message to Sentry if Didomi is not loaded.<br>
   * The check is scheduled after given interval. If the Didomi element doesn't exist after that interval, a message is logged to Sentry.
   * IOS users can disable didomi cookie banner through ATT feature, in that case we don't log error
   */
  private checkDidomi(): void {
    this.ngZoneUtilService.timerOut$(DateUtils.convertSecondsToMilliseconds(this.didomiCheckIntervalSeconds))
      .pipe(
        take(1),
        switchMap(() => {
          if (PlatformCommonService.isNativeIosApp) {
            return AppTrackingTransparency.getTrackingStatus();
          } else {
            return of({ status: 'unauthorized' });
          }
        }),
      )
      .subscribe((trackingStatus) => {
        const isNativeIosApp: boolean = PlatformCommonService.isNativeIosApp;
        const isIosTrackingEnabled: boolean = trackingStatus?.status === 'authorized';
        const isDidomiEnabled: boolean = !isNativeIosApp || (isNativeIosApp && isIosTrackingEnabled);

        if (isDidomiEnabled) {
          const didomiElement = this.document.getElementById('didomi-host');
          if (isNil(didomiElement)) {
            captureException('Didomi script failed to load', { tags: { context: 'didomi', result: 'failure' } });
          }
        }
      });
  }

  protected get showFooter(): boolean {
    return !this.isSubbrandClassificationPage && !this.hideHeaderFooter;
  }

  protected get showBackToTopBtn(): boolean {
    return !this.isSubbrandClassificationPage && this.backToTopService.isVisible();
  }

  protected get isNativePlatform(): boolean {
    return PlatformCommonService.isNativeApp;
  }

  protected setMenuToggleBtnYPosition(value: number): void {
    this.menuToggleBtnYPosition = value;
  }

  /**
   * Evaluates if spinner should be shown on navigation start
   * @param navigationStart
   */
  private showSpinnerOnNavigationStart(navigationStart: NavigationStart): boolean {
    if (this.platformCommonService.isServer) {
      return true;
    }

    if (navigationStart.url.startsWith('/vystavit')) {
      return false;
    }

    if (!navigationStart.url.startsWith(this.browserService.getPathname())
      && this.hasFixedFullPageHeight
      && navigationStart.url.startsWith('/moje-aukro')) {
      return false;
    }

    return true;
  }

  protected setDummySearchRef(ref: ElementRef<HTMLInputElement>): void {
    this.dummySearchInput = ref;
  }

  public ngAfterViewInit(): void {
    if (this.platformCommonService.isBrowser) {
      void FontawesomeUtils.inlineSvgs(this.document);
      this.checkDidomi();
    }

    this.removeLoader();
    this.initializationInfoService.onAppComponentViewInit();
  }

  public onConsentStanceClose(modalData: ConsentStancesModalClose): void {
    this.consentModalData = modalData;
    if (modalData.closed && modalData.actualStatistics && modalData.actualStatistics.unpaidInvoice) {
      const hasDisplayed = this.localStorageService.getItem<boolean>(
        UnpaidAlertUtil.getUnpaidAlertDisplayedKey(modalData.actualStatistics.userId),
      );

      if (!hasDisplayed) {
        this.openUnpaidAlert();
      }
      this.importantAnnouncementsAllowed = hasDisplayed;
    } else {
      if (modalData.actualStatistics && !modalData.actualStatistics.unpaidInvoice) {
        this.localStorageService.removeItem(UnpaidAlertUtil.getUnpaidAlertDisplayedKey(modalData.actualStatistics.userId));
      }
      this.importantAnnouncementsAllowed = modalData.closed;
    }
    this.ngZoneUtilService.simpleTimerOut$(
      () => this.announcementService.setAnnouncementPopupAllowed(this.importantAnnouncementsAllowed),
      this.ngUnsubscribe,
    );
  }

  private checkNeedToScroll(event: NavigationStart): void {
    const isChangeOnlyInQuery = isChangeOnlyQuery(this.oldPath, event.url);
    this.oldPath = event.url;
    if (isChangeOnlyInQuery) {
      return;
    }
    this.browserService.resetScroll();
  }

  private checkStances(): void {
    this.userService.getActualStatistics()
      .pipe(
        take(1),
        filter((actualStatistics: UserInterestStatisticsDto) => actualStatistics?.emailVerified && actualStatistics?.hasPersonalDataFilled),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((actualStatistics: UserInterestStatisticsDto) => {
        const unresolvedStances: ConsentStanceBasicWithContent[] = actualStatistics.stances
          .filter((item: ConsentStanceBasicDto) =>
            item.stance === null
            && item.consentType.enforceStance
            && ['FORTHWITH', 'EVERY_LOGIN'].includes(item.consentType.stanceUrgencyLevel),
          );

        if (!unresolvedStances.length) {
          this.onConsentStanceClose({ closed: true, actualStatistics } as ConsentStancesModalClose);
          return;
        }
        this.openConsentTermsModal(actualStatistics, unresolvedStances, false);
      });
  }

  private openUnpaidAlert(): void {
    const unpaidDialogRef: MatDialogRef<UnpaidAlertComponent> = this.dialog.open(UnpaidAlertComponent, {
      data: { consentModalData: this.consentModalData },
      disableClose: true,
    });
    unpaidDialogRef.afterClosed()
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        this.announcementService.setAnnouncementPopupAllowed(true);
      });
  }

  private openConsentTermsModal(
    actualStatistics: UserInterestStatisticsDto,
    unresolvedStances: ConsentStanceBasicWithContent[],
    isOnlyView: boolean): void {
    this.consentDialogRef = this.dialog.open(ConsentTermsComponent, {
      maxWidth: '900px',
      data: {
        actualStatistics,
        unresolvedStances,
        isOnlyView,
      },
      disableClose: true,
      panelClass: 'consent-terms-dialog-panel',
    });

    this.consentDialogRef.afterClosed()
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((modalResult: ConsentStancesModalClose) => {
        this.onConsentStanceClose(modalResult);
      });
  }

  /**
   * Removes temporary loader from page.
   * Loader should be inside <div id="loader"></div> in index.html.
   * Call this method after some usable content has been loaded.
   * Do not relay on Angular build-in loader solution (loader inside aukro-app tag in index.html).
   */
  private removeLoader(): void {
    const loader = this.renderer.selectRootElement('#loader');
    this.renderer.addClass(loader, 'hidden');
  }

  /**
   * If URL contains `sleId` query param, call EP to record a click through from email.
   * @param queryParamMap query parameters
   */
  private checkClickThroughRecord(queryParamMap: ParamMap): void {
    const sleId: string = queryParamMap.get('sleId');

    // Do not record click if it is unsubscribe.
    if (sleId && !this.router.url.startsWith('/odhlaseni-z-odberu')) {
      if (this.platformCommonService.isBrowser) {
        this.mailApiService.clickRecorded$({ sleId })
          .pipe(
            take(1),
            takeUntil(this.ngUnsubscribe),
          )
          .subscribe();
      }

      // Remove query param (except on marketing mail settings page).
      if (!this.router.url.startsWith('/odhlaseni-z-odberu-upozornovani')) {
        void this.router.navigate([], {
          queryParams: {
            sleid: null,
          },
          queryParamsHandling: 'merge',
          preserveFragment: true,
        });
      }
    }
  }

  /**
   * If URL contains `rUrl` param, redirect to URL in param.
   * @param queryParamMap query parameters
   */
  // TODO[PDEV-22857]: Remove this quite severe security risk by redirect only to domains on whitelist
  private checkRedirect(queryParamMap: ParamMap): void {
    const rurl: string = queryParamMap.get('rUrl');
    if (rurl) {
      captureMessage('AppComponent :: Potentially insecure redirect to URL in rURL pram', {
        fingerprint: ['RURL_REDIRECT'],
        tags: { rurl },
        extra: { rurl },
        level: 'warning',
      });
      this.window.location.href = rurl;
    }
  }

  private isHeaderFooterHidden(route: ActivatedRoute): boolean {
    let hideHeaderFooter = false;
    // find through whole router tree
    while (route) {
      if (route.snapshot.data.hideHeaderFooter) {
        hideHeaderFooter = true;
        break;
      } else {
        route = route.parent;
      }
    }
    return hideHeaderFooter;
  }

  private cleanFlagGoBackFrom500(currentUrl: string): void {
    if (currentUrl && currentUrl !== '/500') {
      this.localStorageService.removeItem('goBackFrom500');
    }
  }

  private detectPageType(): void {
    this.isMyMessages = this.browserService.getPathname().includes('/moje-zpravy');
  }

  private initFixedFullPageHeightHandler(): void {
    combineLatest([
      this.routesService.isMyAukroPage$,
      this.layoutService.hasFixedFullPageHeight$,
    ])
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(([isMyAukro, hasFixedFullPageHeight]: [boolean, boolean]) => {
        this.hasFixedFullPageHeight = isMyAukro || hasFixedFullPageHeight;
      });
  }

  private initFaIcons(): void {
    // manually defined icons to be used.
    faLibrary.add(faCoins, faTshirt, faBabyCarriage, faFeatherAlt, faBandAid, faHeadSideMask, faChartLine, faCouch);

    // styles are added during SSR with this ID. Make sure they are not added for a second time on the client.
    faConfig.autoAddCss = isNil(this.document.getElementById('FA_STYLE'));

    // the replacement of icons for svgs is done manually during SSR. Only start the watch on the client.
    if (this.platformCommonService.isBrowser) {
      faDom.watch();
    }
  }

  private trackGclid(queryParams: ParamMap): void {
    if (this.platformCommonService.isBrowser) {
      void this.googleAnalyticsTrackingService.trackGclid(queryParams.get('gclid') || 'not set');
    }
  }

}
