import { TranslationSource } from '@common/translations/model/translation-source';

export interface ValidationMessage {
  className?: string;
  text: TranslationSource;
}

export const validationMessages: { [errorCode: string]: ValidationMessage | string } = {
  alreadyUsed: {
    text: { key: 'LEGACY_VALIDATION_MESSAGES_ALREADY_USED' },
  },
  city: 'wrongPattern',
  containLogin: {
    text: { key: 'LEGACY_VALIDATION_MESSAGES_CONTAINS_LOGIN' },
  },
  email: {
    text: { key: 'LEGACY_VALIDATION_MESSAGES_EMAIL' },
  },
  webhookUrl: {
    text: { key: 'LEGACY_VALIDATION_MESSAGES_WEBHOOK_URL' },
  },
  firstNameContainLogin: {
    text: { key: 'LEGACY_VALIDATION_MESSAGES_FIRST_NAME_CONTAIN_LOGIN' },
  },
  fileSize1MbError: {
    text: { key: 'LEGACY_VALIDATION_MESSAGES_FILE_SIZE_1MB_ERROR' },
  },
  fileSize2MbError: {
    text: { key: 'LEGACY_VALIDATION_MESSAGES_FILE_SIZE_2MB_ERROR' },
  },
  fileSize5MbError: {
    text: { key: 'LEGACY_VALIDATION_MESSAGES_FILE_SIZE_5MB_ERROR' },
  },
  fileLengthError: {
    text: { key: 'LEGACY_VALIDATION_MESSAGES_FILE_LENGTH_ERROR' },
  },
  fileTypeContactError: {
    text: { key: 'LEGACY_VALIDATION_MESSAGES_FILE_TYPE_CONTACT_ERROR' },
  },
  incorrect: {
    text: { key: 'LEGACY_VALIDATION_MESSAGES_INCORRECT' },
  },
  incorrectPwd: {
    text: { key: 'LEGACY_VALIDATION_MESSAGES_INCORRECT_PWD' },
  },
  incorrectCompany: {
    text: { key: 'LEGACY_VALIDATION_MESSAGES_INCORRECT_COMPANY' },
  },
  itemNumbersError: {
    text: { key: 'LEGACY_VALIDATION_MESSAGES_ITEM_NUMBERS_ERROR' },
  },
  matchOld: {
    text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_PASSWORD_ERROR_MATCH_OLD' },
  },
  maxlength: {
    text: { key: 'LEGACY_VALIDATION_MESSAGES_MAX_LENGTH' },
  },
  minlength: {
    text: { key: 'LEGACY_VALIDATION_MESSAGES_MIN_LENGTH' },
  },
  pattern: {
    text: { key: 'LEGACY_VALIDATION_MESSAGES_PATTERN' },
  },
  personName: 'wrongPattern',
  phoneNumber: {
    text: { key: 'LEGACY_VALIDATION_MESSAGES_PHONE_NUMBER' },
  },
  required: {
    text: { key: 'LEGACY_VALIDATION_MESSAGES_REQUIRED' },
  },
  street: 'wrongPattern',
  userNotFound: {
    text: { key: 'LEGACY_VALIDATION_MESSAGES_USER_NOT_FOUND' },
  },
  vat: 'wrongPattern',
  wrongSecurityAnswer: {
    text: { key: 'LEGACY_VALIDATION_MESSAGES_WRONG_SECURITY_ANSWER' },
  },
  lastNameContainLogin: {
    text: { key: 'LEGACY_VALIDATION_MESSAGES_LAST_NAME_CONTAIN_LOGIN' },
  },
  limitExceeded: {
    text: { key: 'LEGACY_VALIDATION_MESSAGES_LIMIT_EXCEEDED' },
  },
  wrongPattern: {
    text: { key: 'LEGACY_VALIDATION_MESSAGES_WRONG_PATTERN' },
  },
  wrongSms: {
    text: { key: 'LEGACY_VALIDATION_MESSAGES_WRONG_SMS' },
  },
  wrongUserIdSms: {
    text: { key: 'LEGACY_VALIDATION_MESSAGES_WRONG_USER_ID_SMS' },
  },
  manyAttemptsSms: {
    text: { key: 'LEGACY_VALIDATION_MESSAGES_MANY_ATTEMPTS_SMS' },
  },
  expiredCodeSms: {
    text: { key: 'LEGACY_VALIDATION_MESSAGES_EXPIRED_CODE_SMS' },
  },
  zipCode: 'wrongPattern',
  simpleBirthNumberOrDate: {
    text: { key: 'LEGACY_VALIDATION_MESSAGES_SIMPLE_BIRTH_NUMBER_OR_DATE' },
  },
  bankAccountNumber: {
    text: { key: 'LEGACY_VALIDATION_MESSAGES_BANK_ACCOUNT_NUMBER' },
  },
  phoneNumberNotExists: {
    text: { key: 'LEGACY_VALIDATION_MESSAGES_PHONE_NUMBER_NOT_EXISTS' },
  },
  phoneNumberNotValid: {
    text: { key: 'LEGACY_VALIDATION_MESSAGES_PHONE_NUMBER_NOT_VALID' },
  },
  empty: {
    text: { key: 'LEGACY_VALIDATION_MESSAGES_PHONE_NUMBER_NOT_VALID' },
  },
  parseError: {
    text: { key: 'LEGACY_VALIDATION_MESSAGES_PHONE_NUMBER_NOT_EXISTS' },
  },
  shortInput: {
    text: { key: 'LEGACY_VALIDATION_MESSAGES_SHORT_INPUT' },
  },
};
