import { NgModule } from '@angular/core';
import { FavouritePopoverComponent } from '@shared/favourite/component/favourite-popover.component';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from '@common/ui-kit/component/button/component/button.component';
import { CurrencyPipeModule } from '@shared/currency/pipe/currency-pipe.module';
import { Translate2Module } from '@common/translations/translate2.module';
import { RouterLink } from '@angular/router';
import { FavouritePopoverItemsComponent } from '@shared/favourite/component/favourite-items-popover/favourite-items-popover.component';
import { FavouritePopoverSellersComponent } from '@shared/favourite/component/favourite-sellers-popover/favourite-sellers-popover.component';
import { FavouritePopoverSearchComponent } from '@shared/favourite/component/favourite-search-popover/favourite-search-popover.component';
import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';
import { UserChipComponent } from '@shared/user-chip/user-chip.component';
import { SpinnerComponent } from '@common/ui-kit/component/spinner/component/spinner.component';
import { FavouritePopoverEmptyTabContentComponent } from '@shared/favourite/component/favourite-popover-empty-tab-content/favourite-popover-empty-tab-content.component';
import { CountdownPanelComponent } from '@common/ui-kit/component/countdown-panel/component/countdown-panel.component';
import { ReplaceByPlaceholderOnErrorDirective } from '@common/image-loading/directive/replace-by-placeholder-on-error.directive';
import { LinkFromHrefDirective } from '@common/routing/directive/link-from-href.directive';

@NgModule({
  imports: [
    CommonModule,
    ButtonComponent,
    Translate2Module,
    CurrencyPipeModule,
    RouterLink,
    IconComponent,
    UserChipComponent,
    SpinnerComponent,
    CountdownPanelComponent,
    ReplaceByPlaceholderOnErrorDirective,
    LinkFromHrefDirective,
  ],
  declarations: [
    FavouritePopoverComponent,
    FavouritePopoverItemsComponent,
    FavouritePopoverSellersComponent,
    FavouritePopoverSearchComponent,
    FavouritePopoverEmptyTabContentComponent,
  ],
  exports: [
    FavouritePopoverComponent,
    FavouritePopoverItemsComponent,
    FavouritePopoverSellersComponent,
    FavouritePopoverSearchComponent,
    FavouritePopoverEmptyTabContentComponent,
  ],
})
export class FavouritePopoverModule {
}
