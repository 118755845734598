import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { AukWindow } from '@shared/model/auk-window.interface';
import { DidomiOnReadyCallback } from '../model/didomi-on-ready-callback.type';
import { Didomi } from '../model/didomi';
import isNil from 'lodash-es/isNil';
import isFunction from 'lodash-es/isFunction';
import { DOCUMENT } from '@angular/common';
import { CookieConsentVendorEnum } from '../model/cookie-consent-vendor.enum';
import { WINDOW_OBJECT } from '@util/const/window-object';
import { Nil } from '@util/helper-types/nil';

@Injectable({
  providedIn: 'root',
})
export class CookieConsentService {

  private readonly renderer2: Renderer2;

  constructor(
    @Inject(WINDOW_OBJECT) private readonly window: AukWindow,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly rendererFactory2: RendererFactory2,
  ) {
    this.renderer2 = this.rendererFactory2.createRenderer(null, null);
  }

  private pushCallbackToDidomiOnReady(callback: DidomiOnReadyCallback): void {
    this.window.didomiOnReady = this.window.didomiOnReady || [];
    this.window.didomiOnReady.push(callback);
  }

  public openPreferences(): void {
    const callback: DidomiOnReadyCallback = (didomi: Didomi) => {
      if (!isFunction(didomi.preferences?.show)) {
        return;
      }
      didomi.preferences.show();
    };
    this.pushCallbackToDidomiOnReady(callback);
  }

  /**
   * Inserts a script to the page if user gives consent for the specified vendor
   * Waits for the user's consent in case user has not decided yet or Didomi script has not fully loaded
   * Does not insert script if user did not accept the vendor
   * @param vendorId vendor
   * @param script script to insert
   */
  public insertScriptWhenConsentIsGiven(vendorId: CookieConsentVendorEnum, script: HTMLScriptElement): void {
    const callback: DidomiOnReadyCallback = (didomi: Didomi) => {
      if (!isFunction(didomi.getObservableOnUserConsentStatusForVendor)) {
        return;
      }

      const insertScriptCallback: (status: boolean) => void = (status: boolean) => {
        if (status) {
          this.renderer2.appendChild(this.document.head, script);
        }
      };

      this.executeWhenConsentStatusRecognized(vendorId, insertScriptCallback);
    };

    this.pushCallbackToDidomiOnReady(callback);
  }

  /**
   * Executes the given callback when consent status the vendor is recognized. Callback is executed both on grant and reject consent.
   * It is never call callback until user gives their choice or until Didomi script is not loaded yet
   * @param vendorId Vendor identification
   * @param callbackToBeExecuted Callback which will be executed
   */
  public executeWhenConsentStatusRecognized(vendorId: CookieConsentVendorEnum, callbackToBeExecuted: (status: boolean) => void): void {
    const callback: DidomiOnReadyCallback = (didomi: Didomi) => {
      if (!isFunction(didomi.getObservableOnUserConsentStatusForVendor)) {
        return;
      }

      // Filter, first and subscribe are not real RxJS functions
      didomi.getObservableOnUserConsentStatusForVendor(vendorId)
        ?.filter((status: boolean | Nil) => !isNil(status))
        ?.first()
        ?.subscribe((status: boolean) => callbackToBeExecuted(status));
    };

    this.pushCallbackToDidomiOnReady(callback);
  }

}
