<div class="tw-relative tw-w-0 tw-h-0 tw-border-solid tw-border-[1.75rem] tw-border-t-background-color tw-border-r-transparent tw-border-b-transparent tw-border-l-background-color tw-drop-shadow-lg"
>
  <div class="tw-absolute tw--left-5 tw--top-5">
    <auk-icon
        [source]="cornerMarkIcon.source"
        [iconType]="cornerMarkIcon.type"
        colorCombination="PRIMARY"
        size="MD"
    ></auk-icon>
  </div>
</div>
